import React, { useState } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import "./Category.css"
import AdminFooter from '../Footer/Footeradmin';

function App() {
    const baseUrl = process.env.REACT_APP_API_URL;
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [website1, setWebsite1] = useState(false);
  const [website2, setWebsite2] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/newscategories`, { 
        heading, 
        description, 
        category, 
        isChecked,
        website1: website1.toString(),  // Convert to string explicitly
        website2: website2.toString()   // Convert to string explicitly
      });

      alert('Data submitted successfully!');

    } catch (error) {
      alert('Error submitting data: ' + error.message);
      console.log(error);
    }
  };

  return (
    <>
       <Dashboard/>
       <div className="containerrs" style={{marginTop:"50px"}}>
      <h1>Add News Category</h1>
      <div className="poll-form">
      <form onSubmit={handleSubmit}>
        <label>
        Name:
          <input type="text" value={heading} onChange={(e) => setHeading(e.target.value)} required />
        </label>
        <br />
        <label>
          Description:
          <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </label>
        <br />
        <label>
          <input type="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} /> Sub Category
        </label>
   
          
    
        <label> 
        Parent:
          <select value={category} onChange={(e) => setCategory(e.target.value)} disabled={!isChecked}>
            <option value="">Select Parent Category</option>
            <option value="देश-विदेश">देश-विदेश  </option>
            <option value="खास मुलाकात">खास मुलाकात</option>
            <option value="राजनीति">राजनीति</option>
            <option value="व्यापार जगत">व्यापार जगत</option>
            <option value="ज्योतिष">ज्योतिष</option>
            <option value="जोधपुर विशेष">जोधपुर विशेष</option>
            <option value="RTI (सुचना का अधिकार)">RTI (सुचना का अधिकार)</option>
            <option value="पर्यावरण संबंधित">पर्यावरण संबंधित</option>
            <option value="राजनीति">धार्मिक - समाचार</option>
            <option value="अपराध">अपराध</option>
            <option value="कोरोना अपडेट">कोरोना अपडेट</option>
            <option value="अजब - गजब">अजब - गजब</option>
            <option value="जॉब एंड कैरियर">जॉब एंड कैरियर</option>
            <option value="तीज - त्यौहार">तीज - त्यौहार</option>
            <option value="इतिहास व सत्यकथा">इतिहास व सत्यकथा</option>
            <option value="आयुर्वेदा">आयुर्वेदा</option>
            <option value="चालीसा व आरती संग्रह">चालीसा व आरती संग्रह</option>
            <option value="अंतरराष्ट्रीय ">अंतरराष्ट्रीय </option>
            <option value="राष्ट्रीय ">राष्ट्रीय </option>
            <option value="भ्रष्टाचार के खिलाफ मुहिम">भ्रष्टाचार के खिलाफ मुहिम</option>
            <option value="शाही रसोई ">शाही रसोई </option>
            <option value="लेख/ Article">लेख/ Article</option>
          </select>
        </label>
        <br />
        <div className="form-group">
                     <div className="checkbox-group">
                        <label>
                         Website 1:
                           <input
                             type="checkbox"
                             name="website1"
                             checked={website1}
                             onChange={(e) => setWebsite1(e.target.checked)}
                                />
                         </label>
                      <label>
                          Website 2:
                            <input
                            type="checkbox"
                           name="website2"
                              checked={website2}
                             onChange={(e) => setWebsite2(e.target.checked)}
                           />
                       </label>
                      </div>
                  </div>
        <br />
        <button type="submit">Submit</button>
      </form>
      </div>
      </div>
      <AdminFooter/>
    </>
  );
}

export default App;