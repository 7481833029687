import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin'; // Import Footer
import "../Addnews/Addnews.css"; // Common styles

const ListNewsUsers = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const [reporters, setReporters] = useState([]);
    const [loading, setLoading] = useState(false); // General loading for fetch/delete/update
    const [message, setMessage] = useState(''); // Feedback messages
    const [error, setError] = useState(''); // Specific error message state

    // --- Edit State ---
    const [editingReporterId, setEditingReporterId] = useState(null);
    const [editingReporter, setEditingReporter] = useState(null);
    const [newPhoto, setNewPhoto] = useState(null);
    const [imagePreview, setImagePreview] = useState(null); // For edit mode preview

    // --- Toggle State ---
    const [togglingReporterId, setTogglingReporterId] = useState(null); // ID of reporter being toggled

    useEffect(() => {
        fetchReporters();
    }, []);

    const fetchReporters = async () => {
        setLoading(true);
        setMessage('');
        setError(''); // Clear error on fetch
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error("Authentication token not found."); // Throw error if no token

            const response = await axios.get(`${baseUrl}/reporters`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            // Normalize boolean fields and ensure it's an array
            const fetchedReporters = Array.isArray(response.data) ? response.data : [];
            const normalizedReporters = fetchedReporters.map(reporter => ({
                ...reporter,
                website1: reporter.website1 === true || reporter.website1 === 'true' || reporter.website1 === 1,
                website2: reporter.website2 === true || reporter.website2 === 'true' || reporter.website2 === 1,
            }));
            setReporters(normalizedReporters);

        } catch (error) {
            console.error('Error fetching reporters:', error);
            setError(`Error fetching reporters: ${error.response?.data?.message || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // --- Edit Handlers ---
    const handleEdit = (reporter) => {
         if (togglingReporterId === reporter._id) return; // Prevent edit while toggling

        setEditingReporterId(reporter._id);
        setEditingReporter({ ...reporter });
        setNewPhoto(null);
        setImagePreview(reporter.photo ? `${baseUrl}/${reporter.photo}` : null); // Set initial preview
        setMessage('');
        setError('');
    };

    const handleCancelEdit = () => {
        setEditingReporterId(null);
        setEditingReporter(null);
        setNewPhoto(null);
        setImagePreview(null);
        setMessage('');
        setError('');
    };

    const handleInputChange = (e) => { // For edit mode inputs
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setEditingReporter(prev => ({ ...prev, [name]: newValue }));
    };

    const handlePhotoChange = (e) => { // For edit mode photo
        const file = e.target.files[0];
        if (file) {
            setNewPhoto(file);
            setImagePreview(URL.createObjectURL(file)); // Show preview of new photo
        } else {
            setNewPhoto(null);
            // Optionally revert preview to original if file selection is cancelled
            setImagePreview(editingReporter?.photo ? `${baseUrl}/${editingReporter.photo}` : null);
        }
    };

    // --- Delete Handler ---
    const handleDelete = async (reporterIdToDelete) => {
        if (editingReporterId === reporterIdToDelete || togglingReporterId === reporterIdToDelete) return; // Prevent delete while editing/toggling

        if (!window.confirm("Are you sure you want to delete this reporter?")) return;

        // Use general loading state or add specific delete loading state if needed
        setLoading(true);
        setMessage('');
        setError('');
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error("Authentication token not found.");

            await axios.delete(`${baseUrl}/reporters/${reporterIdToDelete}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setMessage('Reporter deleted successfully');
            setReporters(prev => prev.filter(reporter => reporter._id !== reporterIdToDelete));

        } catch (error) {
            console.error('Error deleting reporter:', error);
            setError(`Error deleting reporter: ${error.response?.data?.message || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // --- Update Handler (Edit Mode Save) ---
    const handleUpdate = async () => {
        if (!editingReporterId || !editingReporter) return;

        setLoading(true); // Consider a specific state like `isUpdating` for the modal
        setMessage('');
        setError('');
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error("Authentication token not found.");

            const formData = new FormData();
            // Append necessary fields from editingReporter state
            formData.append('name', editingReporter.name || '');
            formData.append('mobile', editingReporter.mobile || '');
            formData.append('state', editingReporter.state || '');
            formData.append('district', editingReporter.district || '');
            formData.append('address', editingReporter.address || '');
            formData.append('post', editingReporter.post || '');
            // Send boolean status from editingReporter state
            formData.append('website1', editingReporter.website1);
            formData.append('website2', editingReporter.website2);
            // Append userId ONLY if backend explicitly requires it in the body for PUT
            // formData.append('userId', editingReporter.userId);

            if (newPhoto) {
                formData.append('photo', newPhoto);
            }

            const response = await axios.put(`${baseUrl}/reporters/${editingReporterId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });

            setMessage('Reporter updated successfully');
            handleCancelEdit();
            fetchReporters(); // Refetch list

        } catch (error) {
            console.error('Error updating reporter:', error);
            setError(`Error updating reporter: ${error.response?.data?.message || error.message}`);
        } finally {
            setLoading(false);
        }
    };


    // --- NEW: Handle Status Toggle ---
    const handleToggleStatus = async (id, fieldKey, currentValue, currentReporterData) => {
        if (editingReporterId === id || togglingReporterId === id) return; // Prevent action if editing or already toggling

        setTogglingReporterId(id);
        setMessage('');
        setError('');

        const newValue = !currentValue;

        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error("Authentication token not found.");

            // Prepare payload - **ASSUMING JSON update is possible for non-photo changes**
            // Include fields the backend expects for a non-photo update
            const payload = {
                name: currentReporterData.name,
                mobile: currentReporterData.mobile,
                state: currentReporterData.state,
                district: currentReporterData.district,
                address: currentReporterData.address,
                post: currentReporterData.post,
                // Update the specific field, keep the other status as is
                website1: fieldKey === 'website1' ? newValue : currentReporterData.website1,
                website2: fieldKey === 'website2' ? newValue : currentReporterData.website2,
                 // Add userId ONLY if backend expects it in the body payload for PUT/PATCH
                // userId: currentReporterData.userId,
            };

            // *** VERIFY the UPDATE endpoint and METHOD (PUT/PATCH) for JSON updates ***
            await axios.put(`${baseUrl}/reporters/${id}`, payload, { // OR axios.patch
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json' // Sending JSON
                }
            });

            // Optimistic UI Update
            setReporters(prevReporters =>
                prevReporters.map(reporter =>
                    reporter._id === id
                        ? { ...reporter, [fieldKey]: newValue } // Update the specific field
                        : reporter
                )
            );
            // Optional: setMessage(`${fieldKey} status updated.`);

        } catch (error) {
            console.error(`Error toggling ${fieldKey}:`, error);
             setError(`Error updating ${fieldKey}: ${error.response?.data?.message || error.message}`);
        } finally {
            setTogglingReporterId(null); // Reset loading state for this item
        }
    };


    // --- Render ---
    return (
        <>
            <Dashboard />
            <div className="containerrs list-news-container list-reporters-container" style={{ marginTop: "50px" }}>
                <h1>List Reporters</h1>

                {message && <div className="message success-message">{message}</div>}
                {error && <div className="message error-message">{error}</div>}

                {loading && !reporters.length ? ( // Show loading only if list is empty initially
                    <p className="loading-indicator">Loading reporters...</p>
                ) : (
                    <div className="table-responsive">
                        <table className="polls-table news-list-table reporters-list-table">
                            <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>Photo</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>State</th>
                                    <th>District</th>
                                    <th style={{ textAlign: 'center' }}>Web 1</th>
                                    <th style={{ textAlign: 'center' }}>Web 2</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reporters.length === 0 && !loading ? (
                                    <tr><td colSpan="9" style={{ textAlign: 'center' }}>No reporters found.</td></tr>
                                ) : (
                                    reporters.map((reporter) => (
                                        <tr key={reporter._id}>
                                            {/* --- Edit Mode Row --- */}
                                            {editingReporterId === reporter._id ? (
                                                <>
                                                    <td>{reporter.userId}</td>
                                                    <td >
                                                        {imagePreview ? (
                                                            <img src={imagePreview} alt="Preview"  />
                                                        ) : (
                                                           <span className="no-photo-placeholder">No Photo</span>
                                                        )}
                                                        <input type="file" accept="image/*" onChange={handlePhotoChange} disabled={loading}/>
                                                    </td>
                                                    <td><input type="text" name="name" value={editingReporter.name || ''} onChange={handleInputChange} disabled={loading} /></td>
                                                    <td><input type="text" name="mobile" value={editingReporter.mobile || ''} onChange={handleInputChange} disabled={loading} /></td>
                                                    <td><input type="text" name="state" value={editingReporter.state || ''} onChange={handleInputChange} disabled={loading} /></td>
                                                    <td><input type="text" name="district" value={editingReporter.district || ''} onChange={handleInputChange} disabled={loading} /></td>
                                                    {/* Edit mode Checkboxes */}
                                                    <td style={{ textAlign: 'center' }}>
                                                        <input type="checkbox" name="website1" checked={!!editingReporter.website1} onChange={handleInputChange} disabled={loading} style={{ transform: 'scale(1.3)' }}/>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <input type="checkbox" name="website2" checked={!!editingReporter.website2} onChange={handleInputChange} disabled={loading} style={{ transform: 'scale(1.3)' }}/>
                                                    </td>
                                                    <td className="action-cell">
                                                        <button className="action-button update" onClick={handleUpdate} disabled={loading}>Save</button>
                                                        <button className="action-button cancel" onClick={handleCancelEdit} disabled={loading}>Cancel</button>
                                                    </td>
                                                </>
                                            ) : (
                                            /* --- View Mode Row --- */
                                            <>
                                                <td>{reporter.userId}</td>
                                                <td className="photo-cell">
                                                    {reporter.photo ? (
                                                        <img src={`${baseUrl}/${reporter.photo}`} alt={reporter.name} style={{ width: '90px', height: '90px', borderRadius: '10%' }}/>
                                                    ) : (
                                                        <span className="no-photo-placeholder">No Photo</span>
                                                    )}
                                                </td>
                                                <td>{reporter.name}</td>
                                                <td>{reporter.mobile}</td>
                                                <td>{reporter.state}</td>
                                                <td>{reporter.district}</td>
                                                {/* Status Toggles */}
                                                <td style={{ textAlign: 'center' }}>
                                                    <span
                                                        className={`status-toggle ${togglingReporterId === reporter._id ? 'loading' : ''}`}
                                                        onClick={() => handleToggleStatus(reporter._id, 'website1', reporter.website1, reporter)}
                                                        title={`Toggle Website 1 (${reporter.website1 ? 'Active' : 'Inactive'})`}
                                                    >
                                                        {togglingReporterId === reporter._id ? '...' : (reporter.website1 ? '✅' : '❌')}
                                                    </span>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                     <span
                                                        className={`status-toggle ${togglingReporterId === reporter._id ? 'loading' : ''}`}
                                                        onClick={() => handleToggleStatus(reporter._id, 'website2', reporter.website2, reporter)}
                                                        title={`Toggle Website 2 (${reporter.website2 ? 'Active' : 'Inactive'})`}
                                                    >
                                                        {togglingReporterId === reporter._id ? '...' : (reporter.website2 ? '✅' : '❌')}
                                                    </span>
                                                </td>
                                                <td className="action-cell">
                                                    <button className="action-button edit" onClick={() => handleEdit(reporter)} disabled={loading || togglingReporterId === reporter._id}>Edit</button>
                                                    <button className="action-button delete" onClick={() => handleDelete(reporter._id)} disabled={loading || editingReporterId === reporter._id || togglingReporterId === reporter._id}>Delete</button>
                                                </td>
                                            </>
                                            )}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
             <AdminFooter /> {/* Add Footer */}
        </>
    );
};

export default ListNewsUsers;