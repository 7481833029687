import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import './Addnews.css'; // Make sure this path is correct
import AdminFooter from '../Footer/Footeradmin'; // Make sure this path is correct
import { getToken, getUserRole } from '../Login/Authutils'; // Make sure this path is correct

const ListNewNews = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    // State Variables
    const [reporterSubmissions, setReporterSubmissions] = useState([]);
    const [publishedNews, setPublishedNews] = useState([]);
    const [loadingReporter, setLoadingReporter] = useState(false);
    const [loadingPublished, setLoadingPublished] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Editing State
    const [editingArticleId, setEditingArticleId] = useState(null);
    const [editingArticle, setEditingArticle] = useState(null);
    const [editingType, setEditingType] = useState(null); // 'reporterPending' or 'published'
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    // Article flags state (for modal)
    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);
    const [mostPopular, setMostPopular] = useState(false);
    const [featured, setFeatured] = useState(false);
    const [popular, setPopular] = useState(false);
    const [latestNews, setLatestNews] = useState(false);
    const [mostViewed, setMostViewed] = useState(false);
    const [recentPosts, setRecentPosts] = useState(false);

    // Modal State
    const [showModal, setShowModal] = useState(false);

    // User Role State
    const [isAdmin, setIsAdmin] = useState(false);

    // --- Effects ---
    useEffect(() => {
        const role = getUserRole();
        const isAdminUser = role === 'admin';
        setIsAdmin(isAdminUser);
        // Fetch data based on role
        fetchReporterSubmissions(isAdminUser);
        fetchPublishedNews();
    }, []);

    // --- Data Fetching Functions ---
    const fetchReporterSubmissions = async (isAdminUser) => {
        setLoadingReporter(true);
        setError('');
        setMessage('');
        try {
            const token = getToken();
            if (!token) throw new Error("Authentication token not found.");

            // ADMIN fetches all pending, REPORTER fetches their own
            const url = isAdminUser
                ? `${baseUrl}/admin/reporter-news/pending`
                : `${baseUrl}/reporter/news`;

            const response = await axios.get(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setReporterSubmissions(response.data);

        } catch (err) {
            console.error('Error fetching reporter submissions:', err);
            setError(`Error fetching submissions: ${err.response?.data?.message || err.message}`);
            setReporterSubmissions([]); // Clear data on error
        } finally {
            setLoadingReporter(false);
        }
    };

    const fetchPublishedNews = async () => {
        setLoadingPublished(true);
        setError('');
        try {
            const token = getToken(); // Token might be needed if GET /news is protected
            if (!token) throw new Error("Authentication token not found.");

            const response = await axios.get(`${baseUrl}/news`, {
                headers: { 'Authorization': `Bearer ${token}` } // Send token if needed by backend
            });
            setPublishedNews(response.data);

        } catch (err) {
            console.error('Error fetching published news articles:', err);
            setError(`Error fetching published news: ${err.response?.data?.message || err.message}`);
            setPublishedNews([]); // Clear data on error
        } finally {
            setLoadingPublished(false);
        }
    };

    // --- Modal Handling ---
    const openEditModal = (article, type) => {
        // Double check permission before opening modal
        if (type === 'published' && !isAdmin) {
            setError("Only admins can edit published articles.");
            return;
        }
        if (type === 'reporterPending' && isAdmin) {
             setError("Admins should approve/reject, not edit pending submissions.");
             return; // Admins don't edit pending items via this modal
        }
        if (type === 'reporterPending' && !isAdmin && article.status !== 0) {
             setError("You can only edit your pending submissions.");
             return; // Reporters can only edit pending
        }


        setEditingArticleId(article._id);
        setEditingArticle({ ...article });
        setEditingType(type);
        setNewImage(null);

        // Set checkbox/flag states
        setWebsite1(article.website1 || false);
        setWebsite2(article.website2 || false);
        setMostPopular(article.mostPopular || false);
        setFeatured(article.featured || false);
        setPopular(article.popular || false);
        setLatestNews(article.latestNews || false);
        setMostViewed(article.mostViewed || false);
        setRecentPosts(article.recentPosts || false);

        setImagePreview(article.image ? `${baseUrl}/${article.image.startsWith('/') ? article.image.substring(1) : article.image}` : null);

        setShowModal(true);
        setError('');
        setMessage('');
    };

    const handleCancelEdit = () => {
        setShowModal(false);
        setEditingArticleId(null);
        setEditingArticle(null);
        setEditingType(null);
        setNewImage(null);
        setImagePreview(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingArticle(prev => ({ ...prev, [name]: value }));
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setNewImage(file);
            setImagePreview(URL.createObjectURL(file));
        } else {
            setNewImage(null);
            setImagePreview(editingArticle?.image ? `${baseUrl}/${editingArticle.image.startsWith('/') ? editingArticle.image.substring(1) : editingArticle.image}` : null);
        }
    };

    // --- Action Handlers (with explicit Admin checks where needed) ---

    // REPORTER: Update own pending submission
    const handleUpdateReporterPending = async () => {
        // This function is only called by the reporter via the modal's update button
        // when editingType is 'reporterPending'. No extra isAdmin check needed here.
        if (!editingArticleId || editingType !== 'reporterPending') return;
         // Add check for status just in case modal was opened incorrectly
        if (editingArticle?.status !== 0) {
             setError("Cannot update an already processed submission.");
             return;
        }

        setError('');
        setMessage('');
        // ... (rest of the FormData creation and axios PUT to /reporter/news/:id remains the same)
        try {
            const token = getToken();
            const formData = new FormData();
            formData.append('category', editingArticle.category);
            formData.append('title', editingArticle.title);
            formData.append('viewSide', editingArticle.viewSide);
            formData.append('description', editingArticle.description);
            formData.append('date', editingArticle.date ? new Date(editingArticle.date).toISOString().split('T')[0] : '');
            formData.append('website1', website1);
            formData.append('website2', website2);
            formData.append('mostPopular', mostPopular);
            formData.append('featured', featured);
            formData.append('popular', popular);
            formData.append('latestNews', latestNews);
            formData.append('mostViewed', mostViewed);
            formData.append('recentPosts', recentPosts);

            if (newImage) {
                formData.append('image', newImage);
            }

            await axios.put(`${baseUrl}/reporter/news/${editingArticleId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            setMessage('Your submission updated successfully');
            handleCancelEdit();
            fetchReporterSubmissions(false); // Fetch reporter's own news

        } catch (err) {
            console.error('Error updating reporter submission:', err);
            setError(`Error updating submission: ${err.response?.data?.message || err.message}`);
        }
    };

    // ADMIN: Update published news article
    const handleUpdatePublished = async () => {
        // Explicit check for admin role, although button visibility should prevent non-admins
        if (!isAdmin) {
             setError("Unauthorized action.");
             return;
        }
        if (!editingArticleId || editingType !== 'published') return;

        setError('');
        setMessage('');
        // ... (rest of the FormData creation and axios PUT to /news/:id remains the same)
         try {
            const token = getToken();
            const formData = new FormData();
            formData.append('category', editingArticle.category);
            formData.append('title', editingArticle.title);
            formData.append('viewSide', editingArticle.viewSide);
            formData.append('description', editingArticle.description);
            formData.append('date', editingArticle.date ? new Date(editingArticle.date).toISOString().split('T')[0] : '');
            formData.append('website1', website1);
            formData.append('website2', website2);
            formData.append('mostPopular', mostPopular);
            formData.append('featured', featured);
            formData.append('popular', popular);
            formData.append('latestNews', latestNews);
            formData.append('mostViewed', mostViewed);
            formData.append('recentPosts', recentPosts);

            if (newImage) {
                formData.append('image', newImage);
            }

            await axios.put(`${baseUrl}/news/${editingArticleId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            setMessage('Published article updated successfully');
            handleCancelEdit();
            fetchPublishedNews(); // Refresh published list

        } catch (err) {
            console.error('Error updating published article:', err);
            setError(`Error updating article: ${err.response?.data?.message || err.message}`);
        }
    };

    // REPORTER: Delete own pending submission
    const handleDeleteReporterSubmission = async (id) => {
        // Only reporters should see the button calling this. No extra isAdmin check needed.
        if (window.confirm('Are you sure you want to delete your pending submission?')) {
            setError('');
            setMessage('');
            // ... (rest of the axios DELETE to /reporter/news/:id remains the same)
            try {
                const token = getToken();
                await axios.delete(`${baseUrl}/reporter/news/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setMessage('Submission deleted successfully');
                fetchReporterSubmissions(false); // Refresh reporter's list
            } catch (err) {
                console.error('Error deleting reporter submission:', err);
                setError(`Error deleting submission: ${err.response?.data?.message || err.message}`);
            }
        }
    };

     // ADMIN: Delete published news article
     const handleDeletePublished = async (id) => {
        // Explicit check for admin role
         if (!isAdmin) {
             setError("Unauthorized action.");
             return;
        }
        if (window.confirm('Are you sure you want to permanently delete this published article?')) {
            setError('');
            setMessage('');
            // ... (rest of the axios DELETE to /news/:id remains the same)
             try {
                const token = getToken();
                await axios.delete(`${baseUrl}/news/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setMessage('Published article deleted successfully');
                fetchPublishedNews(); // Refresh published list
            } catch (err) {
                console.error('Error deleting published article:', err);
                setError(`Error deleting article: ${err.response?.data?.message || err.message}`);
            }
        }
    };

    // ADMIN: Approve pending submission
    const handleApprove = async (articleId) => {
        // Explicit check for admin role
        if (!isAdmin) {
             setError("Unauthorized action.");
             return;
        }
        setError('');
        setMessage('');
        // ... (rest of the axios POST to /admin/approve-news/:id remains the same)
         try {
            const token = getToken();
            await axios.post(`${baseUrl}/admin/approve-news/${articleId}`, {}, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            setMessage('Article approved and published successfully');
            fetchReporterSubmissions(true); // Refresh pending list
            fetchPublishedNews();           // Refresh published list
        } catch (err) {
            console.error('Error approving article:', err);
            setError(`Error approving article: ${err.response?.data?.message || err.message}`);
        }
    };

    // ADMIN: Reject pending submission
    const handleReject = async (articleId) => {
         // Explicit check for admin role
         if (!isAdmin) {
             setError("Unauthorized action.");
             return;
        }
         if (window.confirm('Are you sure you want to reject and delete this submission?')) {
            setError('');
            setMessage('');
            // ... (rest of the axios DELETE to /admin/reject-news/:id remains the same)
             try {
                const token = getToken();
                await axios.delete(`${baseUrl}/admin/reject-news/${articleId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                setMessage('Submission rejected successfully');
                fetchReporterSubmissions(true); // Refresh pending list
            } catch (err) {
                console.error('Error rejecting article:', err);
                setError(`Error rejecting article: ${err.response?.data?.message || err.message}`);
            }
        }
    };

    const renderImage = (imagePath, title) => {
        if (!imagePath) return null;
        const imageUrl = `${baseUrl}/${imagePath.startsWith('/') ? imagePath.substring(1) : imagePath}`;
        return (
             <img
                src={imageUrl}
                alt={title || 'News Image'}
                style={{ width: '90px', height: 'auto', maxHeight: '90px', borderRadius: '10%', objectFit: 'cover' }}
                onError={(e) => { e.target.style.display = 'none'; console.warn(`Image failed to load: ${imageUrl}`); }}
            />
        );
    };

    // Determine if the modal update button should be enabled
    const isModalUpdateDisabled = () => {
        if (editingType === 'reporterPending') {
            // Reporter can only update if status is 0
            return !isAdmin && editingArticle?.status !== 0;
        }
        if (editingType === 'published') {
            // Only admin can update published
            return !isAdmin;
        }
        return true; // Disable if type is unknown
    };


    return (
        <>
            <Dashboard />
            <div className="containerrs list-news-container" style={{ marginTop: "50px" }}>
                <h1>Manage News Articles</h1>
                {message && <div className="success-message">{message}</div>}
                {error && <div className="error-message">{error}</div>}

                {/* Section 1: Pending Submissions (Admin) or My Submissions (Reporter) */}
                <section>
                    <h2>{isAdmin ? 'Pending Reporter Submissions' : 'My Submitted News'}</h2>
                    {loadingReporter ? <p>Loading submissions...</p> : (
                        reporterSubmissions.length === 0
                        ? <p>{isAdmin ? 'No pending submissions.' : 'You have not submitted any news yet.'}</p>
                        : (
                            <table className="polls-table fixed-layout-table news-list-table">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Title</th>
                                        <th>Image</th>
                                        <th>View Side</th>
                                        <th style={{ minWidth: '200px' }}>Description</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                        {/* Admin Review column only shown to Admins */}
                                        {isAdmin && <th>Admin Review</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {reporterSubmissions.map((article) => (
                                        <tr key={article._id}>
                                            <td>{article.category}</td>
                                            <td>{article.title}</td>
                                            <td>{renderImage(article.image, article.title)}</td>
                                            <td>{article.viewSide}</td>
                                            <td className="description-cell">{article.description}</td>
                                            <td>{new Date(article.date).toLocaleDateString()}</td>
                                            <td>{article.status === 0 ? 'Pending' : (article.status === 1 ? 'Approved' : 'Unknown')}</td>
                                            <td>
                                                {/* Reporter Actions: Only Edit/Delete if status is 0 */}
                                                {!isAdmin && article.status === 0 && (
                                                    <>
                                                        <button onClick={() => openEditModal(article, 'reporterPending')}>Edit</button>
                                                        <button className="delete-button" onClick={() => handleDeleteReporterSubmission(article._id)}>Delete</button>
                                                    </>
                                                )}
                                                {/* Show message for reporter if processed */}
                                                {!isAdmin && article.status !== 0 && ( <span>(Processed)</span> )}
                                                {/* Placeholder/Message for Admin in Actions column */}
                                                {isAdmin && ( <span>(Use Review Below)</span> )}
                                            </td>
                                            {/* Admin Review Actions Column */}
                                            {isAdmin && (
                                                <td>
                                                    <button className="approve-button" onClick={() => handleApprove(article._id)}>Approve</button>
                                                    <button className="reject-button" onClick={() => handleReject(article._id)}>Reject</button>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )
                    )}
                </section>

                <hr style={{margin: "40px 0"}}/>

                

            </div>
            <AdminFooter />

            {/* Unified Modal for Editing */}
            {showModal && editingArticle && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleCancelEdit}>×</span>
                        <h2>{`Edit ${editingType === 'reporterPending' ? 'Pending Submission' : 'Published Article'}`}</h2>

                        {/* Common Form Fields - Disable based on role/status for safety */}
                        <div className="form-group">
                            <label htmlFor="category">Category:</label>
                            <input type="text" id="category" name="category" value={editingArticle.category || ''} onChange={handleInputChange} disabled={isModalUpdateDisabled()} />
                        </div>
                         <div className="form-group">
                            <label htmlFor="title">Title:</label>
                            <input type="text" id="title" name="title" value={editingArticle.title || ''} onChange={handleInputChange} disabled={isModalUpdateDisabled()} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="image">Image:</label>
                            {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '90px', height: 'auto', maxHeight: '90px', borderRadius: '10%', marginBottom: '10px', display: 'block' }} />}
                            <input type="file" id="image" onChange={handleImageChange} disabled={isModalUpdateDisabled()} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="viewSide">View Side:</label>
                            <select name="viewSide" id="viewSide" value={editingArticle.viewSide || 'left'} onChange={handleInputChange} disabled={isModalUpdateDisabled()}>
                                <option value="left">Left</option>
                                <option value="right">Right</option>
                            </select>
                        </div>
                         <div className="form-group">
                            <label htmlFor="description">Description:</label>
                            <textarea name="description" id="description" value={editingArticle.description || ''} onChange={handleInputChange} rows="4" disabled={isModalUpdateDisabled()} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="date">Date:</label>
                            <input type="date" name="date" id="date" value={editingArticle.date ? new Date(editingArticle.date).toISOString().split('T')[0] : ''} onChange={handleInputChange} disabled={isModalUpdateDisabled()} />
                        </div>

                        {/* Checkboxes */}
                         <div className="form_group_new website_container_new">
                             <label className="label_new"><input type="checkbox" name="website1" checked={website1} onChange={(e) => setWebsite1(e.target.checked)} disabled={isModalUpdateDisabled()} /> Website 1</label>
                             <label className="label_new"><input type="checkbox" name="website2" checked={website2} onChange={(e) => setWebsite2(e.target.checked)} disabled={isModalUpdateDisabled()} /> Website 2</label>
                         </div>
                         <div className="form_group_new tags_container_new">
                             <label className="label_new"><input type="checkbox" name="mostPopular" checked={mostPopular} onChange={(e) => setMostPopular(e.target.checked)} disabled={isModalUpdateDisabled()} /> Most Popular</label>
                             <label className="label_new"><input type="checkbox" name="featured" checked={featured} onChange={(e) => setFeatured(e.target.checked)} disabled={isModalUpdateDisabled()} /> Featured</label>
                             <label className="label_new"><input type="checkbox" name="popular" checked={popular} onChange={(e) => setPopular(e.target.checked)} disabled={isModalUpdateDisabled()} /> Popular</label>
                             <label className="label_new"><input type="checkbox" name="latestNews" checked={latestNews} onChange={(e) => setLatestNews(e.target.checked)} disabled={isModalUpdateDisabled()} /> Latest News</label>
                             <label className="label_new"><input type="checkbox" name="mostViewed" checked={mostViewed} onChange={(e) => setMostViewed(e.target.checked)} disabled={isModalUpdateDisabled()} /> Most Viewed</label>
                             <label className="label_new"><input type="checkbox" name="recentPosts" checked={recentPosts} onChange={(e) => setRecentPosts(e.target.checked)} disabled={isModalUpdateDisabled()} /> Recent Posts</label>
                         </div>

                        {/* Status display (informational only) */}
                         {editingType === 'reporterPending' && (
                            <div className="form-group">
                                <label>Status:</label>
                                <span>{editingArticle.status === 0 ? 'Pending' : 'Processed'}</span>
                            </div>
                         )}

                        <div className="form-actions">
                            <button
                                onClick={editingType === 'reporterPending' ? handleUpdateReporterPending : handleUpdatePublished}
                                disabled={isModalUpdateDisabled()} // Use helper to disable button correctly
                            >
                                Update
                            </button>
                            <button onClick={handleCancelEdit}>Cancel</button>
                        </div>
                        {error && <p className="error-message" style={{marginTop: '10px'}}>{error}</p>}
                    </div>
                </div>
            )}
        </>
    );
};

export default ListNewNews;