// src/components/ProtectedRoute.js

import React, { useState, useEffect } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { getToken, removeToken } from './Authutils'; // Adjust path as needed

const ProtectedRoute = ({ children }) => {
  const baseUrl = process.env.REACT_APP_API_URL; // Fallback URL
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null = checking, true = authenticated, false = not authenticated
  const location = useLocation(); // Get current location for redirect

  useEffect(() => {
    const verifyToken = async () => {
      const token = getToken();

      if (!token) {
        setIsAuthenticated(false);
        return;
      } 

      try {
        const response = await fetch(`${baseUrl}/validate-token`, { // Your backend endpoint
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          removeToken(); // Remove invalid token
        }
      } catch (error) {
        console.error('Error validating token:', error);
        setIsAuthenticated(false);
        removeToken(); // Remove token if validation fails due to network error
      }
    };

    verifyToken();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or a spinner
  }

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;