import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './Components/About/About'; // Ensure path is correct
import Home from './Components/Home/Home';
// import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Contact from './Components/Contact-us/Contact';
import Gallery from './Components/Gallery/Gallery';
import VideoGallery from './Components/VideoGallery/VideoGallery';
import AddGalleryImages from './Admin/Addgallery/AddGallery';
import Addcarousel from './Admin/Addcarousels/AddCarousel';
import Login from './Admin/Login/Login';
import Addvideo from './Admin/Addvedio/Addvideo';
import ProtectedRoute from "./Admin/Login/Protected"
import ChangePassword from './Admin/ChangePassword/Changepassword';
import NewsSection from './Admin/Addnews/Addnews';
import AddTestimonial from './Admin/Addtestimonials/Addtestimonials';
import FullNewsPage from './Components/News/News';
import Listvideo from './Admin/Addvedio/Listvideo';
import Listgallery from './Admin/Addgallery/Listgallery';
import Addpolls from './Admin/Addpolls/Addpolls';
import Listpolls from './Admin/Addpolls/Listpolls';
import Addnewspaper from './Admin/AddNewspaper/Addnewspaper';
import Listnewspaper from './Admin/AddNewspaper/Listnewspaper';
import Listnewscategory from './Admin/NewsCategory/Listnewscategory';
import Addnewscategory from './Admin/NewsCategory/Addnewscategory';
import Adduser from './Admin/AddUser/Adduser';
import Listuser from './Admin/AddUser/Listuser';
import ListNewNews from './Admin/Addnews/Listnews';
import Footeradmin from './Admin/Footer/Footeradmin';
import Addadvertise from './Admin/Addadvertise/Addadvertise';
import Listadvertise from './Admin/Addadvertise/Listadvertise';
import Employee from './Admin/EmployeeRegister/Employee';
import Empdash from './Admin/EmpDashboard/Empdash';
import Empaddnews from './Admin/EmpAddNews/Empaddnews';
import Emplistaddnews from './Admin/EmpAddNews/Emplistaddnews';
import Approvalnews from './Admin/Addnews/Approvalnews';
import Addhead from './Admin/AddHeadingmarquee/Addhead';
import Listhead from './Admin/AddHeadingmarquee/Listhead';
import Statusnews from './Admin/Addnews/Statusnews';
import Repoterlogin from './Admin/Login/Repoterlogin';
import Categoryabout from './Components/CategoryAbout/Categoryabout';
import EPaperPage from './Components/NewspaperPfd/EPaperPage';
import EPaperViewer from './Components/NewspaperPfd/EPaperViewer';
import CategoryNewsPage from './Components/Categorypages/CategoryNewsPage';
import Weather from './Components/weather/Weather';

export default function App() {
  return (
    <Router>
      {/* <Navbar/> */}
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/news/:id" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/videogallery" element={<VideoGallery />} />
        <Route path="/news" element={<FullNewsPage />} />
        <Route path="/categoryabout/:categoryName" element={<Categoryabout />} />
        <Route path="/footeradmin" element={<Footeradmin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/epaper" element={<EPaperPage />} />
        <Route path="/weather" element={<Weather />} />
        <Route path="/epaper/:newspaperId" element={<EPaperViewer />} />
        <Route path="*" element={<div><h2>404 Not Found</h2><a href="/">Go Home</a></div>} />
        <Route
            path="/category/:categoryName" // Matches /category/ANYTHING
            element={<CategoryNewsPage/>} // Renders this component
          />
        <Route
          path="/changepass"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addadvertise"
          element={
            <ProtectedRoute>
              <Addadvertise />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listadvertise"
          element={
            <ProtectedRoute>
              < Listadvertise/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/repoterlogin"
          element={
            
              < Repoterlogin/>
           
          }
        />
         <Route
          path="/addpoll"
          element={
            <ProtectedRoute>
              <Addpolls />
            </ProtectedRoute>
          }
        />
         <Route
          path="/addnewspaper"
          element={
            <ProtectedRoute>
              <Addnewspaper />
            </ProtectedRoute>
          }
        />
         <Route
          path="/listnewspaper"
          element={
            <ProtectedRoute>
              <Listnewspaper />
            </ProtectedRoute>
          }
        />
         <Route
          path="/listnewscategory"
          element={
            <ProtectedRoute>
              <Listnewscategory />
            </ProtectedRoute>
          }
        />
          <Route
          path="/addnewscategory"
          element={
            <ProtectedRoute>
              <Addnewscategory />
            </ProtectedRoute>
          }
        />
          <Route
          path="/listpoll"
          element={
            <ProtectedRoute>
              <Listpolls />
            </ProtectedRoute>
          }
        />
          <Route
          path="/listgallery"
          element={
            <ProtectedRoute>
              <Listgallery />
            </ProtectedRoute>
          }
        />
         <Route
          path="/listvideo"
          element={
            <ProtectedRoute>
              <Listvideo />
            </ProtectedRoute>
          }
        />
         <Route
          path="/adduser"
          element={
            <ProtectedRoute>
              <Adduser />
            </ProtectedRoute>
          }
        />
          <Route
          path="/listuser"
          element={
            <ProtectedRoute>
              <Listuser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addtestimonials"
          element={
            <ProtectedRoute>
              <AddTestimonial />
            </ProtectedRoute>
          }
        />
       
          <Route
          path="/addnews"
          element={
            <ProtectedRoute>
              <NewsSection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listnews"
          element={
            <ProtectedRoute>
              <ListNewNews />
            </ProtectedRoute>
          }
        />
          <Route
          path="/addgallery"
          element={
            <ProtectedRoute>
              <AddGalleryImages />
            </ProtectedRoute>
          }
        />
         <Route
          path="/addvideo"
          element={
            <ProtectedRoute>
              <Addvideo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addcarousel"
          element={
            <ProtectedRoute>
              <Addcarousel />
            </ProtectedRoute>
          }
        />
         <Route
          path="/employee"
          element={
            <ProtectedRoute>
              <Employee />
            </ProtectedRoute>
          }
        />
         <Route
          path="/employee-dashboard"
          element={
            <ProtectedRoute>
              <Empdash />
            </ProtectedRoute>
          }
        />
         <Route
          path="/empaddnews"
          
          element={
            <ProtectedRoute>
              <Empaddnews />
            </ProtectedRoute>
          }
        />
          <Route
          path="/status"
          
          element={
            <ProtectedRoute>
              <Statusnews />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addhead"
          element={
            <ProtectedRoute>
              <Addhead />
            </ProtectedRoute>
          }
        />
        <Route
          path="/listhead"
          element={
            <ProtectedRoute>
              <Listhead />
            </ProtectedRoute>
          }
        />
          <Route
          path="/emplistaddnews"
          element={
            <ProtectedRoute>
              <Emplistaddnews />
            </ProtectedRoute>
          }
        />
          <Route
          path="/approval"
          element={
            <ProtectedRoute>
              <Approvalnews />
            </ProtectedRoute>
          }
        />
      </Routes>
      {/* <Footer/> */}
    </Router>
  );
}
