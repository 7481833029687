// src/Pages/CategoryNewsPage/CategoryNewsPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './CategoryNewsPage.css'; // Styles specific to this page
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

// Placeholder image URL
const PLACEHOLDER_IMAGE = '/images/placeholder-news-elegant.png';
const AD_PLACEHOLDER = '/images/ad-placeholder-300x250.png';
const ITEMS_PER_PAGE = 10; // Define items per page

export default function CategoryNewsPage() {
    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const { categoryName } = useParams();
    const decodedCategoryName = decodeURIComponent(categoryName);

    // State for the main category news
    const [allFilteredNewsItems, setAllFilteredNewsItems] = useState([]); // Renamed to hold ALL items
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [adBanner, setAdBanner] = useState(null);
const [adLoading, setAdLoading] = useState(true);
const [adError, setAdError] = useState(null);


    // --- NEW: State for pagination ---
    const [currentPage, setCurrentPage] = useState(1);

    // --- State for LATEST News Sidebar ---
    const [latestNews, setLatestNews] = useState([]);
    const [loadingLatest, setLoadingLatest] = useState(true);
    const [errorLatest, setErrorLatest] = useState(null);

    useEffect(() => {
        const fetchAdBanner = async () => {
            setAdLoading(true);
            setAdError(null);
            try {
                const res = await axios.get(`${API_BASE_URL}/advertisements?location=category`);
                if (Array.isArray(res.data) && res.data.length > 0) {
                    setAdBanner(res.data[0]); // Pick the first ad for this location
                } else {
                    setAdBanner(null);
                }
            } catch (err) {
                console.error('Error fetching advertisement:', err);
                setAdError('Failed to load advertisement.');
            } finally {
                setAdLoading(false);
            }
        };
    
        fetchAdBanner();
    }, [API_BASE_URL]);

    
    // --- Effect for Fetching & Filtering Main Category News ---
    useEffect(() => {
        const fetchAndFilterNews = async () => {
            setIsLoading(true);
            setError(null);
            setAllFilteredNewsItems([]); // Clear all items
            setCurrentPage(1); // Reset to page 1 when category changes
            const apiUrl = `${API_BASE_URL}/news`;

            try {
                const response = await axios.get(apiUrl);
                if (response.data && Array.isArray(response.data)) {
                    const filteredData = response.data.filter(item =>
                        item.category?.toLowerCase() === decodedCategoryName.toLowerCase() ||
                        (!item.category && item.heading?.toLowerCase() === decodedCategoryName.toLowerCase()) // Fallback if category missing
                    );
                    // Sort all fetched items
                    filteredData.sort((a, b) => new Date(b.publishDate || b.createdAt) - new Date(a.publishDate || a.createdAt));
                    setAllFilteredNewsItems(filteredData); // Store ALL fetched & filtered items
                } else {
                    setAllFilteredNewsItems([]);
                }
            } catch (err) {
                console.error("Error fetching category news:", err);
                setError(`Failed to load news for "${decodedCategoryName}". Please try again later.`);
            } finally {
                setIsLoading(false);
            }
        };
        if (decodedCategoryName) fetchAndFilterNews();
    }, [decodedCategoryName, API_BASE_URL]); // Dependency array includes decodedCategoryName

    // --- Effect for Fetching LATEST News (Sidebar) ---
    useEffect(() => {
        const fetchLatestNews = async () => {
            setLoadingLatest(true);
            setErrorLatest(null);
            const latestApiUrl = `${API_BASE_URL}/news?limit=5&sort=-createdAt`; // Or sort=-publishDate

            try {
                const response = await axios.get(latestApiUrl);
                if (response.data && Array.isArray(response.data)) {
                    setLatestNews(response.data);
                } else {
                    setLatestNews([]);
                }
            } catch (err) {
                console.error("Error fetching latest news:", err);
                setErrorLatest("Could not load latest news.");
            } finally {
                setLoadingLatest(false);
            }
        };
        fetchLatestNews();
    }, [API_BASE_URL]);

    // --- Helper Functions ---
    const getImageUrl = (imagePath) => {
        if (!imagePath) return PLACEHOLDER_IMAGE;
        if (/^https?:\/\//.test(imagePath)) return imagePath;
        const path = imagePath.startsWith('/') ? imagePath.substring(1) : imagePath;
        const separator = (API_BASE_URL.endsWith('/') && path.startsWith('/')) ? '' :
                          (!API_BASE_URL.endsWith('/') && !path.startsWith('/')) ? '/' : '';
        return `${API_BASE_URL}${separator}${path}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        try {
            return new Date(dateString).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            });
        } catch (e) { return ''; }
    };

    const getShortDescription = (text, wordLimit = 20) => {
       if (!text) return 'No description available.';
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    // --- Pagination Calculations ---
    const totalItems = allFilteredNewsItems.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

    // Calculate items for the current page
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentItems = allFilteredNewsItems.slice(startIndex, endIndex); // Items to display on this page

    // --- Pagination Handlers ---
    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages)); // Prevent going beyond last page
        window.scrollTo(0, 0); // Optional: scroll to top on page change
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1)); // Prevent going below page 1
        window.scrollTo(0, 0); // Optional: scroll to top on page change
    };


    return (
        <>
        <Navbar/>
        <div className="categorynewsabout-page">
            <div className="categorynewsabout-container">

                {/* --- Page Title Bar - Enhanced --- */}
                <div className="categorynewsabout-title-bar">
                    <h1 className="categorynewsabout-page-title">{decodedCategoryName}</h1>
                    {/* Optional: Breadcrumb or subtitle */}
                </div>

                <div className="categorynewsabout-layout-grid">

                    {/* === Main Content Column === */}
                    <main className="categorynewsabout-main-content">
                        {/* --- Loading/Error/No Results --- */}
                        {isLoading && (
                             <div className="categorynewsabout-status-indicator categorynewsabout-loading-indicator">
                                <div className="categorynewsabout-spinner"></div><span>Loading Content...</span>
                             </div>
                        )}
                        {error && <p className="categorynewsabout-status-indicator categorynewsabout-error-message">{error}</p>}
                        {!isLoading && !error && totalItems === 0 && ( // Check totalItems now
                            <p className="categorynewsabout-status-indicator categorynewsabout-no-results-message">
                                No articles found in "{decodedCategoryName}".
                            </p>
                        )}

                        {/* --- Display Current Page Items --- */}
                        {!isLoading && !error && currentItems.length > 0 && ( // Check currentItems for rendering
                            <div className="categorynewsabout-news-grid">
                                {/* Map over currentItems INSTEAD of allFilteredNewsItems */}
                                {currentItems.map((item) => (
                                    item._id ? (
                                        <article key={item._id} className="categorynewsabout-news-card">
                                            <div className="categorynewsabout-news-card-image-wrapper">
                                                <Link to={`/news/${item._id}`} aria-label={`Read more about ${item.title || 'article'}`}>
                                                    <img
                                                        src={getImageUrl(item.image)}
                                                        alt={item.title || 'News image'}
                                                        className="categorynewsabout-news-card-image"
                                                        loading="lazy" // Add lazy loading
                                                        onError={(e) => { if (e.target.src !== PLACEHOLDER_IMAGE) { e.target.onerror = null; e.target.src = PLACEHOLDER_IMAGE; }}}
                                                    />
                                                </Link>
                                                {item.category && (
                                                    <span className="categorynewsabout-news-card-category-badge">
                                                        {item.category}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="categorynewsabout-news-card-content">
                                                <h2 className="categorynewsabout-news-card-title">
                                                    <Link to={`/news/${item._id}`}>
                                                        {item.title || 'Untitled Article'}
                                                    </Link>
                                                </h2>

                                                {/* Meta Info: Date & Optional Author */}
                                                <div className="categorynewsabout-news-card-meta">
                                                    <span className="categorynewsabout-news-card-date">
                                                        <i className="far fa-calendar-alt"></i> {/* Icon */}
                                                        {formatDate(item.publishDate || item.createdAt)}
                                                    </span>
                                                    {/* Check if author data exists */}
                                                    {item.author && (
                                                         <span className="categorynewsabout-news-card-author">
                                                             <i className="far fa-user"></i> {/* Icon */}
                                                             {item.author}
                                                         </span>
                                                    )}
                                                </div>

                                                <p className="categorynewsabout-news-card-description">
                                                    {getShortDescription(item.summary || item.description)}
                                                </p>

                                                {/* Separated Read More Link */}
                                                <div className="categorynewsabout-news-card-read-more-link">
                                                    <Link to={`/news/${item._id}`}>
                                                        Read More <i className="fas fa-arrow-right"></i> {/* Ensure FA is linked */}
                                                    </Link>
                                                </div>
                                            </div>
                                        </article>
                                    ) : null
                                ))}
                            </div>
                        )}

                        {/* --- NEW: Pagination Controls --- */}
                        {!isLoading && !error && totalPages > 1 && (
                            <div className="categorynewsabout-pagination-controls">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                    className="categorynewsabout-pagination-button categorynewsabout-pagination-prev"
                                    aria-label="Go to previous page" // Accessibility
                                >
                                    <i className="fas fa-chevron-left" aria-hidden="true"></i> Previous
                                </button>
                                <span className="categorynewsabout-pagination-info" aria-live="polite"> {/* Announce changes */}
                                    Page {currentPage} of {totalPages}
                                </span>
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                    className="categorynewsabout-pagination-button categorynewsabout-pagination-next"
                                     aria-label="Go to next page" // Accessibility
                                >
                                    Next <i className="fas fa-chevron-right" aria-hidden="true"></i>
                                </button>
                            </div>
                        )}
                    </main>

                    {/* === Sidebar Column === */}
                    <aside className="categorynewsabout-sidebar">
                        {/* --- LATEST NEWS WIDGET --- */}
                        <div className="categorynewsabout-sidebar-widget categorynewsabout-latest-news-widget">
                            <h4 className="categorynewsabout-widget-title">Latest News</h4>
                            <div className="categorynewsabout-widget-content">
                                {loadingLatest ? (
                                    <div className="categorynewsabout-widget-status categorynewsabout-widget-loading">Loading...</div>
                                ) : errorLatest ? (
                                     <div className="categorynewsabout-widget-status categorynewsabout-widget-error">{errorLatest}</div>
                                ) : latestNews.length > 0 ? (
                                    latestNews.map(news => (
                                        news._id ? (
                                            <Link to={`/news/${news._id}`} className="categorynewsabout-latest-news-item" key={news._id}>
                                                <div className="categorynewsabout-latest-news-item-image">
                                                    <img
                                                        src={getImageUrl(news.image)}
                                                        alt={news.title || 'Latest news'}
                                                        loading="lazy"
                                                        onError={(e) => { if (e.target.src !== PLACEHOLDER_IMAGE) { e.target.onerror = null; e.target.src = PLACEHOLDER_IMAGE; }}}
                                                        />
                                                </div>
                                                <div className="categorynewsabout-latest-news-item-content">
                                                    <h5 className="categorynewsabout-latest-news-item-title">{news.title || 'Untitled'}</h5>
                                                    <span className="categorynewsabout-latest-news-item-date">
                                                         <i className="far fa-clock"></i> {/* Icon */}
                                                         {formatDate(news.date || news.createdAt)}
                                                    </span>
                                                </div>
                                            </Link>
                                        ) : null
                                    ))
                                ) : (
                                    <div className="categorynewsabout-widget-status categorynewsabout-widget-placeholder">No latest news found.</div>
                                )}
                            </div>
                        </div>

                        {/* --- Advertisement Widget --- */}
                        <div className="categorynewsabout-sidebar-widget categorynewsabout-ad-widget">
                             <h4 className="categorynewsabout-widget-title">Advertisement</h4>
                             <div className="categorynewsabout-widget-content">
                                {/* Ensure the ad link opens in a new tab and indicates it's sponsored */}
                               {adLoading ? (
    <div className="categorynewsabout-widget-status categorynewsabout-widget-loading">Loading ad...</div>
) : adError ? (
    <div className="categorynewsabout-widget-status categorynewsabout-widget-error">{adError}</div>
) : adBanner ? (
    <a href={adBanner.link || "#"} target="_blank" rel="noopener sponsored" aria-label="Advertisement">
        <img
            src={getImageUrl(adBanner.image)}
            alt="Advertisement"
            className="categorynewsabout-ad-widget-image"
            loading="lazy"
            onError={(e) => { if (e.target.src !== AD_PLACEHOLDER) { e.target.onerror = null; e.target.src = AD_PLACEHOLDER; }}}
        />
    </a>
) : (
    <div className="categorynewsabout-widget-status categorynewsabout-widget-placeholder">No ad available.</div>
)}

                            </div>
                        </div>
                    </aside>

                </div> {/* End categorynewsabout-layout-grid */}
            </div> {/* End categorynewsabout-container */}
        </div> {/* End categorynewsabout-page */}
        <Footer/>
        </>
    );
}