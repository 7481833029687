import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './News.css'; // Import CSS file for styling
import NavbarAdvanced from '../Navbar/Navbar';
import FooterAdvanced from '../Footer/Footer';

const FullNewsPage = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL; // Fallback URL

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/news`);
        setNews(response.data);
      } catch (error) {
        setError(error);
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return <div className="loading-spinner">Loading all news...</div>;
  }

  if (error) {
    return <div className="error-message">Error: {error.message}</div>;
  }

  return (
    <>
      <NavbarAdvanced />
      <div className="full-news-page-container">
        <header className="news-header">
          <h1>Latest News</h1>
          <p className="header-subtitle">Stay updated on current events.</p>
        </header>

        <main className="news-grid">
          {news.map((article) => {
            const formattedDate = new Date(article.date).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            });

            return (
              <article key={article.id} className="news-card animated-card">
                <div className="news-card-content">
                  <h2 className="news-card-title">{article.title}</h2>
                  <p className="news-card-date">{formattedDate}</p>
                  <p className="news-card-summary">{article.summary}</p>
                </div>
              </article>
            );
          })}
        </main>
      </div>
      <FooterAdvanced />
    </>
  );
};

export default FullNewsPage;
