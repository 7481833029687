import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard"; // Check path
import './Adduser.css'; // Check path
import Footeradmin from '../Footer/Footeradmin'; // Check path

const AddUser = () => {
    // Ensure the base URL is correctly configured or provide a default
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    // --- State Variables ---
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [photo, setPhoto] = useState(null); // Holds the File object
    const [photoPreview, setPhotoPreview] = useState(null); // For displaying image preview
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [selectedState, setSelectedState] = useState(''); // Renamed for clarity
    const [selectedDistrict, setSelectedDistrict] = useState(''); // Renamed for clarity
    const [address, setAddress] = useState('');
    const [post, setPost] = useState('');
    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);

    // Feedback Messages & Loading State
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // To disable button during submission

    // --- State and District Data ---
    // Consider fetching this from backend in a real app
    const [statesData, setStatesData] = useState([
        { name: 'Madhya Pradesh', districts: ['Indore', 'Bhopal', 'Jabalpur', 'Gwalior', 'Ujjain'] },
        { name: 'Uttar Pradesh', districts: ['Lucknow', 'Kanpur', 'Varanasi', 'Agra', 'Prayagraj'] },
        { name: 'Maharashtra', districts: ['Mumbai', 'Pune', 'Nagpur', 'Nashik', 'Aurangabad'] },
        // Add more states/districts as needed
    ]);
    const [districts, setDistricts] = useState([]);

    // --- Effects ---
    useEffect(() => {
        // Update districts based on selected state
        const stateInfo = statesData.find((s) => s.name === selectedState);
        setDistricts(stateInfo ? stateInfo.districts : []);
        // Reset district selection when state changes
        setSelectedDistrict('');
    }, [selectedState, statesData]);

    // --- Handlers ---
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPhoto(file);
            // Create a preview URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhotoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPhoto(null);
            setPhotoPreview(null);
        }
    };

    const resetForm = () => {
        setUserId('');
        setPassword('');
        setPhoto(null);
        setPhotoPreview(null); // Clear preview
        document.getElementById('photo').value = null; // Clear file input visually
        setName('');
        setMobile('');
        setSelectedState('');
        setSelectedDistrict('');
        setAddress('');
        setPost('');
        setWebsite1(false);
        setWebsite2(false);
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        // Frontend validation (optional, backend also validates)
        if (!userId || !password || !photo || !name || !mobile || !selectedState || !selectedDistrict || !address || !post) {
            setErrorMessage('Please fill in all required fields and upload a photo.');
            return;
        }
        if (password.length < 6) {
            setErrorMessage('Password must be at least 6 characters long.');
            return;
        }

        setIsLoading(true); // Disable button

        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('password', password);
        formData.append('photo', photo); // Append the File object
        formData.append('name', name);
        formData.append('mobile', mobile);
        formData.append('state', selectedState);
        formData.append('district', selectedDistrict);
        formData.append('address', address);
        formData.append('post', post);
        formData.append('website1', website1); // Send boolean directly, backend handles 'true'/'false' strings too
        formData.append('website2', website2);

        try {
            const response = await axios.post(`${baseUrl}/reporter/register`, formData, {
                // No Authorization header needed for public registration
                headers: {
                    // Axios sets Content-Type automatically for FormData
                    // 'Content-Type': 'multipart/form-data',
                },
            });

            setSuccessMessage(response.data.message || 'Reporter registered successfully!'); // Use backend message
            resetForm(); // Clear the form

        } catch (error) {
            console.error('Error adding reporter:', error);
            // Display specific error from backend if available
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred during registration: ' + error.message);
            }
        } finally {
            setIsLoading(false); // Re-enable button
        }
    };

    return (
        <>
            <Dashboard />
            <div className="containerrs add-user-container"> {/* Added specific class */}
                <h1>Add Reporter</h1>
                {successMessage && <div className="alert alert-success">{successMessage}</div>} {/* Use alert classes */}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} {/* Use alert classes */}

                <form onSubmit={handleAddUser} noValidate> {/* Added noValidate to rely on backend */}
                    {/* Row 1 */}
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="userId">Reporter ID <span className="required">*</span></label>
                            <input
                                type="text"
                                id="userId"
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                                required
                                disabled={isLoading}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password <span className="required">*</span></label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                minLength="6" // Basic HTML validation
                                disabled={isLoading}
                            />
                        </div>
                    </div>

                    {/* Row 2 */}
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="name">Full Name <span className="required">*</span></label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                disabled={isLoading}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile Number <span className="required">*</span></label>
                            <input
                                type="tel" // Use tel type for mobile
                                id="mobile"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                required
                                // Add pattern for basic validation if desired: pattern="[0-9]{10}"
                                disabled={isLoading}
                            />
                        </div>
                    </div>

                    {/* Row 3: State and District */}
                     <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="state">State <span className="required">*</span></label>
                            <select
                                id="state"
                                value={selectedState}
                                onChange={(e) => setSelectedState(e.target.value)}
                                required
                                disabled={isLoading}
                            >
                                <option value="">-- Select State --</option>
                                {statesData.map((s) => (
                                    <option key={s.name} value={s.name}>
                                        {s.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                         <div className="form-group">
                            <label htmlFor="district">District <span className="required">*</span></label>
                            <select
                                id="district"
                                value={selectedDistrict}
                                onChange={(e) => setSelectedDistrict(e.target.value)}
                                required
                                disabled={!selectedState || isLoading} // Disable if no state or loading
                            >
                                <option value="">-- Select District --</option>
                                {districts.map((district) => (
                                    <option key={district} value={district}>
                                        {district}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                     {/* Row 4: Address and Post */}
                    <div className="form-row">
                         <div className="form-group form-group-full"> {/* Make address span full width */}
                            <label htmlFor="address">Full Address <span className="required">*</span></label>
                            <textarea
                                id="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                                rows="3"
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                       <div className="form-group">
                            <label htmlFor="post">Post/Designation <span className="required">*</span></label>
                            <input
                                type="text"
                                id="post"
                                value={post}
                                onChange={(e) => setPost(e.target.value)}
                                required
                                disabled={isLoading}
                            />
                        </div>
                         <div className="form-group">
                            <label htmlFor="photo">Upload Photo <span className="required">*</span></label>
                            <input
                                type="file"
                                id="photo"
                                onChange={handlePhotoChange}
                                accept="image/jpeg, image/png, image/gif" // Specify accepted types
                                required
                                disabled={isLoading}
                            />
                            {photoPreview && (
                                <img src={photoPreview} alt="Photo Preview" className="photo-preview" />
                            )}
                        </div>
                    </div>


                     {/* Row 5: Website Checkboxes */}
                     <div className="form-row">
                         <div className="form-group form-group-full checkbox-group"> {/* Special styling for checkboxes */}
                             <label>Website Assignment:</label>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    name="website1"
                                    checked={website1}
                                    onChange={(e) => setWebsite1(e.target.checked)}
                                    disabled={isLoading}
                                /> Website 1
                            </label>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    name="website2"
                                    checked={website2}
                                    onChange={(e) => setWebsite2(e.target.checked)}
                                    disabled={isLoading}
                                /> Website 2
                            </label>
                        </div>
                    </div>

                    <div className="form-actions">
                        <button type="submit" className="submit-button" disabled={isLoading}>
                            {isLoading ? 'Adding...' : 'Add Reporter'}
                        </button>
                    </div>
                </form>
                {/* Removed duplicate message display */}
            </div>
            <Footeradmin/>
        </>
    );
};

export default AddUser;