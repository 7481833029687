// src/components/Admin/AdminNewsApproval.js (Example path)

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard"; // Adjust path as needed
import './Addnews.css'; // Create or use an appropriate CSS file
import AdminFooter from '../Footer/Footeradmin'; // Adjust path as needed
import { getToken } from '../Login/Authutils'; // Adjust path as needed - getUserRole removed if not needed elsewhere

const AdminNewsApproval = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    // State
    const [pendingSubmissions, setPendingSubmissions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(''); // For success messages
    const [error, setError] = useState(''); // For error messages
    // Removed isAdminVerified state

    // --- Initial Data Fetch ---
    useEffect(() => {
        // Directly fetch data assuming the user *should* be an admin to use this page effectively.
        // Backend will handle authorization.
        fetchPendingSubmissions();
        // Optionally fetch published news if displaying it
        // fetchPublishedNews();
    }, []); // Run once on component mount

    // --- Fetch Pending Submissions ---
    const fetchPendingSubmissions = async () => {
        setIsLoading(true);
        setMessage(''); setError('');
        try {
            const token = getToken();
            if (!token) throw new Error("Authentication token missing. Please log in.");

            // Always attempt to fetch the admin endpoint
            const url = `${baseUrl}/admin/reporter-news/pending`;
            const response = await axios.get(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log("Fetched Pending Data:", response.data); // For debugging
            setPendingSubmissions(response.data || []);
        } catch (err) {
            console.error('Error fetching pending submissions:', err);
            // Error handling will now catch 401/403 if a non-admin tries to load
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                 setError("Access Denied: You do not have permission to view pending submissions.");
            } else {
                 setError(`Error fetching submissions: ${err.response?.data?.message || err.message}`);
            }
            setPendingSubmissions([]); // Clear data on error
        } finally {
            setIsLoading(false);
        }
    };

    // Optional: Fetch published news if needed on this page
    /*
    const fetchPublishedNews = async () => {
        // ... implementation ...
    };
    */

    // --- Action Handlers (Approve/Reject) ---
    // These handlers implicitly require an admin token for the backend call to succeed

    // Handle Approval -> POST /admin/approve-news/:id
    const handleApprove = async (articleId) => {
        setMessage(''); setError('');
        setIsLoading(true);
        try {
            const token = getToken();
            if (!token) throw new Error("Token missing.");

            const url = `${baseUrl}/admin/approve-news/${articleId}`;
            const response = await axios.post(url, {}, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            setMessage(response.data?.message || 'Article approved and published!');
            fetchPendingSubmissions(); // Refresh list

        } catch (err) {
            console.error('Error approving article:', err);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                 setError("Action failed: You do not have permission to approve submissions.");
            } else {
                 setError(`Error approving article: ${err.response?.data?.message || err.message}`);
            }
            setIsLoading(false); // Ensure loading stops on error
        }
         // Loading stops via fetchPendingSubmissions' finally block on success
    };

    // Handle Rejection -> DELETE /admin/reject-news/:id
    const handleReject = async (articleId) => {
        if (window.confirm('Are you sure you want to reject and delete this submission?')) {
            setMessage(''); setError('');
            setIsLoading(true);
            try {
                const token = getToken();
                if (!token) throw new Error("Token missing.");

                const url = `${baseUrl}/admin/reject-news/${articleId}`;
                await axios.delete(url, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                setMessage('Submission rejected and deleted successfully.');
                fetchPendingSubmissions(); // Refresh list

            } catch (err) {
                console.error('Error rejecting article:', err);
                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                     setError("Action failed: You do not have permission to reject submissions.");
                } else {
                    setError(`Error rejecting article: ${err.response?.data?.message || err.message}`);
                }
                setIsLoading(false); // Ensure loading stops on error
            }
             // Loading stops via fetchPendingSubmissions' finally block on success
        }
    };

    // --- Helper Functions ---
    const renderImage = (imagePath, title) => {
        if (!imagePath) return <span className="no-image">(No Image)</span>;
        const imageUrl = `${baseUrl}/${imagePath.startsWith('/') ? imagePath.substring(1) : imagePath}`;
        return (
            <img
                src={imageUrl}
                alt={title || 'News'}
                className="news-thumbnail"
                onError={(e) => { e.target.style.display = 'none'; console.warn(`Image failed: ${imageUrl}`); }}
            />
        );
    };

    // --- Main Render ---
    return (
        <>
            <Dashboard />
            <div className="containerrs admin-approval-container list-news-container">
                <h1>Admin News Approval</h1>

                {/* Display Messages */}
                {message && <div className="alert alert-success">{message}</div>}
                {/* Error is crucial now, as it will show unauthorized messages */}
                {error && <div className="alert alert-danger">{error}</div>}

                <section className="approval-section">
                    <h2>Pending Reporter Submissions ({pendingSubmissions.length})</h2>

                    {/* Show loading or the table/no submissions message */}
                    {isLoading ? (
                        <p>Loading pending submissions...</p>
                    ) : error ? (
                         // If there was an error loading (like 403), don't show the table
                         <p style={{ color: 'red' }}>Could not load submissions.</p>
                    ) : pendingSubmissions.length === 0 ? (
                        <p>There are no pending news submissions awaiting approval.</p>
                    ) : (
                        <div className="table-responsive">
                            <table className="approval-table news-list-table">
                                <thead>
                                    <tr>
                                        <th>Reporter</th>
                                        <th>Category</th>
                                        <th>Title</th>
                                        <th>Image</th>
                                        <th>Submitted On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pendingSubmissions.map((article) => (
                                        <tr key={article._id}>
                                            <td>
                                                <span className="reporter-name">
                                                    {article.reporterId?.name || 'N/A'}
                                                </span>
                                                <br />
                                                <span className="reporter-id">
                                                    ({article.reporterId?.userId || 'No ID'})
                                                </span>
                                            </td>
                                            <td>{article.category || '-'}</td>
                                            <td>{article.title || '-'}</td>
                                            <td>{renderImage(article.image, article.title)}</td>
                                            <td>{article.date ? new Date(article.date).toLocaleDateString() : '-'}</td>
                                            <td className="action-cell">
                                                <button
                                                    className="button-approve"
                                                    onClick={() => handleApprove(article._id)}
                                                    disabled={isLoading}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    className="button-reject"
                                                    onClick={() => handleReject(article._id)}
                                                    disabled={isLoading}
                                                >
                                                    Reject
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </section>
            </div>
            <AdminFooter />
        </>
    );
};

export default AdminNewsApproval;