// src/Components/Categories/ManageCategories.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard"; // Adjust path if needed
import AdminFooter from '../Footer/Footeradmin'; // Adjust path if needed
import './Category.css'; // Single CSS file for this component

const ManageCategories = () => {
    // --- Configuration ---
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    // --- State ---
    const [newscategories, setNewsCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);

    // Edit Form State
    const [editHeading, setEditHeading] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editIsSubCategory, setEditIsSubCategory] = useState(false);
    const [editParentCategory, setEditParentCategory] = useState('');
    const [editWebsite1, setEditWebsite1] = useState(false);
    const [editWebsite2, setEditWebsite2] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    // --- Effects ---
    useEffect(() => {
        fetchNewsCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --- API Calls & Handlers ---

    const fetchNewsCategories = async () => {
        setLoading(true);
        setMessage('');
        setError('');
        setNewsCategories([]);

        try {
            console.log(`Fetching categories from: ${baseUrl}/newscategories`);
            const response = await axios.get(`${baseUrl}/newscategories`, {
                headers: {
                    'Cache-Control': 'no-cache'
                 }
            });

            if (Array.isArray(response.data)) {
                 console.log("Categories fetched successfully:", response.data.length);
                 setNewsCategories(response.data);
            } else {
                console.warn("Received non-array data for categories:", response.data);
                setError("Received unexpected data format from server.");
                setNewsCategories([]);
            }

        } catch (err) {
            console.error('Error fetching news categories:', err);
            if (err.response) {
                console.error("Server Response Error:", err.response.status, err.response.data);
                 setError(`Error fetching categories (${err.response.status}): ${err.response.data?.message || 'Server error'}`);
            } else if (err.request) {
                console.error("Network/Request Error:", err.request);
                setError(`Network Error: Could not reach the server at ${baseUrl}. Please check the API URL, CORS settings, and if the server is running.`);
            } else {
                console.error("Axios Setup Error:", err.message);
                setError(`Error: ${err.message}`);
            }
            setNewsCategories([]);
        } finally {
            setLoading(false);
        }
    };


    const handleDeleteCategory = async (id) => {
        if (!window.confirm('Are you sure you want to delete this category? This action cannot be undone.')) {
            return;
        }
        setMessage('');
        setError('');

        try {
            await axios.delete(`${baseUrl}/newscategories/${id}`);
            setMessage('Category deleted successfully!');
            fetchNewsCategories();

        } catch (err) {
            console.error('Error deleting category:', err);
             setError(`Error deleting category: ${err.response?.data?.message || err.message}`);
        }
    };

    // --- Edit Modal Logic ---

    const handleEditClick = (categoryToEdit) => {
        if (!categoryToEdit || !categoryToEdit._id) {
             console.error("Invalid category data for editing:", categoryToEdit);
             setError("Cannot edit: Invalid category data provided.");
             return;
        }
        setEditingCategory(categoryToEdit);
        setEditHeading(categoryToEdit.heading || '');
        setEditDescription(categoryToEdit.description || '');
        const isSub = !!categoryToEdit.category;
        setEditIsSubCategory(isSub);
        setEditParentCategory(categoryToEdit.category || '');
        setEditWebsite1(!!categoryToEdit.website1);
        setEditWebsite2(!!categoryToEdit.website2);

        setIsEditModalOpen(true);
        setMessage('');
        setError('');
    };

    const handleCloseEditModal = () => {
        if (modalLoading) return;
        setIsEditModalOpen(false);
        setEditingCategory(null);
        setEditHeading('');
        setEditDescription('');
        setEditIsSubCategory(false);
        setEditParentCategory('');
        setEditWebsite1(false);
        setEditWebsite2(false);
        setError('');
        setMessage('');
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        if (!editingCategory || !editingCategory._id) {
            setError("Error: No category selected for update.");
            return;
        }
        if (!editHeading.trim()) {
            setError("Category Name is required.");
            return;
        }
        if (editIsSubCategory && !editParentCategory) {
            setError("Parent Category must be selected if 'Is Sub Category?' is checked.");
            return;
        }

        setModalLoading(true);
        setMessage('');
        setError('');

        const updatedData = {
            heading: editHeading.trim(),
            description: editDescription.trim(),
            category: editIsSubCategory ? editParentCategory : null,
            website1: editWebsite1,
            website2: editWebsite2,
        };
        console.log("Sending update data:", updatedData);

        try {
            const response = await axios.put(
                `${baseUrl}/newscategories/${editingCategory._id}`,
                updatedData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            setMessage('Category updated successfully!');
            handleCloseEditModal();
            fetchNewsCategories();

        } catch (err) {
            console.error('Error updating category:', err);
            if (err.response) {
                 console.error("Server Response Error (Update):", err.response.status, err.response.data);
                  if (err.response.data?.errors) {
                     const validationErrors = Object.values(err.response.data.errors).map(e => e.message).join('; ');
                     setError(`Update failed: Validation errors (${validationErrors})`);
                 } else {
                     setError(`Update failed (${err.response.status}): ${err.response.data?.message || 'Server error'}`);
                 }
            } else if (err.request) {
                 setError(`Network Error during update: Could not reach the server.`);
            } else {
                 setError(`Error during update: ${err.message}`);
            }
        } finally {
            setModalLoading(false);
        }
    };


    // --- Helper ---
    const formatDateTime = (dateString) => {
        if (!dateString) return 'N/A';
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) return 'Invalid Date';
            return date.toLocaleString('en-GB', {
                day: '2-digit', month: '2-digit', year: 'numeric',
                hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
            });
        } catch (e) { return 'Invalid Date'; }
    };

    // Filter potential parents for the dropdown
    const potentialParents = newscategories.filter(cat =>
        !cat.category &&
        (!editingCategory || cat._id !== editingCategory._id)
    ).sort((a, b) => a.heading.localeCompare(b.heading));

    // --- Render ---
    return (
        <>
            <Dashboard />
             {/* // --- Applied classes from the example --- */}
            <div className="containerrs list-news-container" style={{ marginTop: "50px" }}>
                <h1>Manage News Categories</h1>

                {/* Main Status Messages */}
                {!isEditModalOpen && message && <div className="alert alert-success">{message}</div>}
                {!isEditModalOpen && error && <div className="alert alert-danger">{error}</div>}
                {loading && <div className="loading-indicator">Loading categories... <span className="spinner"></span></div>}

                <div className="table-responsive">
                     {/* // --- Applied classes from the example --- */}
                    <table className="polls-table news-list-table">
                        <thead>
                            <tr>
                                <th>Parent</th>
                                <th>Category Name</th>
                                <th style={{minWidth: '200px'}}>Description</th>
                                <th>Created At</th>
                                <th>Website 1</th>
                                <th>Website 2</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Handle loading and empty states */}
                            {!loading && newscategories.length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="no-data-cell">
                                        {error ? 'Could not load categories.' : 'No categories found.'}
                                    </td>
                                </tr>
                            ) : (
                                Array.isArray(newscategories) && newscategories.map((categoryItem) => {
                                    const parent = categoryItem.category
                                        ? newscategories.find(p => p._id === categoryItem.category)
                                        : null;
                                    const parentName = parent ? parent.heading : (categoryItem.category);

                                    return (
                                        <tr key={categoryItem._id}>
                                            <td>{parentName}</td>
                                            <td>{categoryItem.heading || '(No Name)'}</td>
                                            <td
                                                className="description-cell"
                                                title={categoryItem.description || ''}
                                            >
                                                {categoryItem.description || ''}
                                            </td>
                                            <td>{formatDateTime(categoryItem.createdAt)}</td>
                                            <td className={`status-cell ${categoryItem.website1 ? 'status-yes' : 'status-no'}`}>
                                                {categoryItem.website1 ? '✅ ' : '❌ '}
                                            </td>
                                            <td className={`status-cell ${categoryItem.website2 ? 'status-yes' : 'status-no'}`}>
                                                {categoryItem.website2 ? '✅ ' : '❌ '}
                                            </td>
                                            <td className="action-cell">
                                                <button
                                                   
                                                    onClick={() => handleEditClick(categoryItem)}
                                                    disabled={loading || isEditModalOpen || modalLoading}
                                                    title="Edit Category"
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                   
                                                    onClick={() => handleDeleteCategory(categoryItem._id)}
                                                    disabled={loading || isEditModalOpen || modalLoading}
                                                    title="Delete Category"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* --- Edit Modal --- */}
            {isEditModalOpen && editingCategory && (
                <div className="modal" onClick={handleCloseEditModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2>Edit Category: {editingCategory.heading}</h2>
                        <button className="modal-close-btn" onClick={handleCloseEditModal} aria-label="Close" disabled={modalLoading}>×</button>

                        {/* Modal Status Messages */}
                         {error && <div className="alert alert-danger modal-error">{error}</div>}

                        <form onSubmit={handleUpdateSubmit} noValidate>
                            {/* Category Name */}
                            <div className="form-group">
                                <label htmlFor="edit-heading">Category Name: *</label>
                                <input
                                    id="edit-heading"
                                    type="text"
                                    value={editHeading}
                                    onChange={(e) => setEditHeading(e.target.value)}
                                    required
                                    disabled={modalLoading}
                                    aria-required="true"
                                />
                            </div>

                             {/* Description */}
                             <div className="form-group">
                                <label htmlFor="edit-description">Description:</label>
                                <textarea
                                    id="edit-description"
                                    value={editDescription}
                                    onChange={(e) => setEditDescription(e.target.value)}
                                    rows={3}
                                    disabled={modalLoading}
                                />
                             </div>

                             {/* Is Sub Category Checkbox */}
                             <div className="form-group checkbox-label inline-checkbox">
                                <input
                                    id="edit-isSubCategory"
                                    type="checkbox"
                                    checked={editIsSubCategory}
                                    onChange={(e) => {
                                         const isChecked = e.target.checked;
                                         setEditIsSubCategory(isChecked);
                                         if (!isChecked) setEditParentCategory('');
                                     }}
                                    disabled={modalLoading}
                                />
                                 <label htmlFor="edit-isSubCategory">Is this a Sub Category?</label>
                            </div>

                            {/* Parent Category Select */}
                            <div className="form-group">
                                <label htmlFor="edit-parentCategory">Parent Category: {editIsSubCategory ? '*' : ''}</label>
                                <select
                                    id="edit-parentCategory"
                                    value={editParentCategory}
                                    onChange={(e) => setEditParentCategory(e.target.value)}
                                    disabled={!editIsSubCategory || modalLoading}
                                    required={editIsSubCategory}
                                    aria-required={editIsSubCategory}
                                >
                                     <option value="">{editIsSubCategory ? '-- Select Parent --' : '(Not a Sub Category)'}</option>
                                     {potentialParents.map(parentCat => (
                                         <option key={parentCat._id} value={parentCat._id}>
                                             {parentCat.heading}
                                         </option>
                                     ))}
                                </select>
                            </div>

                             {/* Website Assignment Checkboxes */}
                            <div className="form-group checkbox-group-container">
                                <p className="checkbox-group-title">Assign to Websites:</p>
                                <div className="checkbox-group">
                                    <div className="checkbox-label inline-checkbox">
                                        <input
                                            id="edit-website1"
                                            type="checkbox"
                                            checked={editWebsite1}
                                            onChange={(e) => setEditWebsite1(e.target.checked)}
                                            disabled={modalLoading}
                                        />
                                         <label htmlFor="edit-website1">Website 1</label>
                                    </div>
                                    <div className="checkbox-label inline-checkbox">
                                        <input
                                            id="edit-website2"
                                            type="checkbox"
                                            checked={editWebsite2}
                                            onChange={(e) => setEditWebsite2(e.target.checked)}
                                            disabled={modalLoading}
                                        />
                                         <label htmlFor="edit-website2">Website 2</label>
                                    </div>
                                </div>
                            </div>

                            {/* Modal Actions */}
                            <div className="modal-actions">
                                <button type="button"  onClick={handleCloseEditModal} disabled={modalLoading}>
                                    Cancel
                                </button>
                                <button type="submit"  disabled={modalLoading}>
                                    {modalLoading ? (<>Saving... <span className="spinner small"></span></>) : 'Save Changes'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {/* --- End Edit Modal --- */}

            <AdminFooter />
        </>
    );
};

export default ManageCategories;