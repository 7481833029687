import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css'; // Assuming you keep the same CSS file or create ReporterLogin.css
import { setToken, getToken, clearToken } from './Authutils';
import AdminFooter from '../Footer/Footeradmin'; // Consider if this footer is appropriate or use a different one

const ReporterLogin = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [userId, setUserId] = useState(''); // Only Reporter ID needed
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    useEffect(() => {
        // Check for an existing token on component mount
        const token = getToken();
        if (token) {
            axios.post(`${baseUrl}/validate-token`, {}, { // Still validate token
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                const { role } = response.data;
                if (role === 'reporter') {
                    navigate('/employee-dashboard'); // Redirect reporter if already logged in
                } else {
                     // If a non-reporter token exists on reporter login page, clear it
                    console.warn("Non-reporter token found on reporter login page. Clearing token.");
                    clearToken();
                     // Stay on the reporter login page or navigate('/reporter-login') if routes are set up
                }
            })
            .catch(error => {
                console.error("Token validation failed:", error);
                clearToken();
                 // Stay on the reporter login page
            });
        }
         // No need to navigate to /login as we are already on a specific login page
    }, [baseUrl, navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors
        try {
            const loginEndpoint = '/reporter/login'; // Specific endpoint for reporter
            const payload = { userId, password }; // Specific payload for reporter

            const res = await axios.post(`${baseUrl}${loginEndpoint}`, payload, { withCredentials: true });
            setToken(res.data.token);

            toast.success('Reporter login successful!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            // Optional: Decode token client-side for immediate role check (backend validation is primary)
             const token = res.data.token;
             try {
                 const decodedToken = JSON.parse(atob(token.split('.')[1]));
                 if (decodedToken.role === 'reporter') {
                     navigate('/employee-dashboard'); // Navigate to reporter dashboard
                 } else {
                     // This case should ideally not happen if backend is correct
                     console.error("Login successful but received non-reporter token.");
                     toast.error('Login error: Role mismatch.');
                     clearToken();
                     setError('Login error: Role mismatch.');
                 }
             } catch (decodeError) {
                 console.error("Error decoding token:", decodeError);
                 toast.error('Login successful, but could not verify role.');
                 // Proceed with caution or clear token depending on security policy
                 navigate('/employee-dashboard'); // Or potentially clear token and show error
             }

        } catch (err) {
            toast.error('Invalid credentials. Please try again.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError('Invalid credentials');
            console.error('Reporter Login error:', err.response?.data || err.message);
        }
    };

    return (
        <>
            <div className="login-container">
                <ToastContainer />
                <div className="login-card">
                    <h2 className="login-title">Reporter Login</h2>

                     {/* Removed login type buttons */}

                    {error && <div className="error-message">{error}</div>}
                    <form onSubmit={handleLogin} className="login-form">

                        {/* Only Reporter ID Input */}
                        <div className="form-groups">
                            <label htmlFor="userId">Reporter ID:</label>
                            <input
                                type="text" // Or number, depending on your ID format
                                id="userId"
                                placeholder="Enter your Reporter ID"
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                                required
                            />
                        </div>

                        {/* Password Input */}
                        <div className="form-groups">
                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>

                        <button type="submit" className="login-button">Login</button>
                    </form>
                </div>
            </div>
            {/* Consider if AdminFooter is the right footer here */}
            <AdminFooter />
        </>
    );
};

export default ReporterLogin;