import React, { useState } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';

const AddMarqueeHeading = () => {
    const [heading, setHeading] = useState('');
    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);
    const [message, setMessage] = useState('');
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    const handleHeadingChange = (e) => {
        setHeading(e.target.value);
    };

    const handleUploadHeading = async () => {
        if (!heading) {
            setMessage('Please enter a heading.');
            return;
        }

        try {
            const response = await axios.post(`${baseUrl}/marquee-headings`, {
                heading: heading,
                website1: website1.toString(),  // to string
                website2: website2.toString(),  // to String
            });

            setMessage(response.data.message);
            setHeading('');
            setWebsite1(false);
            setWebsite2(false);
        } catch (error) {
            setMessage('Error uploading heading: ' + error.message);
            console.error("upload heading error", error);
        }
    };

    return (
        <>
            <Dashboard />
            <div className="containerrs" style={{ marginTop: "50px" }}>
                <h2>Add Marquee Heading</h2>
                <div className="poll-form">
                    <label htmlFor="heading">Heading:</label>
                    <input
                        type="text"
                        id="heading"
                        value={heading}
                        onChange={handleHeadingChange}
                        placeholder="Enter heading text"
                    />
                    <div className="form-group">
                        <div className="checkbox-group">
                            <label>
                                Website 1:
                                <input
                                    type="checkbox"
                                    name="website1"
                                    checked={website1}
                                    onChange={(e) => setWebsite1(e.target.checked)}
                                />
                            </label>
                            <label>
                                Website 2:
                                <input
                                    type="checkbox"
                                    name="website2"
                                    checked={website2}
                                    onChange={(e) => setWebsite2(e.target.checked)}
                                />
                            </label>
                        </div>
                    </div>
                    <button onClick={handleUploadHeading}>Upload Heading</button>
                </div>
                <p className={message.includes('Error') ? 'error' : ''}>{message}</p>
            </div>
            <AdminFooter />
        </>
    );
};

export default AddMarqueeHeading;