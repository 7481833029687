import React, { useState } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import { getToken } from '../Login/Authutils';
import './Changepassword.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AdminFooter from '../Footer/Footeradmin';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false); // New state

  const backendUrl = process.env.REACT_APP_API_URL;

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    const token = getToken();

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }

    if (passwordStrength !== 'Perfect' && passwordStrength !== 'Good') {
      setError('New password does not meet strength requirements.');
      return;
    }

    try {
      const response = await axios.post(
        `${backendUrl}/change-password`,
        { currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage(response.data.message);

    } catch (err) {
      setError(err.response?.data?.message || 'Failed to change password');
    }
  };

  const checkPasswordStrength = (password) => {
    if (password.length < 8) {
      return 'Weak';
    } else if (password.length < 12) {
      if (/[0-9]/.test(password) || /[^a-zA-Z0-9]/.test(password)) {
        return 'Normal';
      }
      return 'Weak';
    } else {
      if (/[0-9]/.test(password) && /[^a-zA-Z0-9]/.test(password) && /[A-Z]/.test(password)) {
        return 'Perfect';
      }
      return 'Good';
    }
  };

  const handleNewPasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    const strength = checkPasswordStrength(password);
    setPasswordStrength(strength);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <>
      <Dashboard />
      <div className="change-password-container">
        <h2 className="change-password-title">Change Password</h2>
        {message && <div className="success-message">{message}</div>}
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleChangePassword} className="change-password-form">
          <div className="form-groups">
            <label htmlFor="currentPassword">Current Password:</label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-groups">
            <label htmlFor="newPassword">New Password:</label>
            <div className="password-input-container">
              <input
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                value={newPassword}
                onChange={handleNewPasswordChange}
                required
                className="password-input"
              />
              <button
                type="button"
                className="password-toggle-button"
                onClick={toggleShowNewPassword}
              >
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            <div className="form-groups">
            <label htmlFor="confirmPassword">Confirm New Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
            {passwordStrength && (
              <div className={`password-strength ${passwordStrength.toLowerCase()}`}>
                Password Strength: {passwordStrength}
              </div>
            )}
          </div>
          
          <button type="submit" className="submit-button">Change Password</button>
        </form>
      </div>
      <AdminFooter/>
    </>
  );
};

export default ChangePassword;