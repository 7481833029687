import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import './AddTestimonial.css';
import AdminFooter from '../Footer/Footeradmin';

const AddTestimonial = () => {
    const baseUrl = process.env.REACT_APP_API_URL; // Fallback URL
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [testimonialFiles, setTestimonialFiles] = useState([]);  // Store selected image files
    const [message, setMessage] = useState('');
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Load testimonials
    const fetchTestimonials = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/testimonials`);
            setTestimonials(response.data);
        } catch (error) {
            setMessage('Error fetching testimonials: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTestimonials();
    }, []);

    // Handle image selection
    const handleFileChange = (e) => {
        if (e.target.files.length === 4) {
            setTestimonialFiles(e.target.files); // Store 4 image files
            setMessage(''); // Clear any previous error message
        } else {
            setTestimonialFiles([]); // Reset files
            setMessage('Please select exactly 4 images.');
        }
    };

    const handleUploadTestimonial = async () => {
        if (!name || !text) {
            setMessage('Please fill all fields.');
            return;
        }

        if (testimonialFiles.length !== 4) {
            setMessage('Please select exactly 4 images.');
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('text', text);
        for (let i = 0; i < testimonialFiles.length; i++) {
            formData.append('images', testimonialFiles[i]);  // Append images to FormData
        }

        try {
            const response = await axios.post(`${baseUrl}/testimonial`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setMessage(response.data.message);
            setName('');
            setText('');
            setTestimonialFiles([]);
            fetchTestimonials();
        } catch (error) {
            setMessage('Error uploading testimonial: ' + error.message);
        }
    };

    const handleDeleteTestimonial = async (id) => {
        try {
            const response = await axios.delete(`${baseUrl}/testimonials/${id}`);
            setMessage(response.data.message);
            fetchTestimonials();
        } catch (error) {
            setMessage('Error deleting testimonial: ' + error.message);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = testimonials.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(testimonials.length / itemsPerPage);

     const truncateText = (text, maxLength = 100) => {
        if (!text) return "";
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + "...";
    };

    return (
        <>
            <Dashboard />
            <div id='appoint'>
            <div className="testimonial-container">
                <h2>Upload Testimonial</h2>

                <div>
                    <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <textarea placeholder="Testimonial Description" value={text} onChange={(e) => setText(e.target.value)} />
                    <input type="file" multiple onChange={handleFileChange} accept="image/*" />
                    <button onClick={handleUploadTestimonial}>Upload Testimonial</button>
                </div>

                <p className={message.includes('Error') ? 'error' : ''}>{message}</p>

                <h3>Uploaded Testimonials</h3>
                {loading ? (
                    <p>Loading testimonials...</p>
                ) : (
                    <>
                        <table className="testimonial-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Images</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((testimonial) => (
                                        <tr key={testimonial._id}>
                                            <td>{testimonial.name}</td>
                                            <td>{truncateText(testimonial.text)}</td>
                                            <td>
                                                {testimonial.images.map((image, index) => (
                                                    <img key={index} src={`${baseUrl}${image}`} alt="Testimonial" style={{ width: '50px', height: '50px', margin: '5px' }} />
                                                ))}
                                            </td>
                                            <td>
                                                <button onClick={() => handleDeleteTestimonial(testimonial._id)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No testimonials found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="pagination">
                            <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                                Previous
                            </button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                                Next
                            </button>
                        </div>
                    </>
                )}
            </div>
            </div>
            <AdminFooter/>
        </>
    );
};

export default AddTestimonial;