import React, { useState, useEffect, useCallback } from 'react';
import { fetchWeather, fetchForecast } from '../api/fetchWeather';
import { ThermometerHalf, DropletFill, Wind, Speedometer2, SunriseFill, SunsetFill, Search } from 'react-bootstrap-icons';
import './Weather.css'; // Ensure this is imported
import Navbar from '../Navbar/Navbar';
import Footer from "../Footer/Footer"

// --- Helper Functions (Keep formatTime, formatDate, formatWindSpeed as before) ---
const formatTime = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
};
const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' });
};
const formatWindSpeed = (speedMs) => {
    if (speedMs === undefined || speedMs === null) return 'N/A';
    return `${(speedMs * 3.6).toFixed(1)} km/h`;
};

// --- Helper Function to Process Raw Forecast Data (Keep as before) ---
const processForecastData = (rawData) => {
    // ... (Keep the exact forecast processing logic from the previous step)
    if (!rawData || !rawData.list) return [];
    const dailyData = {};
    rawData.list.forEach(item => {
        const date = new Date(item.dt * 1000).toISOString().split('T')[0];
        if (!dailyData[date]) {
            dailyData[date] = { date: date, temps: [], feels_like: [], humidity: [], icons: [], descriptions: [] };
        }
        dailyData[date].temps.push(item.main.temp);
        dailyData[date].feels_like.push(item.main.feels_like);
        dailyData[date].humidity.push(item.main.humidity);
        dailyData[date].icons.push(item.weather[0].icon);
        dailyData[date].descriptions.push(item.weather[0].description);
    });
    const processedForecast = Object.values(dailyData).map(day => {
        const maxTemp = Math.max(...day.temps);
        const middayIndex = rawData.list.findIndex(item => {
            const itemDate = new Date(item.dt * 1000).toISOString().split('T')[0];
            const itemHour = new Date(item.dt * 1000).getHours();
            return itemDate === day.date && itemHour >= 11 && itemHour <= 14;
        });
        let representativeIcon = day.icons[0], representativeDesc = day.descriptions[0], representativeFeelsLike = day.feels_like[0], representativeHumidity = day.humidity[0];
        if (middayIndex !== -1) {
            const middayItem = rawData.list[middayIndex];
            representativeIcon = middayItem.weather[0].icon; representativeDesc = middayItem.weather[0].description; representativeFeelsLike = middayItem.main.feels_like; representativeHumidity = middayItem.main.humidity;
        }
        const dayOfWeek = new Date(day.date).toLocaleDateString('en-US', { weekday: 'short' });
        return { date: day.date, dayOfWeek: dayOfWeek, temp_max: maxTemp.toFixed(1), feels_like: representativeFeelsLike.toFixed(1), humidity: Math.round(representativeHumidity), icon: representativeIcon, condition: representativeDesc };
    });
    const todayDate = new Date().toISOString().split('T')[0];
    return processedForecast.filter(day => day.date > todayDate).slice(0, 5);
};

// --- NEW: Helper Function to Determine Background Class ---
const getWeatherBackgroundClass = (weatherData) => {
    if (!weatherData || !weatherData.weather || weatherData.weather.length === 0) {
        return 'weather-bg-default'; // Default background
    }
    const condition = weatherData.weather[0].main.toLowerCase(); // e.g., 'clear', 'clouds', 'rain'
    const iconCode = weatherData.weather[0].icon; // e.g., '01d', '10n'

    // Prioritize specific conditions
    if (condition.includes('thunderstorm')) return 'weather-bg-thunderstorm';
    if (condition.includes('drizzle')) return 'weather-bg-drizzle';
    if (condition.includes('rain')) return 'weather-bg-rain';
    if (condition.includes('snow')) return 'weather-bg-snow';
    if (condition.includes('mist') || condition.includes('smoke') || condition.includes('haze') || condition.includes('dust') || condition.includes('fog') || condition.includes('sand') || condition.includes('ash') || condition.includes('squall') || condition.includes('tornado')) return 'weather-bg-atmosphere';

    // General conditions
    if (condition.includes('clear')) return 'weather-bg-clear'; // Sunny/Clear
    if (condition.includes('clouds')) {
         // Differentiate clouds based on icon maybe? (e.g., '02d' few clouds vs '04d' overcast)
         if (iconCode === '04d' || iconCode === '04n' ) return 'weather-bg-overcast'; // Heavier clouds
         return 'weather-bg-clouds'; // Lighter/scattered clouds
    }

    return 'weather-bg-default'; // Fallback
}


const Weather = () => {
    const [query, setQuery] = useState('');
    const [weather, setWeather] = useState({});
    const [forecast, setForecast] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const fetchAllWeatherData = useCallback(async (cityName) => {
        // ... (Keep fetch logic exactly the same as previous step)
        if (!cityName) return;
        setLoading(true); setError(''); setWeather({}); setForecast([]);
        try {
            const [currentData, forecastData] = await Promise.all([ fetchWeather(cityName), fetchForecast(cityName) ]);
            setWeather(currentData); setForecast(processForecastData(forecastData));
        } catch (err) { console.error("Error fetching weather data:", err); setError(err.message || 'Failed to fetch weather data.'); setWeather({}); setForecast([]);
        } finally { setLoading(false); }
    }, []);

    useEffect(() => {
        fetchAllWeatherData('Jodhpur');
    }, [fetchAllWeatherData]);

    const handleSearchKeyPress = (e) => {
        if (e.key === 'Enter' && query) { fetchAllWeatherData(query); setQuery(''); }
    };
     const handleSearchClick = () => {
        if (query) { fetchAllWeatherData(query); setQuery(''); }
    };

    const isClearCondition = weather.weather && ['01d', '01n'].includes(weather.weather[0].icon);
    const backgroundClass = getWeatherBackgroundClass(weather); // Get dynamic background class

    return (
        <>
        <Navbar/>
      
        <div className={`weather-main-container ${backgroundClass}`}>
            <div className="weather-content-wrapper">
                {/* Search remains the same */}
                 <div className="weather-search-container">
                    <input type="text" className="weather-search" placeholder="Search location..." value={query} onChange={(e) => setQuery(e.target.value)} onKeyPress={handleSearchKeyPress} aria-label="Search weather by city"/>
                    <button className="weather-search-button" onClick={handleSearchClick} aria-label="Search"><Search size={18} /></button>
                </div>

                {/* Status Display Area remains the same */}
                <div className="weather-status-container">
                     {loading && ( <div className="weather-status weather-loading"><div className="weather-spinner"></div>Loading...</div> )}
                     {error && !loading && ( <div className="weather-status weather-error">{error}</div> )}
                </div>

                {/* Display Current Weather Card */}
                {!loading && !error && weather.main && (
                    // Add 'weather-glass' class for styling
                    <div className="weather-card-current weather-glass">
                         <div className="weather-current-header">
                            <h2 className="weather-city-name">{weather.name}, {weather.sys?.country}</h2>
                            <p className="weather-date">{formatDate(weather.dt)}</p>
                        </div>
                         <div className="weather-current-main">
                            <img className={`weather-current-icon ${isClearCondition ? 'is-clear' : ''}`} src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@4x.png`} alt={weather.weather[0].description} title={weather.weather[0].description}/>
                            <div className="weather-current-temp-details">
                                <p className="weather-current-temp">{weather.main.temp.toFixed(1)}<sup className="weather-degree">°C</sup></p>
                                <p className="weather-current-description">{weather.weather[0].description}</p>
                            </div>
                        </div>
                        {/* Details Grid */}
                        <div className="weather-details-grid">
                            {/* Item 1: Feels Like */}
                            {/* Add 'weather-glass' class to detail items */}
                            <div className="weather-detail-item weather-glass">
                                <ThermometerHalf className="weather-detail-icon" size={20}/>
                                <div className="weather-detail-text"> <span className="weather-detail-label">Feels like</span> <span className="weather-detail-value">{weather.main.feels_like?.toFixed(1)}°C</span> </div>
                            </div>
                             {/* Item 2: Humidity */}
                             <div className="weather-detail-item weather-glass">
                                <DropletFill className="weather-detail-icon" size={20}/>
                                <div className="weather-detail-text"> <span className="weather-detail-label">Humidity</span> <span className="weather-detail-value">{weather.main.humidity}%</span> </div>
                            </div>
                             {/* Item 3: Wind */}
                             <div className="weather-detail-item weather-glass">
                                <Wind className="weather-detail-icon" size={20}/>
                                <div className="weather-detail-text"> <span className="weather-detail-label">Wind</span> <span className="weather-detail-value">{formatWindSpeed(weather.wind?.speed)}</span> </div>
                            </div>
                             {/* Item 4: Pressure */}
                            <div className="weather-detail-item weather-glass">
                                <Speedometer2 className="weather-detail-icon" size={20}/>
                                <div className="weather-detail-text"> <span className="weather-detail-label">Pressure</span> <span className="weather-detail-value">{weather.main.pressure} hPa</span> </div>
                            </div>
                             {/* Item 5: Sunrise */}
                             <div className="weather-detail-item weather-glass">
                                <SunriseFill className="weather-detail-icon" size={20}/>
                                <div className="weather-detail-text"> <span className="weather-detail-label">Sunrise</span> <span className="weather-detail-value">{formatTime(weather.sys?.sunrise)}</span> </div>
                            </div>
                             {/* Item 6: Sunset */}
                             <div className="weather-detail-item weather-glass">
                                <SunsetFill className="weather-detail-icon" size={20}/>
                                <div className="weather-detail-text"> <span className="weather-detail-label">Sunset</span> <span className="weather-detail-value">{formatTime(weather.sys?.sunset)}</span> </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Display 5-Day Forecast */}
                {!loading && !error && forecast.length > 0 && (
                    // Add 'weather-glass' class for styling
                    <div className="weather-forecast-section weather-glass">
                        <h3 className="weather-forecast-title">5-Day Forecast</h3>
                        <div className="weather-forecast-items">
                            {forecast.map((day, index) => ( // Add index for animation delay
                                // Add 'weather-glass' class to forecast items
                                <div key={day.date} className="weather-forecast-item weather-glass" style={{ animationDelay: `${0.2 + index * 0.1}s` }}>
                                    <p className="forecast-day">{day.dayOfWeek}</p>
                                    <img className="forecast-icon" src={`https://openweathermap.org/img/wn/${day.icon}@2x.png`} alt={day.condition} title={day.condition} />
                                    <p className="forecast-temp">{day.temp_max}<sup className="weather-degree-small">°C</sup></p>
                                    <p className="forecast-detail">Feels like {day.feels_like}°C</p>
                                    <p className="forecast-detail">Humidity {day.humidity}%</p>
                                    <p className="forecast-condition">{day.condition}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
           
        </div>
        <Footer/>
        </>
    );
};

export default Weather;