import React, { useState } from 'react';
import axios from 'axios'; // Import axios
import './Contact.css'; // Import global CSS
import NavbarAdvanced from '../Navbar/Navbar';
import FooterAdvanced from '../Footer/Footer';

const Contact = () => {
    const baseUrl = process.env.REACT_APP_API_URL; // Fallback URL
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        reason: '', // Changed 'message' to 'reason'
        drugOfInterest: ''
    });
    const [message, setMessage] = useState(''); // Add state for messages

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseUrl}/appointments`, formData);
            setMessage(response.data.message);  // Show success message
            // Optionally, reset the form after successful submission
            setFormData({ name: '', email: '', phone: '', reason: '', drugOfInterest: '' });

        } catch (error) {
            setMessage(`Error saving reason: ${error.message}`); // Show error message
            console.error("Error:", error); // Log the full error for debugging
        }
    };

    return (
        <>
        <NavbarAdvanced/>
        <div className="contact-drugs-contact-container">
            <div className="contact-drugs-contact-form-wrapper">
                <h2 className="contact-drugs-contact-title">Reach Out for Support</h2>
               
                <form className="contact-drugs-contact-form" onSubmit={handleSubmit}>
                    <div className="form-drugs-contact-group">
                        <label htmlFor="name">Your Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-drugs-contact-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-drugs-contact-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Enter your phone number"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-drugs-contact-group">
                        <label htmlFor="reason">Reason for Contact</label> {/* Changed Label */}
                        <textarea
                            id="reason"              
                            name="reason"         
                            rows="5"
                            placeholder="Tell us more about your situation"
                            value={formData.reason}  
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="button-drugs-contact-submit">
                        Send Message
                    </button>
                    {message && <p>{message}</p>}  {/* Display message */}
                </form>
            </div>

        
        </div>
        <FooterAdvanced/>
        </>
    );
};

export default Contact;