import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import './Captcha.css';

const generateCaptchaText = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from({ length: 6 }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
};

const Captcha = forwardRef(({ onValidate }, ref) => {
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef(null);

  useImperativeHandle(ref, () => ({
    resetCaptcha: () => {
      generateNewCaptcha();
    },
  }));

  useEffect(() => {
    generateNewCaptcha();
  }, []);

  const generateNewCaptcha = () => {
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptcha(newCaptcha);
    setUserInput('');
    onValidate(false);
  };

  const drawCaptcha = (text) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Style the background
    ctx.fillStyle = '#f0f0f0';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Add noise
    for (let i = 0; i < 10; i++) {
      ctx.strokeStyle = '#ccc';
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.stroke();
    }

    // Style the text
    ctx.font = '28px Verdana';
    ctx.fillStyle = '#333';
    ctx.fillText(text, 20, 35);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setUserInput(value);
    onValidate(value === captchaText);
  };

  return (
    <div className="captcha-container">
      <canvas ref={canvasRef} width={180} height={50} className="captcha-canvas" />
      <button type="button" onClick={generateNewCaptcha} className="captcha-refresh">↻</button>
      <input
        type="text"
        placeholder="Enter CAPTCHA"
        value={userInput}
        onChange={handleChange}
        className="captcha-input"
      />
    </div>
  );
});

export default Captcha;
