import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddPolls.css'; // Make sure this CSS file exists and is updated
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';

// Min/Max options allowed in the form
const MIN_OPTIONS = 2;
const MAX_OPTIONS = 6; // Adjust as needed

const AddPolls = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Ensure consistent URL

    // Form State
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState(['', '']); // Start with 2 empty options
    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);

    // Component State
    const [message, setMessage] = useState('');
    const [error, setError] = useState(''); // Separate error state
    const [polls, setPolls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false); // State for submission in progress


    // Fetch existing polls on mount
    useEffect(() => {
        fetchPolls();
    }, []);

    const fetchPolls = async () => {
        setLoading(true);
        setMessage('');
        setError('');
        try {
            // Assuming GET /polls requires token based on your backend snippet
            const token = localStorage.getItem('token');
            const response = await axios.get(`${baseUrl}/polls`, {
                 headers: { 'Authorization': `Bearer ${token}` }
            });
            setPolls(response.data || []); // Ensure polls is always an array
        } catch (err) {
            console.error('Error fetching polls:', err);
            setError('Error fetching polls: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoading(false);
        }
    };

    // --- Form Input Handlers ---

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleOptionChange = (index, value) => {
        // Create a new array (immutable update)
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const handleAddOption = () => {
        if (options.length < MAX_OPTIONS) {
            setOptions([...options, '']); // Add a new empty option
        } else {
            setError(`Maximum of ${MAX_OPTIONS} options allowed.`);
        }
    };

    const handleRemoveOption = (index) => {
        if (options.length > MIN_OPTIONS) {
            const newOptions = options.filter((_, i) => i !== index); // Remove option at index
            setOptions(newOptions);
        } else {
            setError(`Minimum of ${MIN_OPTIONS} options required.`);
        }
    };

    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    };

    // --- Form Submission ---

    const handleAddPoll = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setMessage('');
        setError('');

        // --- Validation ---
        if (!question.trim()) {
            setError('Please enter a poll question.');
            return;
        }
        // Filter out empty options *before* checking length/content
        const validOptions = options.map(opt => opt.trim()).filter(opt => opt !== '');
        if (validOptions.length < MIN_OPTIONS) {
            setError(`Please provide at least ${MIN_OPTIONS} non-empty options.`);
            return;
        }
        // Check original options array for *any* empty string if you want to enforce filling all displayed fields
        if (options.some(opt => opt.trim() === '')) {
             setError('Please fill in all option fields or remove unused ones.');
             return;
        }
        // --- End Validation ---


        setSubmitting(true);
        try {
            const token = localStorage.getItem('token');
            // Prepare data in the format expected by the backend (array of objects)
            const pollData = {
                question: question.trim(),
                options: validOptions.map(optText => ({ text: optText })), // Send valid options as {text: ...}
                website1: website1,
                website2: website2
            };

            const response = await axios.post(`${baseUrl}/polls`, pollData, {
                 headers: { 'Authorization': `Bearer ${token}` }
            });

            setMessage(response.data.message || 'Poll added successfully!'); // Use backend message or default
            // Reset form
            setQuestion('');
            setOptions(['', '']); // Reset to two empty options
            setWebsite1(false);
            setWebsite2(false);
            fetchPolls(); // Refresh the list of polls

        } catch (err) {
            console.error('Error adding poll:', err);
            setError('Error adding poll: ' + (err.response?.data?.message || err.message));
        } finally {
            setSubmitting(false);
        }
    };

    // --- Deletion ---
    const handleDeletePoll = async (id) => {
         if (window.confirm('Are you sure you want to delete this poll?')) {
             setMessage('');
             setError('');
            try {
                const token = localStorage.getItem('token');
                const response = await axios.delete(`${baseUrl}/polls/${id}`, {
                     headers: { 'Authorization': `Bearer ${token}` }
                });
                setMessage(response.data.message || 'Poll deleted successfully!');
                fetchPolls(); // Refresh list
            } catch (err) {
                console.error('Error deleting poll:', err);
                setError('Error deleting poll: ' + (err.response?.data?.message || err.message));
            }
         }
    };

    return (
        <>
            <Dashboard />

            <div className="containerrs add-poll-container" style={{marginTop:"50px"}}>
                <h2>Add New Poll</h2>

                {/* Display Messages */}
                {message && <div className="message success-message">{message}</div>}
                {error && <div className="message error-message">{error}</div>}

                {/* --- Add Poll Form --- */}
                <form className="poll-form" onSubmit={handleAddPoll}>
                    <div className="form-group"> {/* Wrap elements in form-group */}
                        <label htmlFor="question">Poll Question:</label>
                        <textarea
                            id="question"
                            value={question}
                            onChange={handleQuestionChange}
                            rows="3" // Slightly smaller
                            required // Add HTML5 required
                            placeholder="Enter the poll question here..."
                        />
                    </div>

                    <fieldset className="form-group options-fieldset">
                        <legend>Options (Minimum {MIN_OPTIONS}, Maximum {MAX_OPTIONS})</legend>
                        {options.map((optionText, index) => (
                            <div className="option-input-group" key={index}>
                                <input
                                    type="text"
                                    placeholder={`Option ${index + 1}`}
                                    value={optionText}
                                    onChange={(e) => handleOptionChange(index, e.target.value)}
                                    required // Add HTML5 required
                                />
                                {options.length > MIN_OPTIONS && ( // Show remove btn only if more than min options
                                    <button
                                        type="button" // Important: type="button" to prevent form submission
                                        className="remove-option-btn"
                                        onClick={() => handleRemoveOption(index)}
                                        title="Remove Option"
                                    >
                                        × {/* Simple 'x' icon */}
                                    </button>
                                )}
                            </div>
                        ))}
                        {options.length < MAX_OPTIONS && ( // Show add btn only if less than max options
                             <button
                                type="button"
                                className="add-option-btn"
                                onClick={handleAddOption}
                            >
                                Add Option
                            </button>
                        )}
                    </fieldset>

                     <fieldset className="form-group checkbox-group">
                            <legend>Website Display</legend>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    name="website1"
                                    checked={website1}
                                    onChange={handleCheckboxChange(setWebsite1)}
                                />
                                Website 1
                            </label>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    name="website2"
                                    checked={website2}
                                    onChange={handleCheckboxChange(setWebsite2)}
                                />
                                Website 2
                            </label>
                     </fieldset>

                    <button type="submit" className="submit-poll-btn" disabled={submitting}>
                        {submitting ? 'Adding Poll...' : 'Add Poll'}
                    </button>
                </form>

                 

            </div>
            <AdminFooter/>
        </>
    );
};

export default AddPolls;