// src/components/CategoryNewsPage.js (Example path)
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Import useParams and Link
import axios from 'axios';
// import './CategoryNewsPage.css'; // Create CSS for styling this page

const CategoryNewsPage = () => {
    const { categoryName: encodedCategoryName } = useParams(); // Get encoded name from URL parameter
    const categoryName = decodeURIComponent(encodedCategoryName || ''); // Decode it
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch news when the categoryName parameter changes
        const fetchCategoryNews = async () => {
            if (!categoryName) {
                setError("Category name not found in URL.");
                setLoading(false);
                return;
            }

            setLoading(true);
            setError('');
            setNews([]); // Clear previous news

            try {
                // Use the encoded name directly in the URL for the API call
                const response = await axios.get(`${baseUrl}/news/category/${encodedCategoryName}`);
                setNews(response.data);
            } catch (err) {
                console.error(`Error fetching news for category "${categoryName}":`, err);
                setError(`Failed to load news for category "${categoryName}". ${err.response?.data?.message || err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoryNews();

    }, [encodedCategoryName, baseUrl, categoryName]); // Re-run effect if encoded name or base URL changes

    // Helper function to create slugs (optional, but good for links)
    const slugify = (text = '') => text.toString().toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');

    return (
        <div className="category-news-page container"> {/* Use a container class */}
            <h1 className="category-title">News in: {categoryName}</h1> {/* Display decoded name */}

            {loading && <p className="loading-indicator">Loading news...</p>}
            {error && <p className="error-message">{error}</p>}

            {!loading && !error && news.length === 0 && (
                <p>No news articles found in this category.</p>
            )}

            {!loading && !error && news.length > 0 && (
                <div className="news-list">
                    {news.map((article) => (
                        <div key={article._id} className="news-card">
                            {article.image && (
                                <img
                                    src={`${baseUrl}/${article.image}`} // Adjust if your paths are different
                                    alt={article.title}
                                    className="news-card-image"
                                    onError={(e) => { e.target.style.display = 'none'; /* Hide broken image */ }}
                                />
                            )}
                            <div className="news-card-content">
                                <h2 className="news-card-title">{article.title}</h2>
                                <p className="news-card-description">
                                    {/* Display a snippet of the description */}
                                    {article.description ? article.description.substring(0, 150) + '...' : ''}
                                </p>
                                <p className="news-card-date">
                                    {article.date ? new Date(article.date).toLocaleDateString() : ''}
                                </p>
                                {/* Link to the full news article page */}
                                <Link to={`/news/${article._id}`} className="read-more-link">
                                    Read More
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CategoryNewsPage;