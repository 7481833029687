import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Gallery.css'; // Import CSS
import NavbarAdvanced from '../Navbar/Navbar';
import FooterAdvanced from '../Footer/Footer';

const Gallery = ({ images }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const baseUrl = process.env.REACT_APP_API_URL; // Fallback URL


    const openImage = (index) => {
        setSelectedImage(index);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    return (
        <>
        <NavbarAdvanced/>
        <div className="gallery-drugs-gallery-container">
            <h2 className="gallery-drugs-gallery-title">Photo Gallery</h2>
            <div className="gallery-drugs-grid-container">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="gallery-drugs-grid-item"
                        onClick={() => openImage(index)}
                    >
                        <img src={`${baseUrl}${image.path}`} alt={image.name} className="gallery-drugs-grid-image" />
                    </div>
                ))}
            </div>

            {selectedImage !== null && (
                <div className="gallery-drugs-modal" onClick={closeImage}>
                    <div className="gallery-drugs-modal-content">
                        <img src={`${baseUrl}${images[selectedImage].path}`} alt={images[selectedImage].name} />
                        <span className="gallery-drugs-close-button" onClick={closeImage}>
                            ×
                        </span>
                    </div>
                </div>
            )}
        </div>
        <FooterAdvanced/>
        </>
    );
};

const App = () => {
    const [imageList, setImageList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseUrl = process.env.REACT_APP_API_URL; // Fallback URL


    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/gallery-images`);
                setImageList(response.data);
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        };
        fetchImages();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;
    return (
        <div className="App">
            <Gallery images={imageList} />
        </div>
       
    );
};
export default App;