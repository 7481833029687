import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';
// Assuming common styles are in Addnews.css or create ListNewspaper.css
import '../Addnews/Addnews.css'; // Keep if it has relevant styles

const ListNewspaper = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [newspapers, setNewspapers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // --- NEW: State for Toggling ---
    const [togglingNewspaperId, setTogglingNewspaperId] = useState(null);

    useEffect(() => {
        fetchNewspapers();
    }, []);

    const fetchNewspapers = async () => {
        setLoading(true);
        setMessage(''); // Clear message on fetch
        try {
            const token = localStorage.getItem('token'); // Get token if needed for GET
            const response = await axios.get(`${baseUrl}/newspapers`, {
                 headers: { 'Authorization': `Bearer ${token}` } // Add auth header if needed
            });
            // Normalize boolean fields
            const fetchedNewspapers = response.data.map(np => ({
                ...np,
                website1: np.website1 === true || np.website1 === 'true' || np.website1 === 1,
                website2: np.website2 === true || np.website2 === 'true' || np.website2 === 1,
            }));
            setNewspapers(fetchedNewspapers);
        } catch (error) {
            console.error('Error fetching newspapers:', error);
            setMessage('Error fetching newspapers: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteNewspaper = async (id) => {
        // Prevent delete while toggling status
        if (togglingNewspaperId === id) return;

        if (window.confirm('Are you sure you want to delete this newspaper?')) {
            setMessage('');
            try {
                const token = localStorage.getItem('token');
                await axios.delete(`${baseUrl}/newspapers/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setMessage('Newspaper deleted successfully');
                // Optimistic UI update
                setNewspapers(prev => prev.filter(np => np._id !== id));
                // Adjust pagination if needed
                const newTotalItems = newspapers.length - 1;
                const newTotalPages = Math.ceil(newTotalItems / itemsPerPage);
                if (currentPage > newTotalPages && newTotalPages > 0) {
                    setCurrentPage(newTotalPages);
                } else if (newTotalItems === 0) {
                    setCurrentPage(1);
                }
            } catch (error) {
                 console.error('Error deleting newspaper:', error);
                 setMessage('Error deleting newspaper: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    // --- NEW: Handle Status Toggle ---
    const handleToggleStatus = async (id, fieldKey, currentValue, currentNewspaperData) => {
        if (togglingNewspaperId === id) return; // Prevent rapid clicks

        setTogglingNewspaperId(id);
        setMessage(''); // Clear previous messages

        const newValue = !currentValue;

        try {
            // Prepare payload - Assuming JSON PUT/PATCH
            // Send only fields relevant for update, **DO NOT send pdfPath**
            const payload = {
                heading: currentNewspaperData.heading, // Include if backend needs it
                date: currentNewspaperData.date,       // Include if backend needs it
                website1: fieldKey === 'website1' ? newValue : currentNewspaperData.website1,
                website2: fieldKey === 'website2' ? newValue : currentNewspaperData.website2,
                // [fieldKey]: newValue // Use if backend handles partial updates
            };

            const token = localStorage.getItem('token');
            // *** Verify the UPDATE endpoint and METHOD (PUT/PATCH) ***
            await axios.put(`${baseUrl}/newspapers/${id}`, payload, { // OR PATCH
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json' // Sending JSON
                }
            });

            // Optimistic UI Update
            setNewspapers(prevNps =>
                prevNps.map(np =>
                    np._id === id
                        ? { ...np, [fieldKey]: newValue } // Update the specific field
                        : np
                )
            );
            // Optional: setMessage(`${fieldKey} status updated.`);

        } catch (error) {
            console.error(`Error toggling ${fieldKey}:`, error);
            setMessage(`Error updating ${fieldKey}: ` + (error.response?.data?.message || error.message));
            // Don't revert UI optimistically, just show error
        } finally {
            setTogglingNewspaperId(null); // Reset loading state for this item
        }
    };


    // --- Utility Functions ---
    const formatDateTime = (dateString) => {
         if (!dateString) return 'N/A';
        try {
            const date = new Date(dateString);
             if (isNaN(date.getTime())) return 'Invalid Date';
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Numeric month
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${day}/${month}/${year} ${hours}:${minutes}`;
        } catch (e) {
             console.error("Error formatting date:", dateString, e);
            return 'Invalid Date';
        }
    };

    // --- Pagination Logic ---
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = newspapers.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(newspapers.length / itemsPerPage);

    return (
        <>
            <Dashboard />
            <div className="containerrs list-news-container list-newspaper-container" style={{ marginTop: "50px" }}>
                <h2>List Newspapers</h2>

                {/* Display Message */}
                {message && (
                    <p className={`message ${message.includes('Error') ? 'error' : 'success'}`}>
                        {message}
                    </p>
                )}

                {loading ? (
                    <p>Loading newspapers...</p>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="image-table news-list-table newspaper-list-table"> {/* Added specific class */}
                                <thead>
                                    <tr>
                                        <th>Heading</th>
                                        <th>Upload Date</th>
                                        <th>PDF</th>
                                        {/* Centered Status Headers */}
                                        <th style={{ textAlign: 'center' }}>Website 1</th>
                                        <th style={{ textAlign: 'center' }}>Website 2</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.length > 0 ? (
                                        currentItems.map((newspaper) => (
                                            <tr key={newspaper._id}>
                                                <td>{newspaper.heading}</td>
                                                <td>{formatDateTime(newspaper.date)}</td>
                                                <td>
                                                    {newspaper.pdfPath ? (
                                                        <a href={`${baseUrl}/${newspaper.pdfPath}`} target="_blank" rel="noopener noreferrer" className="action-link view-pdf">
                                                            View PDF
                                                        </a>
                                                    ) : (
                                                        'No PDF'
                                                    )}
                                                </td>
                                                {/* Status Toggles */}
                                                <td style={{ textAlign: 'center' }}>
                                                    <span
                                                        className={`status-toggle ${togglingNewspaperId === newspaper._id ? 'loading' : ''}`}
                                                        onClick={() => handleToggleStatus(newspaper._id, 'website1', newspaper.website1, newspaper)}
                                                        title={`Click to toggle Website 1 (${newspaper.website1 ? 'Active' : 'Inactive'})`}
                                                    >
                                                        {togglingNewspaperId === newspaper._id ? '...' : (newspaper.website1 ? '✅' : '❌')}
                                                    </span>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <span
                                                        className={`status-toggle ${togglingNewspaperId === newspaper._id ? 'loading' : ''}`}
                                                        onClick={() => handleToggleStatus(newspaper._id, 'website2', newspaper.website2, newspaper)}
                                                        title={`Click to toggle Website 2 (${newspaper.website2 ? 'Active' : 'Inactive'})`}
                                                    >
                                                        {togglingNewspaperId === newspaper._id ? '...' : (newspaper.website2 ? '✅' : '❌')}
                                                    </span>
                                                </td>
                                                <td className="action-cell">
                                                    <button
                                                        className="action-button delete"
                                                        onClick={() => handleDeleteNewspaper(newspaper._id)}
                                                        disabled={togglingNewspaperId === newspaper._id} // Disable while toggling
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" style={{ textAlign: 'center' }}>No newspapers found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        {totalPages > 1 && (
                            <div className="pagination">
                                <button
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1 || loading}
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages || loading}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </>
                )}
                {/* Removed redundant message display here */}
            </div>
            <AdminFooter />
        </>
    );
};

export default ListNewspaper;