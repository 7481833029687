import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Addgallery.css'; // Keep your CSS
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';

const AddGalleryImages = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Ensure a fallback

    // State for the upload form
    const [galleryFile, setGalleryFile] = useState(null);
    const [heading, setHeading] = useState('');
    const [paragraph, setParagraph] = useState('');
    const [folderName, setFolderName] = useState(''); // <-- ADDED: State for folder name
    const [uploadMessage, setUploadMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false); // <-- ADDED: Loading state for upload

    // State for the list
    const [allImages, setAllImages] = useState([]); // <-- RENAMED: Stores the flat list
    const [loading, setLoading] = useState(false);
    const [listMessage, setListMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // State for toggling status
    const [togglingImageId, setTogglingImageId] = useState(null);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        setLoading(true);
        setListMessage('');
        try {
            const token = localStorage.getItem('token');
            // Fetch the grouped data from the backend
            const response = await axios.get(`${baseUrl}/gallery-images`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            // *** FLATTEN the grouped data ***
            const groupedData = response.data; // e.g., { folderA: [...], folderB: [...] }
            let flatImageArray = [];
            if (groupedData && typeof groupedData === 'object') {
                // Iterate over the keys (folder names) of the object
                Object.keys(groupedData).forEach(folder => {
                    // Check if the value is an array before pushing
                    if (Array.isArray(groupedData[folder])) {
                        // Add folder info back if needed for display, otherwise just push images
                        // Example: Add folder property to each image object
                        // const imagesInFolder = groupedData[folder].map(img => ({ ...img, folder: folder }));
                        // flatImageArray = flatImageArray.concat(imagesInFolder);

                        // Or just concatenate the image arrays directly if folder isn't needed in the flat list display
                         flatImageArray = flatImageArray.concat(groupedData[folder]);
                    }
                });

                 // Optional: Sort the flattened array by date again if needed
                 flatImageArray.sort((a, b) => new Date(b.datePosted) - new Date(a.datePosted));

            } else {
                 console.warn("Received unexpected data format from /gallery-images. Expected an object.", response.data);
                 setListMessage('Error: Unexpected data format received from server.');
            }

            setAllImages(flatImageArray); // Set the flattened array to state

        } catch (error) {
            console.error('Error fetching gallery images:', error);
            setListMessage('Error fetching gallery images: ' + (error.response?.data?.message || error.message));
            setAllImages([]); // Clear images on error
        } finally {
            setLoading(false);
        }
    };

    // --- Upload Form Handlers ---
    const handleGalleryFileChange = (e) => {
        setGalleryFile(e.target.files[0]);
    };

    const handleUploadGallery = async (e) => {
        e.preventDefault();
        setUploadMessage('');
        setIsUploading(true); // Start loading

        if (!galleryFile) {
            setUploadMessage('Please select an image file.');
            setIsUploading(false);
            return;
        }
        if (!folderName.trim()) { // <-- VALIDATE: Folder name is required
            setUploadMessage('Folder name is required.');
            setIsUploading(false);
            return;
        }
        if (!heading.trim()) {
            setUploadMessage('Heading is required.');
            setIsUploading(false);
            return;
        }

        const formData = new FormData();
        formData.append('image', galleryFile);
        formData.append('heading', heading);
        formData.append('paragraph', paragraph);
        formData.append('folder', folderName.trim()); // <-- APPEND: Folder name

        // Add default website statuses (match backend schema defaults if necessary)
        // These might not be needed if backend defaults them, but explicit is okay too.
        // formData.append('website1', 'false'); // Send as strings if needed
        // formData.append('website2', 'false');

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${baseUrl}/upload-gallery`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });
            setUploadMessage(response.data.message || 'Image uploaded successfully!');
            // Clear form
            setGalleryFile(null);
            setHeading('');
            setParagraph('');
            setFolderName(''); // <-- RESET: Folder name input
            if (document.getElementById('gallery-file-input')) {
                document.getElementById('gallery-file-input').value = null;
            }
            fetchImages(); // Refresh the list
        } catch (error) {
            console.error('Error uploading gallery image:', error);
            setUploadMessage('Error uploading gallery image: ' + (error.response?.data?.message || error.message));
        } finally {
            setIsUploading(false); // Stop loading
        }
    };

    // --- List Action Handlers ---
    const handleDeleteImage = async (id) => {
        if (togglingImageId === id) return;

        if (window.confirm('Are you sure you want to delete this image? This action cannot be undone.')) {
            setListMessage('');
            try {
                const token = localStorage.getItem('token');
                // *** Ensure this DELETE endpoint exists on your backend ***
                const response = await axios.delete(`${baseUrl}/images/${id}`, { // Assuming /images/:id
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setListMessage(response.data.message || 'Image deleted successfully.');
                // Update UI immediately by filtering the flat array
                setAllImages(prev => prev.filter(img => img._id !== id));

                // Adjust pagination if the deleted item was on the last page and made it empty
                const newTotalItems = allImages.length - 1;
                const newTotalPages = Math.ceil(newTotalItems / itemsPerPage);
                if (currentPage > newTotalPages && newTotalPages > 0) {
                    setCurrentPage(newTotalPages);
                } else if (newTotalItems === 0) {
                    setCurrentPage(1); // Reset to page 1 if list becomes empty
                } else {
                    // If the current page is now empty but not the last overall page, go back one
                    const itemsOnCurrentPageAfterDelete = allImages.slice(indexOfFirstItem, indexOfLastItem).length -1;
                    if (itemsOnCurrentPageAfterDelete === 0 && currentPage > 1) {
                        setCurrentPage(currentPage - 1);
                    }
                }

            } catch (error) {
                console.error('Error deleting image:', error);
                setListMessage('Error deleting image: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    const handleToggleStatus = async (id, fieldKey, currentValue, currentImageData) => {
        if (togglingImageId === id) return;

        setTogglingImageId(id);
        setListMessage('');

        const newValue = !currentValue;

        try {
            // Construct payload based on what your backend PUT/PATCH endpoint expects
            // Sending only the changed field might require PATCH, PUT often replaces
            const payload = {
                // Include other fields if your PUT endpoint replaces the document
                heading: currentImageData.heading,
                paragraph: currentImageData.paragraph,
                folder: currentImageData.folder, // Include folder if needed by backend update logic
                website1: fieldKey === 'website1' ? newValue : currentImageData.website1,
                website2: fieldKey === 'website2' ? newValue : currentImageData.website2,
                // OR simpler if PATCH is supported: { [fieldKey]: newValue }
            };

            const token = localStorage.getItem('token');
            // *** Ensure this PUT or PATCH endpoint exists on your backend ***
            await axios.put(`${baseUrl}/images/${id}`, payload, { // OR use axios.patch
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            // Optimistic UI Update on the flat array
            setAllImages(prevImages =>
                prevImages.map(img =>
                    img._id === id
                        ? { ...img, [fieldKey]: newValue }
                        : img
                )
            );
             // Optional: setListMessage(`${fieldKey} status updated successfully.`);

        } catch (error) {
            console.error(`Error toggling ${fieldKey}:`, error);
            setListMessage(`Error updating status: ${error.response?.data?.message || error.message}`);
            // Note: No UI rollback implemented here on error for simplicity
        } finally {
            setTogglingImageId(null);
        }
    };


    // --- Utility Functions ---
    const formatDateTime = (dateString) => {
        if (!dateString) return 'N/A';
        try {
            const date = new Date(dateString);
             if (isNaN(date.getTime())) return 'Invalid Date';
            return date.toLocaleString('en-IN', { // Example: Indian English locale format
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true // Use AM/PM
             });
        } catch (e) {
             console.error("Error formatting date:", dateString, e);
            return 'Invalid Date';
        }
    };

    // --- Pagination Logic ---
    // Use the flattened allImages array for pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = allImages.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(allImages.length / itemsPerPage);

    // Function to safely construct image URL
    const getImageUrl = (path) => {
        if (!path) return ''; // Handle missing path
        // If path already includes http, use it directly
        if (path.startsWith('http://') || path.startsWith('https://')) {
            return path;
        }
        // Ensure baseUrl is defined and doesn't end with a slash if path starts with one
        const adjustedBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
        const adjustedPath = path.startsWith('/') ? path : `/${path}`;
        return `${adjustedBaseUrl}${adjustedPath}`;
    };


    return (
        <>
            <Dashboard />

             


            {/* List Section */}
            <div className="containerrs list-news-container list-gallery-container">
                <h3>Uploaded Gallery Images (All Folders)</h3>

                 {/* Display List Action Messages */}
                 {listMessage && (
                     <p className={`message ${listMessage.includes('Error') ? 'error' : 'success'}`}>
                         {listMessage}
                     </p>
                 )}

                {loading ? (
                    <p>Loading images...</p>
                ) : (
                    <>
                       <div className="table-responsive">
                            <table className="polls-table news-list-table gallery-list-table">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Folder</th>{/* <-- ADDED: Display Folder */}
                                        <th>Heading</th>
                                        <th>Paragraph</th>
                                        <th style={{ textAlign: 'center' }}>Website 1</th>
                                        <th style={{ textAlign: 'center' }}>Website 2</th>
                                        <th>Uploaded</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.length > 0 ? (
                                        currentItems.map((image) => (
                                            <tr key={image._id}>
                                                <td>
                                                    {image.path ? (
                                                         <img
                                                            src={getImageUrl(image.path)} // Use helper function for URL
                                                            
                                                            alt={image.heading || 'Gallery'}
                                                            style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '8px' }} // Adjusted style
                                                            loading="lazy" // Add lazy loading
                                                            onError={(e) => {
                                                                e.target.onerror = null; // Prevent infinite loop if placeholder fails
                                                                e.target.src = '/path/to/your/placeholder.png'; // Provide a real placeholder path
                                                                e.target.alt = 'Image not found';
                                                                e.target.style.opacity = '0.5'; // Indicate broken image
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>No Image</span>
                                                    )}
                                                </td>
                                                <td>{image.folder || 'default'}</td> {/* Display Folder */}
                                                <td>{image.heading || '-'}</td>
                                                <td title={image.paragraph || ''}> {/* Show full paragraph on hover */}
                                                    {image.paragraph ? image.paragraph.substring(0, 40) + (image.paragraph.length > 40 ? '...' : '') : '-'}
                                                </td>
                                                {/* Status Toggles */}
                                                <td style={{ textAlign: 'center' }}>
                                                    <span
                                                        className={`status-toggle ${togglingImageId === image._id ? 'loading' : ''} ${image.website1 ? 'active' : 'inactive'}`}
                                                        onClick={() => !togglingImageId && handleToggleStatus(image._id, 'website1', image.website1, image)} // Prevent click while loading
                                                        title={`Click to toggle Website 1 (${image.website1 ? 'Active' : 'Inactive'})`}
                                                        style={{ cursor: togglingImageId ? 'default' : 'pointer' }} // Change cursor when loading
                                                    >
                                                         {/* Simple visual indicator */}
                                                        {togglingImageId === image._id ? '...' : (image.website1 ? '✔️' : '❌')}
                                                    </span>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <span
                                                         className={`status-toggle ${togglingImageId === image._id ? 'loading' : ''} ${image.website2 ? 'active' : 'inactive'}`}
                                                         onClick={() => !togglingImageId && handleToggleStatus(image._id, 'website2', image.website2, image)} // Prevent click while loading
                                                         title={`Click to toggle Website 2 (${image.website2 ? 'Active' : 'Inactive'})`}
                                                         style={{ cursor: togglingImageId ? 'default' : 'pointer' }} // Change cursor when loading
                                                    >
                                                         {/* Simple visual indicator */}
                                                         {togglingImageId === image._id ? '...' : (image.website2 ? '✔️' : '❌')}
                                                    </span>
                                                </td>
                                                <td>{formatDateTime(image.datePosted)}</td>
                                                <td>
                                                    <button
                                                        className="action-button delete"
                                                        onClick={() => handleDeleteImage(image._id)}
                                                        disabled={!!togglingImageId} // Disable delete while ANY toggle is happening
                                                        title="Delete Image"
                                                    >
                                                         Delete {/* Use an icon or text */}
                                                    </button>
                                                     {/* Add Edit Button Placeholder if needed */}
                                                     {/* <button className="action-button edit" title="Edit Image (Not Implemented)">✏️ Edit</button> */}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8" style={{ textAlign: 'center', padding: '20px' }}>No images found. Upload one using the form above.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                         {totalPages > 1 && (
                            <div className="pagination">
                                <button
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1 || loading || !!togglingImageId}
                                >
                                    « Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages || loading || !!togglingImageId}
                                >
                                    Next »
                                </button>
                            </div>
                         )}
                    </>
                )}
            </div>
            <AdminFooter />
        </>
    );
};

export default AddGalleryImages;