// src/components/NewsDetail/NewsDetail.js
import React, { useState, useEffect, useRef } from 'react'; // Added useRef
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Captcha from "./Captcha.js"

// Use FaPause when speaking
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaLink, FaVolumeUp, FaPause } from 'react-icons/fa';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './About.css'; // Make sure this contains the .highlighted-word style

// --- Configuration ---
const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.29news.live:5004';
const POPULAR_NEWS_LIMIT = 5;

// --- Helper Functions ---
const formatDate = (dateString, includeTime = false) => {
    // ... (keep existing formatDate function)
    if (!dateString) return 'Date not available';
    const defaultOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };
    const options = includeTime ? { ...defaultOptions, ...timeOptions } : defaultOptions;
    try {
        return new Date(dateString).toLocaleDateString(undefined, options);
    } catch (e) {
        console.error("Error formatting date:", e);
        return dateString;
    }
};

const getImageUrl = (imagePath) => {
    // ... (keep existing getImageUrl function)
    if (!imagePath) return '/img/placeholder/news_placeholder_small.jpg';
    const cleanedPath = imagePath.startsWith('/') ? imagePath.substring(1) : imagePath;
    return `${API_BASE_URL}/${cleanedPath}`;
};

// Basic check for Hindi characters
const containsHindi = (text) => {
    if (!text) return false;
    const hindiRegex = /[\u0900-\u097F]/;
    return hindiRegex.test(text);
};

export default function NewsDetail() {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [popularNews, setPopularNews] = useState([]);
    const [loadingPopular, setLoadingPopular] = useState(true);

    // --- State for Speech Synthesis ---
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [availableVoices, setAvailableVoices] = useState([]);
    const [currentWordIndex, setCurrentWordIndex] = useState(null); // For highlighting
//comments 
    // --- State for Comment Form ---
    const [commentName, setCommentName] = useState('');
    const [commentEmail, setCommentEmail] = useState('');
    const [commentText, setCommentText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('idle'); // 'idle', 'success', 'error'
    const [submitMessage, setSubmitMessage] = useState('');
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const captchaRef = useRef();

    
    // --- Refs for Speech Queue ---
    const utteranceQueueRef = useRef([]); // Holds the queue of SpeechSynthesisUtterance objects
    const currentUtteranceIndexRef = useRef(0); // Tracks the index in the queue
    const synthRef = useRef(window.speechSynthesis); // Ref to the synthesis object

    // --- Effect to load available voices ---
    useEffect(() => {
        const synth = synthRef.current;
        if (!synth) {
            console.warn("Web Speech API not supported.");
            return;
        }

        const loadVoices = () => {
            const voices = synth.getVoices();
            setAvailableVoices(voices);
            console.log("Available Voices:", voices);
        };

        loadVoices();
        if (synth.onvoiceschanged !== undefined) {
            synth.onvoiceschanged = loadVoices;
        }
    }, []);

    // --- Fetch Main Article ---
    useEffect(() => {
        const fetchArticle = async () => {
            setLoading(true);
            setError(null);
            setArticle(null);
            // Stop speech if navigating to a new article
            stopReading(); // Call stopReading which handles cleanup

            if (!id) {
                setError("No article ID provided.");
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`${API_BASE_URL}/news/${id}`);
                setArticle(response.data ? response.data : null);
                if (!response.data) setError("Article not found.");
            } catch (err) {
                console.error("Error fetching article:", err);
                setError(err.response?.status === 404 ? "Article not found (404)." : "Failed to load article.");
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();

        // Cleanup on unmount or ID change
        return () => {
            stopReading(); // Ensure speech stops when component leaves
        };
    }, [id]);

    // --- Fetch Popular News ---
    useEffect(() => {
        // ... (keep existing fetchPopularNews logic) ...
        const fetchPopularNews = async () => {
            setLoadingPopular(true);
            try {
                 // Ensure the API endpoint is correct
                 const response = await axios.get(`${API_BASE_URL}/news?sort=views&limit=${POPULAR_NEWS_LIMIT}`);
                 let dataToSet = [];
                 if (response.data && Array.isArray(response.data)) {
                     dataToSet = response.data;
                 } else if (response.data?.data && Array.isArray(response.data.data)) { // Handle nested data structure if applicable
                     dataToSet = response.data.data;
                 } else {
                     console.warn("Popular news API response format unexpected:", response.data);
                 }
                  // Filter out the current article being viewed
                 setPopularNews(dataToSet.filter(item => item._id !== id));
            } catch (err) {
                console.error("Error fetching popular news:", err);
                setPopularNews([]); // Set to empty on error
            } finally {
                setLoadingPopular(false);
            }
        };

        if (id) { // Only fetch if we have a main article ID
            fetchPopularNews();
        } else {
             setLoadingPopular(false); // Don't show loading if no ID
             setPopularNews([]);
        }
    }, [id]); // Rerun if the main article ID changes

    // --- Word-by-Word Speech Logic ---

    const speakNextWord = () => {
        const synth = synthRef.current;
        if (!synth || currentUtteranceIndexRef.current >= utteranceQueueRef.current.length) {
            // End of queue or synth not available
            stopReading(false); // Call stopReading without cancelling synth if already ended
            return;
        }

        const utter = utteranceQueueRef.current[currentUtteranceIndexRef.current];

        // Set onend for the *current* utterance to trigger the next one
        utter.onend = () => {
            // Small delay before starting next word (optional, can feel more natural)
             setTimeout(() => {
                 currentUtteranceIndexRef.current++;
                 speakNextWord();
             }, 50); // Adjust delay as needed (e.g., 50-100ms)
        };

        // Set onerror for the *current* utterance
        utter.onerror = (event) => {
            console.error("Speech synthesis error on word:", event.utterance.text, event);
             let errorMsg = `Speech error: ${event.error}`;
             // Handle specific common errors
             if (event.error === 'language-unavailable' || event.error === 'voice-unavailable') {
                 errorMsg = `The required language (${utter.lang}) or voice is unavailable.`;
             } else if (event.error === 'synthesis-failed') {
                 errorMsg = "Speech synthesis failed. Please try again.";
             } else if (event.error === 'interrupted' || event.error === 'canceled') {
                 // Don't show alert if intentionally stopped
                 console.log("Speech interrupted or cancelled.");
                 stopReading(false); // Update state without showing error
                 return;
             }
             alert(errorMsg);
             stopReading(false); // Stop reading and update state
        };

        // Set onstart to update highlighting
        // (Moved here from utterance creation for clarity)
        utter.onstart = () => {
           setCurrentWordIndex(currentUtteranceIndexRef.current); // Use the index ref
        };


        console.log("Speaking word:", utter.text, "Index:", currentUtteranceIndexRef.current);
        synth.speak(utter);
    };

    const readNews = () => {
        const synth = synthRef.current;
        if (!article || !article.description || !synth) return;
    
        stopReading(); // Stop any ongoing speech
    
        const textToSpeak = article.description.trim();
        const sentences = textToSpeak.split(/(?<=[.?!])\s+/); // Split by sentence
    
        const targetLang = containsHindi(textToSpeak) ? 'hi-IN' : 'en-US';
        const selectedVoice = availableVoices.find(voice => voice.lang === targetLang);
    
        utteranceQueueRef.current = sentences.map((sentence) => {
            const utter = new SpeechSynthesisUtterance(sentence);
            utter.lang = targetLang;
            utter.rate = 1.1; // Slightly faster rate
            utter.voice = selectedVoice || null;
            return utter;
        });
    
        if (utteranceQueueRef.current.length > 0) {
            setIsSpeaking(true);
            speakNextSentence();
        }
    };
    
    const speakNextSentence = () => {
        const synth = synthRef.current;
        if (!synth || currentUtteranceIndexRef.current >= utteranceQueueRef.current.length) {
            stopReading(false);
            return;
        }
    
        const utter = utteranceQueueRef.current[currentUtteranceIndexRef.current];
        utter.onend = () => {
            currentUtteranceIndexRef.current++;
            speakNextSentence();
        };
    
        synth.speak(utter);
    };
    
    const stopReading = (cancelSynth = true) => { // Add flag to avoid cancelling if already ended
        const synth = synthRef.current;
        console.log("Stopping reading. Cancel synth:", cancelSynth);
        if (synth && cancelSynth) {
             // Clear the queue and stop speaking immediately
             synth.cancel();
        }
        setIsSpeaking(false);
        setCurrentWordIndex(null);
        currentUtteranceIndexRef.current = 0; // Reset index
        utteranceQueueRef.current = []; // Clear the queue
    };


    // --- Comment Submission Logic ---
    const handleCommentSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (!commentName.trim() || !commentEmail.trim() || !commentText.trim()) {
            setSubmitStatus('error');
            setSubmitMessage('Please fill in all required fields (Name, Email, Comment).');
            return;
        }

        // Basic email format check (optional, backend should validate robustly)
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailRegex.test(commentEmail)) {
             setSubmitStatus('error');
             setSubmitMessage('Please enter a valid email address.');
             return;
        }


        setIsSubmitting(true);
        setSubmitStatus('idle');
        setSubmitMessage('');

        try {
            const response = await axios.post(`${API_BASE_URL}/news/${id}/comments`, {
                name: commentName,
                email: commentEmail,
                comment: commentText, // Ensure field names match backend
            });

            if (response.status === 201) {
                setSubmitStatus('success');
               
                // Clear the form
                setCommentName('');
                setCommentEmail('');
                setCommentText('');
                // Optionally: Refresh comments list if displayed
            } else {
                 // Handle unexpected success statuses if necessary
                 setSubmitStatus('error');
                 setSubmitMessage(response.data?.message || 'An unexpected response occurred.');
            }

        } catch (err) {
            console.error("Error submitting comment:", err);
            setSubmitStatus('error');
            // Try to get specific error message from backend response
            const backendMessage = err.response?.data?.message;
            const validationErrors = err.response?.data?.errors; // Check for specific validation errors

            if (validationErrors) {
                // Format validation errors nicely (simple example)
                const errorMessages = Object.values(validationErrors).map(e => e.message).join(' ');
                setSubmitMessage(`Validation Failed: ${errorMessages}`);
            } else {
                 setSubmitMessage(backendMessage || 'Failed to submit comment. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
        e.preventDefault();
        if (!isCaptchaValid) {
          alert('CAPTCHA validation failed!');
          return;
        }
    
        // handle form submission logic here
        alert('Form submitted successfully ✅');
    
        // Reset captcha after submission
        captchaRef.current.resetCaptcha();
    };
 
    // --- Render Logic ---
    if (loading) return <><Navbar /> <div className="news-detail-loading-description">Loading Article...</div> </>;
    if (error) return <><Navbar /> <div className="news-detail-error-description">Error: {error}</div> <Footer /> </>;
    if (!article) return <><Navbar /> <div className="news-detail-error-description">Article data could not be loaded.</div> <Footer /> </>;

    return (
        <>
            <Navbar />
            <div className="news-detail-page-description">
                <div className="container-description">
                    <div className="row-description">
                        {/* Main Content Column */}
                        <div className="news-detail-main-content-description">
                            <article className="news-detail-content-description">

                                <div className="news-detail-img-description">
                                    <img src={getImageUrl(article.image)} alt={article.title} />
                                </div>

                                <header className="news-detail-header-description">
                                     <div className="meta-info-description">
                                        {article.category && <span className="news-detail-category-description">{article.category}</span>}
                                        <span className="news-detail-date-description">{formatDate(article.date, true)}</span>
                                    </div>
                                    <h1>{article.title}</h1>
                                     {/* === UPDATED: SPEECH TOGGLE BUTTON === */}
                                    {article.description && synthRef.current && ( // Check synth support
                                        <button
                                            onClick={isSpeaking ? () => stopReading() : readNews} // Use stopReading with default cancel=true
                                            className={`speech-toggle-button ${isSpeaking ? 'speaking' : ''}`}
                                            aria-label={isSpeaking ? "Stop reading description" : "Read description aloud"}
                                            title={isSpeaking ? "Stop reading" : "Read aloud"}
                                        >
                                            {isSpeaking ? <FaPause size="1.2em"/> : <FaVolumeUp size="1.2em" />}
                                            <span style={{ marginLeft: '8px' }}>{isSpeaking ? "" : ""}</span>
                                        </button>
                                    )}
                                </header>

                                {/* === UPDATED: Description with Highlighting === */}
                                <div className="news-detail-body-description">
                                    {article.description
                                        ? article.description.trim().split(/(\s+)/).map((segment, index) => {
                                            // Split by spaces, keeping the spaces as separate segments
                                            // Filter out empty segments that might result from multiple spaces
                                            if (segment.trim().length === 0 && segment.length > 0) {
                                                // This is whitespace, render it directly
                                                return <span key={`space-${index}`}>{segment}</span>;
                                            } else if (segment.trim().length > 0) {
                                                // This is a word segment
                                                // We need to map this segment's index back to the word index in the queue
                                                // This is slightly complex because the queue filters out spaces
                                                // A simpler approach for now: highlight based on the index in *this* map
                                                // Note: This index won't perfectly match currentUtteranceIndexRef if description has multiple spaces
                                                // For perfect sync, we'd need to pre-process description and queue identically.

                                                // Let's find the *actual* word index corresponding to this segment
                                                const actualWordIndex = article.description.trim().split(/(\s+)/)
                                                    .filter(w => w.trim().length > 0) // Get only non-empty word segments
                                                    .findIndex((word, wIdx) => {
                                                         // Find the index of the word in the *original* split array
                                                         const originalIndex = article.description.trim().split(/(\s+)/).indexOf(segment, index);
                                                         return originalIndex === index;
                                                    });


                                                return (
                                                    <span
                                                        key={`word-${index}`}
                                                        className={actualWordIndex === currentWordIndex ? "highlighted-word" : ""}
                                                    >
                                                        {segment}
                                                    </span>
                                                );
                                            }
                                            return null; // Ignore empty segments from trim()
                                          })
                                        : <p>No description available.</p> // Fallback
                                    }
                                </div>


                                {/* --- Other sections remain the same --- */}
                                 {/* === SOCIAL SHARE === */}
                               <div className="social-share-description">
                                                                  <div className="share-prompt-description">
                                                                       <strong>Share:</strong>
                                                                       <ul>
                                                                           <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} aria-label="Share on Facebook" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a></li>
                                                                           <li><a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(article.title)}`} aria-label="Share on Twitter" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
                                                                           {/* Add other relevant share links */}
                                                                           <li><a href="#" aria-label="Copy link" onClick={(e) => { e.preventDefault(); navigator.clipboard.writeText(window.location.href); alert('Link Copied!'); }}><FaLink /></a></li>
                                                                       </ul>
                                                                   </div>
                                                               </div>

                                {/* === Comment Form Section === */}
                                <section className="comment-section-description">
                                   <h3 className="comment-form-title-description">Leave a Comment</h3>
                                    {/* --- FORM START --- */}
                                    <form onSubmit={handleCommentSubmit} noValidate> {/* Add noValidate to rely on custom handling */}
                                        <div className="form-group-description">
                                            <textarea
                                                className="form-control-description"
                                                placeholder="Enter Comment *"
                                                required
                                                value={commentText}
                                                onChange={(e) => setCommentText(e.target.value)}
                                                rows="5"
                                                aria-label="Comment Text"
                                            ></textarea>
                                        </div>
                                        <div className="form-row-description">
                                            <div className="form-group-description form-group-half-description">
                                                <input
                                                    className="form-control-description"
                                                    type="text"
                                                    placeholder="Your Name *"
                                                    required
                                                    value={commentName}
                                                    onChange={(e) => setCommentName(e.target.value)}
                                                    aria-label="Your Name"
                                                />
                                            </div>
                                            <div className="form-group-description form-group-half-description">
                                                <input
                                                    className="form-control-description"
                                                    type="email"
                                                    placeholder="Your Email *"
                                                    required
                                                    value={commentEmail}
                                                    onChange={(e) => setCommentEmail(e.target.value)}
                                                    aria-label="Your Email"
                                                />
                                            </div>
                                             {/* Captcha */}
                                             <Captcha ref={captchaRef} onValidate={setIsCaptchaValid} />
                                        </div>

                                        {/* --- Submission Status Feedback --- */}
                                        {submitMessage && (
                                            <div className={`form-feedback ${submitStatus === 'success' ? 'success' : 'error'}`}>
                                                {submitMessage}
                                            </div>
                                        )}

                                        <div className="form-group-description form-submit-description">
                                            <button
                                                type="submit"
                                                className="btn-description btn-primary-description"
                                                disabled={isSubmitting} // Disable button while submitting
                                            >
                                                {isSubmitting ? 'Posting...' : 'Post Comment'}
                                            </button>
                                        </div>
                                    </form>
                                     {/* --- FORM END --- */}
                                </section>

                            </article>
                        </div> {/* End Main Content Column */}

                         {/* Sidebar Column */}
                       <aside className="news-detail-sidebar-description">
                                                  {/* Popular News Widget */}
                                                  <div className="sidebar-widget-description popular-news-widget-description">
                                                      {/* ... (popular news widget code remains same) ... */}
                                                       <h4 className="widget-title-description">Popular News</h4>
                                                      <div className="widget-content-description">
                                                          {loadingPopular ? ( <div className="widget-loading-description">Loading...</div> )
                                                           : popularNews.length > 0 ? (
                                                              popularNews.map(news => (
                                                                  <Link to={`/news/${news._id}`} className="popular-news-item-description" key={news._id}>
                                                                      <div className="popular-news-item-image-description"> <img src={getImageUrl(news.image)} alt={news.title} /> </div>
                                                                      <div className="popular-news-item-content-description">
                                                                          <h5 className="popular-news-item-title-description">{news.title}</h5>
                                                                          <span className="popular-news-item-date-description">{formatDate(news.date)}</span>
                                                                      </div>
                                                                  </Link>
                                                              ))
                                                            ) : ( <div className="widget-placeholder-description">No popular news found.</div> )
                                                          }
                                                      </div>
                                                  </div>
                                                   {/* Advertisement Widget */}
                                                  <div className="sidebar-widget-description ad-widget-description news-poster-description">
                                                      {/* ... (ad widget code remains same) ... */}
                                                      <h4 className="widget-title-description">Advertisement</h4>
                                                       <div className="widget-content-description">
                                                          <a href="#" aria-label="Advertisement"><img src="/img/placeholder/ad_placeholder.jpg" alt="Advertisement Poster" /></a> {/* Use placeholder */}
                                                      </div>
                                                  </div>
                                              </aside> {/* End Sidebar Column */}

                    </div> {/* End Row */}
                </div> {/* End Container */}
            </div> {/* End Page Wrapper */}
            <Footer />
        </>
    );
}