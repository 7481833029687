import React, { useState, useEffect } from "react";
import {
    FaBars,
    FaInfoCircle,
    FaImage,
    FaNewspaper,
    FaVideo,
    FaUsers,  // Icon for User
    FaBullhorn, // Icon for Advertise
    FaPoll, // Icon for Poll
    FaNewspaper as FaNewspaperIcon, // Icon for Newspaper
    FaSignOutAlt, // Logout Icon
    FaKey, // Change Password Icon
    FaUserCheck, // Approval Icon
    FaUserPlus // Employee Register Icon
} from "react-icons/fa";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "./Dashboard.css";
// import Logo from "./logo.png"; // Assuming logo is not used
import axios from "axios";
import { removeToken, getToken } from '../Login/Authutils'; // Ensure path is correct

const Sidebar = ({ children }) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    if (!baseUrl) {
        console.error("REACT_APP_API_URL is not defined. Please check your .env file.");
    }

    const [isOpen, setIsOpen] = useState(false);
    // const [click, setClick] = useState(false); // click state seems unused for sidebar/navbar logic shown
    const navigate = useNavigate();
    const location = useLocation();

    // State to control link visibility based on role
    const [showEmployeeLinks, setShowEmployeeLinks] = useState(false);
    const [showAdminLinks, setShowAdminLinks] = useState(false);

    // State for dropdown visibility (Admin Only) - Individual states
    const [userDropdownOpen, setUserDropdownOpen] = useState(false);
    const [marqueeDropdownOpen, setMarqueeDropdownOpen] = useState(false);
    const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
    const [galleryDropdownOpen, setGalleryDropdownOpen] = useState(false);
    const [newsDropdownOpen, setNewsDropdownOpen] = useState(false);
    const [videoDropdownOpen, setVideoDropdownOpen] = useState(false);
    const [advertiseDropdownOpen, setAdvertiseDropdownOpen] = useState(false);
    const [pollDropdownOpen, setPollDropdownOpen] = useState(false);
    const [newspaperDropdownOpen, setNewspaperDropdownOpen] = useState(false);

    // --- Close dropdowns when sidebar closes or location changes ---
    useEffect(() => {
        if (!isOpen) {
            // Close all dropdowns when sidebar collapses
            setUserDropdownOpen(false);
            setMarqueeDropdownOpen(false);
            setCategoryDropdownOpen(false);
            setGalleryDropdownOpen(false);
            setNewsDropdownOpen(false);
            setVideoDropdownOpen(false);
            setAdvertiseDropdownOpen(false);
            setPollDropdownOpen(false);
            setNewspaperDropdownOpen(false);
        }
    }, [isOpen]);

    useEffect(() => {
        // Close all dropdowns on navigation change
        setUserDropdownOpen(false);
        setMarqueeDropdownOpen(false);
        setCategoryDropdownOpen(false);
        setGalleryDropdownOpen(false);
        setNewsDropdownOpen(false);
        setVideoDropdownOpen(false);
        setAdvertiseDropdownOpen(false);
        setPollDropdownOpen(false);
        setNewspaperDropdownOpen(false);
    }, [location]);

    // --- Role validation Effect (Corrected Parsing) ---
    useEffect(() => {
        const token = getToken();
        if (token) {
            axios.post(`${baseUrl}/validate-token`, {}, { // Send empty object if no body needed
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                // Correctly parse role from nested user object
                if (response.data && response.data.user && response.data.user.role) {
                    const userRole = response.data.user.role;
                    console.log("Validated Role:", userRole); // Debugging

                    // Use 'reporter' for employee links
                    setShowEmployeeLinks(userRole === 'reporter');
                    setShowAdminLinks(userRole === 'admin');
                } else {
                    console.error("Token validation response structure unexpected:", response.data);
                    removeToken();
                    navigate('/login');
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    console.warn("Token validation failed (Unauthorized/Expired). Redirecting to login.");
                } else {
                    console.error("Error validating token:", error.response ? error.response.data : error.message);
                }
                removeToken();
                navigate('/login');
            });
        } else {
            // No token found
            setShowEmployeeLinks(false);
            setShowAdminLinks(false);
             if (location.pathname !== '/login' && location.pathname !== '/') {
                // navigate('/login'); // Optional redirection
             }
        }
    }, [baseUrl, navigate, location]); // Dependencies


    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        try {
            const token = getToken();
            const headers = token ? { Authorization: `Bearer ${token}` } : {};
            await axios.post(`${baseUrl}/logout`, {}, { headers: headers, withCredentials: true });
        } catch (error) {
            console.error("Logout backend call failed:", error.response ? error.response.data : error.message);
        } finally {
            // Always cleanup client-side
            removeToken();
            setShowAdminLinks(false);
            setShowEmployeeLinks(false);
            setIsOpen(false); // Close sidebar
            navigate('/login');
        }
    };

    // --- Individual Toggle Functions ---
    const toggleUserDropdown = () => {
        const nextState = !userDropdownOpen;
        console.log(`Toggling User Dropdown. Current: ${userDropdownOpen}, Next: ${nextState}`);
        setUserDropdownOpen(nextState);
        // Optionally close others: setMarqueeDropdownOpen(false); ...
    }
    const toggleMarqueeDropdown = () => setMarqueeDropdownOpen(!marqueeDropdownOpen);
    const toggleCategoryDropdown = () => setCategoryDropdownOpen(!categoryDropdownOpen);
    const toggleGalleryDropdown = () => setGalleryDropdownOpen(!galleryDropdownOpen);
    const toggleNewsDropdown = () => setNewsDropdownOpen(!newsDropdownOpen);
    const toggleVideoDropdown = () => setVideoDropdownOpen(!videoDropdownOpen);
    const toggleAdvertiseDropdown = () => setAdvertiseDropdownOpen(!advertiseDropdownOpen);
    const togglePollDropdown = () => setPollDropdownOpen(!pollDropdownOpen);
    const toggleNewspaperDropdown = () => setNewspaperDropdownOpen(!newspaperDropdownOpen);

    // --- Link Data ---
    const adminSingleLinks = [
        { to: "/approval", text: "User News Approve", icon: FaUserCheck },
      
        { to: "/changepass", text: "Change Password", icon: FaKey },
    ];

    const employeeLinks = [
        { to: "/empaddnews", text: "Add News", icon: FaNewspaper },
        { to: "/emplistaddnews", text: "List News", icon: FaInfoCircle },
      { to: "/status", text: "Status", icon: FaInfoCircle },
    ];

    // --- Component Return ---
    return (
        <>
            {/* Navbar */}
            <nav className="navbar">
                <div className="nav-container">
                    <NavLink exact="true" to="/adduser" className="nav-logo">
                        <span><h1 style={{color:"white"}}>New29</h1></span>
                    </NavLink>
                    <button onClick={handleLogout} className="logout-btn">
                        <FaSignOutAlt />
                        <span className="logout-text">Logout</span>
                    </button>
                </div>
            </nav>

            {/* Layout */}
            <div className="layout">
                {/* Sidebar */}
                <div className={`sidebar ${isOpen ? "open" : ""}`}>
                    <div className="toggle-btn" onClick={toggleSidebar}>
                        <FaBars />
                    </div>
                    <nav className="menu">

                       {/* Admin Links */}
                       {showAdminLinks && (
                            <>
                                {/* User Dropdown */}
                                <div > {/* Wrapper div */}
                                    <div className="menu-item has-dropdown" onClick={isOpen ? toggleUserDropdown : undefined} >
                                        <FaUsers className="icon" />
                                        {isOpen && <span className="text">User</span>}
                                        {isOpen && <span className={`dropdown-arrow ${userDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    
                                    {userDropdownOpen && isOpen && (
                                        <div>
                                            
                                            <NavLink to="/adduser"  className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add User</NavLink>
                                            <NavLink to="/listuser"  className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List User</NavLink>
                                        </div>
                                    )}
                                    {/* --- END FORCED VISIBILITY TEST --- */}
                                </div>

                                {/* Marquee Heading Dropdown (Keep original logic & classNames) */}
                                <div>
                                    <div className="menu-item has-dropdown" onClick={isOpen ? toggleMarqueeDropdown : undefined}>
                                        <FaNewspaper className="icon" />
                                        {isOpen && <span className="text">Marquee heading</span>}
                                        {isOpen && <span className={`dropdown-arrow ${marqueeDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    {marqueeDropdownOpen && isOpen && (
                                        <div >
                                            <NavLink to="/addhead" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add heading</NavLink>
                                            <NavLink to="/listhead" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List heading</NavLink>
                                        </div>
                                    )}
                                </div>

                              

                                {/* News Category Dropdown */}
                                <div>
                                    <div className="menu-item has-dropdown" onClick={isOpen ? toggleCategoryDropdown : undefined}>
                                        <FaImage className="icon" />
                                        {isOpen && <span className="text">News Category</span>}
                                        {isOpen && <span className={`dropdown-arrow ${categoryDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    {categoryDropdownOpen && isOpen && (
                                        <div>
                                            <NavLink to="/addnewscategory" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add Category</NavLink>
                                            <NavLink to="/listnewscategory" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List Category</NavLink>
                                        </div>
                                    )}
                                </div>

                                {/* Gallery Dropdown */}
                                <div>
                                    <div className="menu-item has-dropdown" onClick={isOpen ? toggleGalleryDropdown : undefined}>
                                        <FaImage className="icon" />
                                        {isOpen && <span className="text">Gallery</span>}
                                         {isOpen && <span className={`dropdown-arrow ${galleryDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    {galleryDropdownOpen && isOpen && (
                                        <div>
                                            <NavLink to="/addgallery" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add Gallery</NavLink>
                                            <NavLink to="/listgallery" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List Gallery</NavLink>
                                        </div>
                                    )}
                                </div>

                                {/* News Dropdown */}
                                <div>
                                    <div className="menu-item has-dropdown" onClick={isOpen ? toggleNewsDropdown : undefined}>
                                        <FaNewspaper className="icon" />
                                        {isOpen && <span className="text">News</span>}
                                         {isOpen && <span className={`dropdown-arrow ${newsDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    {newsDropdownOpen && isOpen && (
                                        <div >
                                            <NavLink to="/addnews" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add News</NavLink>
                                            <NavLink to="/listnews" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List News</NavLink>
                                        </div>
                                    )}
                                </div>

                                {/* Video Dropdown */}
                                <div>
                                    <div className="menu-item has-dropdown" onClick={isOpen ? toggleVideoDropdown : undefined}>
                                        <FaVideo className="icon" />
                                        {isOpen && <span className="text">Youtube Videos</span>}
                                         {isOpen && <span className={`dropdown-arrow ${videoDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    {videoDropdownOpen && isOpen && (
                                        <div >
                                            <NavLink to="/addvideo" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add Youtube Video</NavLink>
                                            <NavLink to="/listvideo" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List Youtube Videos</NavLink>
                                        </div>
                                    )}
                                </div>

                                {/* Advertise Dropdown */}
                                <div>
                                    <div className="menu-item has-dropdown" onClick={isOpen ? toggleAdvertiseDropdown : undefined}>
                                        <FaBullhorn className="icon" />
                                        {isOpen && <span className="text">Advertise</span>}
                                         {isOpen && <span className={`dropdown-arrow ${advertiseDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    {advertiseDropdownOpen && isOpen && (
                                        <div >
                                            <NavLink to="/addadvertise" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add Advertise</NavLink>
                                            <NavLink to="/listadvertise" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List Advertise</NavLink>
                                        </div>
                                    )}
                                </div>

                                {/* Poll Dropdown */}
                                <div>
                                    <div className="menu-item has-dropdown" onClick={isOpen ? togglePollDropdown : undefined}>
                                        <FaPoll className="icon" />
                                        {isOpen && <span className="text">Poll</span>}
                                         {isOpen && <span className={`dropdown-arrow ${pollDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    {pollDropdownOpen && isOpen && (
                                        <div >
                                            <NavLink to="/addpoll" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add Polls</NavLink>
                                            <NavLink to="/listpoll" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List Polls</NavLink>
                                        </div>
                                    )}
                                </div>

                                {/* Newspaper Dropdown */}
                                <div>
                                    <div className="menu-item has-dropdown" onClick={isOpen ? toggleNewspaperDropdown : undefined}>
                                        <FaNewspaperIcon className="icon" />
                                        {isOpen && <span className="text">Newspaper</span>}
                                         {isOpen && <span className={`dropdown-arrow ${newspaperDropdownOpen ? 'open' : ''}`}>▼</span>}
                                    </div>
                                    {newspaperDropdownOpen && isOpen && (
                                        <div>
                                            <NavLink to="/addnewspaper" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>Add Newspaper</NavLink>
                                            <NavLink to="/listnewspaper" className={({ isActive }) => (isActive ? "dropdown-item active" : "dropdown-item")}>List Newspaper</NavLink>
                                        </div>
                                    )}
                                </div>

                                {/* Admin Single Links */}
                                {adminSingleLinks.map(link => (
                                     <NavLink key={link.to} to={link.to} className={({ isActive }) => (isActive ? "menu-item single active" : "menu-item single")}>
                                         <link.icon className="icon" />
                                         {isOpen && <span className="text">{link.text}</span>}
                                     </NavLink>
                                 ))}
                            </>
                        )}

                        {/* Employee (Reporter) Links */}
                        {showEmployeeLinks && (
                          <>
                             {employeeLinks.map(link => (
                                 <NavLink key={link.to} to={link.to} className={({ isActive }) => (isActive ? "menu-item single active" : "menu-item single")}>
                                     <link.icon className="icon" />
                                     {isOpen && <span className="text">{link.text}</span>}
                                 </NavLink>
                              ))}
                            </>
                        )}
                    </nav>
                </div>
                {/* Main Content */}
                <main className="content">{children}</main>
            </div>
        </>
    );
};

Sidebar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Sidebar;