import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';
// Assuming common styles are in Addnews.css or create AddVideo.css/ListVideos.css
import '../Addnews/Addnews.css'; // Keep if it has relevant styles

const AddVideo = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Use baseUrl consistently

    // --- Form State ---
    const [videoUrl, setVideoUrl] = useState('');
    const [videoTitle, setVideoTitle] = useState('');
    const [formWebsite1, setFormWebsite1] = useState(false); // Separate state for form checkbox
    const [formWebsite2, setFormWebsite2] = useState(false); // Separate state for form checkbox
    const [formMessage, setFormMessage] = useState('');
    const [formError, setFormError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // Loading state for form submission

    // --- List State ---
    const [videos, setVideos] = useState([]);
    const [listLoading, setListLoading] = useState(false); // Separate loading for list
    const [listMessage, setListMessage] = useState(''); // Separate message for list actions
    const [listError, setListError] = useState(''); // Separate error for list actions
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // --- Toggle State ---
    const [togglingVideoId, setTogglingVideoId] = useState(null); // ID of video being toggled

    // Fetch videos on component mount
    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        setListLoading(true);
        setListMessage('');
        setListError('');
        try {
            // Assume GET /videos doesn't require auth, add if needed
            // const token = localStorage.getItem('token');
            const response = await axios.get(`${baseUrl}/videos`); // Ensure correct endpoint

            // Normalize boolean fields
            const fetchedVideos = Array.isArray(response.data) ? response.data : [];
            const normalizedVideos = fetchedVideos.map(video => ({
                ...video,
                website1: video.website1 === true || video.website1 === 'true' || video.website1 === 1,
                website2: video.website2 === true || video.website2 === 'true' || video.website2 === 1,
            }));
            setVideos(normalizedVideos);

        } catch (error) {
            console.error('Error fetching videos:', error);
            setListError('Error fetching videos: ' + (error.response?.data?.message || error.message));
        } finally {
            setListLoading(false);
        }
    };

    // --- Form Handlers ---
    const handleTitleChange = (e) => setVideoTitle(e.target.value);
    const handleVideoUrlChange = (e) => setVideoUrl(e.target.value);
    const handleFormCheckboxChange = (setter) => (e) => setter(e.target.checked);

    const handleUploadVideo = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setFormMessage('');
        setFormError('');

        if (!videoUrl.trim()) {
            setFormError('Please enter a video URL.');
            return;
        }
        if (!videoTitle.trim()) {
            setFormError('Please enter a video title.');
            return;
        }

        setIsSubmitting(true);
        try {
            // Assume POST /upload-video requires auth, add if needed
            const token = localStorage.getItem('token'); // Get token if needed
            const response = await axios.post(`${baseUrl}/upload-video`, { // Ensure correct endpoint
                title: videoTitle,
                url: videoUrl,
                website1: formWebsite1, // Use form state variables
                website2: formWebsite2, // Use form state variables
            }, {
                 headers: { 'Authorization': `Bearer ${token}` } // Add auth header if needed
            });

            setFormMessage(response.data.message || 'Video uploaded successfully!');
            // Clear form
            setVideoUrl('');
            setVideoTitle('');
            setFormWebsite1(false);
            setFormWebsite2(false);
            fetchVideos(); // Refresh the list
        } catch (error) {
            console.error('Error uploading video:', error);
            setFormError('Error uploading video: ' + (error.response?.data?.message || error.message));
        } finally {
            setIsSubmitting(false);
        }
    };

    // --- List Action Handlers ---
    const handleDeleteVideo = async (id) => {
        // Prevent delete while status is being toggled
        if (togglingVideoId === id) return;

        if (window.confirm('Are you sure you want to delete this video?')) {
            setListMessage('');
            setListError('');
            // Consider adding a specific loading state for deletion if needed
            try {
                const token = localStorage.getItem('token'); // Get token if needed
                 if (!token) throw new Error("Authentication token not found.");

                const response = await axios.delete(`${baseUrl}/videos/${id}`, { // Ensure correct endpoint
                    headers: { 'Authorization': `Bearer ${token}` } // Add auth header if needed
                });
                setListMessage(response.data.message || 'Video deleted successfully!');
                // Optimistic UI update
                setVideos(prev => prev.filter(video => video._id !== id));
                // Adjust pagination if needed
                const newTotalItems = videos.length - 1;
                const newTotalPages = Math.ceil(newTotalItems / itemsPerPage);
                if (currentPage > newTotalPages && newTotalPages > 0) {
                    setCurrentPage(newTotalPages);
                } else if (newTotalItems === 0) {
                    setCurrentPage(1);
                }
            } catch (error) {
                 console.error('Error deleting video:', error);
                 setListError('Error deleting video: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    // --- NEW: Handle Status Toggle ---
    const handleToggleStatus = async (id, fieldKey, currentValue, currentVideoData) => {
        if (togglingVideoId === id) return; // Prevent rapid clicks

        setTogglingVideoId(id);
        setListMessage(''); // Clear previous messages
        setListError('');

        const newValue = !currentValue;

        try {
            const token = localStorage.getItem('token'); // Get token if update needs auth
             if (!token) throw new Error("Authentication token not found.");

            // Prepare payload - Assuming JSON PUT/PATCH for status updates
            // Include fields required by backend for update
            const payload = {
                title: currentVideoData.title, // Include if needed
                url: currentVideoData.url,     // Include if needed
                website1: fieldKey === 'website1' ? newValue : currentVideoData.website1,
                website2: fieldKey === 'website2' ? newValue : currentVideoData.website2,
                // If backend handles partial updates (PATCH), payload could be just: { [fieldKey]: newValue }
            };

            // *** VERIFY the UPDATE endpoint and METHOD (PUT/PATCH) for videos ***
            await axios.put(`${baseUrl}/videos/${id}`, payload, { // OR axios.patch
                headers: {
                    'Authorization': `Bearer ${token}`, // Add if needed
                    'Content-Type': 'application/json' // Sending JSON
                }
            });

            // Optimistic UI Update
            setVideos(prevVideos =>
                prevVideos.map(video =>
                    video._id === id
                        ? { ...video, [fieldKey]: newValue } // Update the specific field
                        : video
                )
            );
            // Optional: setListMessage(`${fieldKey} status updated.`);

        } catch (error) {
            console.error(`Error toggling ${fieldKey}:`, error);
            setListError(`Error updating ${fieldKey}: ` + (error.response?.data?.message || error.message));
        } finally {
            setTogglingVideoId(null); // Reset loading state for this item
        }
    };


    // --- Utility Functions ---
    const formatDateTime = (dateString) => {
        if (!dateString) return 'N/A';
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) return 'Invalid Date';
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Numeric month
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${day}/${month}/${year} ${hours}:${minutes}`;
        } catch (e) {
            console.error("Error formatting date:", dateString, e);
            return 'Invalid Date';
        }
    };

    const getYouTubeVideoId = (url = '') => { // Add default value
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const renderVideo = (videoUrl) => {
        if (!videoUrl) return <span className="no-video-placeholder">No URL</span>;
        const youtubeId = getYouTubeVideoId(videoUrl);

        if (youtubeId) {
            return (
                <iframe
                    width="160" // Slightly smaller default size
                    height="90"
                    src={`https://www.youtube.com/embed/${youtubeId}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="video-embed youtube-embed" // Add classes
                ></iframe>
            );
        } else {
            // Attempt direct video rendering - use caution with formats
             return (
                <video width="160" height="90" controls className="video-embed direct-video">
                    <source src={videoUrl} type="video/mp4" />
                    {/* Add other common types if needed, but MP4 is widely supported */}
                    {/* <source src={videoUrl} type="video/webm" /> */}
                    {/* <source src={videoUrl} type="video/ogg" /> */}
                    Your browser doesn't support embedded videos. <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video</a>
                </video>
            );
        }
    };

     // --- Pagination Logic ---
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = videos.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(videos.length / itemsPerPage);


    return (
        <>
            <Dashboard />

           
            {/* List Videos Section */}
            <div className="containerrs list-news-container list-videos-container">
                <h3>Uploaded Videos</h3>

                {listMessage && <div className="message success-message">{listMessage}</div>}
                {listError && <div className="message error-message">{listError}</div>}

                {listLoading ? (
                    <p className="loading-indicator">Loading videos...</p>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="polls-table news-list-table videos-list-table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Video Preview</th>
                                        <th>Upload Date</th>
                                        <th style={{ textAlign: 'center' }}>Web 1</th>
                                        <th style={{ textAlign: 'center' }}>Web 2</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.length > 0 ? (
                                        currentItems.map((video) => (
                                            <tr key={video._id}>
                                                <td title={video.title}>{video.title ? video.title.substring(0, 40) + (video.title.length > 40 ? '...' : '') : 'N/A'}</td>
                                                <td className="video-preview-cell">
                                                    {renderVideo(video.url)}
                                                </td>
                                                <td>{formatDateTime(video.createdAt)}</td>
                                                {/* Status Toggles */}
                                                <td style={{ textAlign: 'center' }}>
                                                     <span
                                                        className={`status-toggle ${togglingVideoId === video._id ? 'loading' : ''}`}
                                                        onClick={() => handleToggleStatus(video._id, 'website1', video.website1, video)}
                                                        title={`Toggle Website 1 (${video.website1 ? 'Active' : 'Inactive'})`}
                                                    >
                                                        {togglingVideoId === video._id ? '...' : (video.website1 ? '✅' : '❌')}
                                                    </span>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <span
                                                        className={`status-toggle ${togglingVideoId === video._id ? 'loading' : ''}`}
                                                        onClick={() => handleToggleStatus(video._id, 'website2', video.website2, video)}
                                                        title={`Toggle Website 2 (${video.website2 ? 'Active' : 'Inactive'})`}
                                                    >
                                                        {togglingVideoId === video._id ? '...' : (video.website2 ? '✅' : '❌')}
                                                    </span>
                                                </td>
                                                <td className="action-cell">
                                                    <button
                                                        className="action-button delete"
                                                        onClick={() => handleDeleteVideo(video._id)}
                                                        disabled={togglingVideoId === video._id || listLoading} // Disable delete while toggling or list loading
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" style={{ textAlign: 'center' }}>No videos found. Add one using the form above.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        {totalPages > 1 && (
                            <div className="pagination">
                                <button
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1 || listLoading}
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages || listLoading}
                                >
                                    Next
                                </button>
                            </div>
                         )}
                    </>
                )}
            </div>
            <AdminFooter />
        </>
    );
};

export default AddVideo;