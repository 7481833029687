import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './emp.css'; 
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';

const EmployeeRegistration = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [registrationError, setRegistrationError] = useState('');
  const baseUrl = process.env.REACT_APP_API_URL; // Use your API URL

  const handleEmployeeRegistration = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${baseUrl}/employee/register`, { // Make sure this matches your backend route
        email: email,
        password: password,
      });

      // Registration was successful
      toast.success('Employee registration successful!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Clear the form
      setEmail('');
      setPassword('');
      setRegistrationError('');

    } catch (error) {
      console.error('Employee registration error:', error.response ? error.response.data : error.message);
      let errorMessage = 'Employee registration failed.'; // Default message

      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message; // Use backend's error message if available
      } else if (error.message) {
        errorMessage = error.message; // Generic client-side error
      }

      setRegistrationError(errorMessage);
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
    <Dashboard />
    <div className="change-password-container" style={{marginTop:"220px"}}>
      <ToastContainer />
      <div className="registration-card">
        <h2 className="registration-title">Employee Registration</h2>
        {registrationError && <div className="error-message">{registrationError}</div>}
        <form onSubmit={handleEmployeeRegistration} className="registration-form">
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              placeholder="Enter employee email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              placeholder="Enter employee password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="register-button">Register Employee</button>
        </form>
      </div>
    </div>
    <AdminFooter/>
    </>
  );
};

export default EmployeeRegistration;