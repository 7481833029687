import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import '../Addnews/Addnews.css'; // Import CSS file for styling
import AdminFooter from '../Footer/Footeradmin';
import { getToken } from '../Login/Authutils';

const ListNewNews = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [newsArticles, setNewsArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const [editingArticleId, setEditingArticleId] = useState(null);
    const [editingArticle, setEditingArticle] = useState(null);
    const [newImage, setNewImage] = useState(null);

    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);

    const [mostPopular, setMostPopular] = useState(false);
    const [featured, setFeatured] = useState(false);
    const [popular, setPopular] = useState(false);
    const [latestNews, setLatestNews] = useState(false);
    const [mostViewed, setMostViewed] = useState(false);
    const [recentPosts, setRecentPosts] = useState(false);

    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    useEffect(() => {
        fetchNewsArticles();
    }, []);


    const fetchNewsArticles = async () => {
        try {
            const token = getToken(); // Get the token from Authutils
            const response = await axios.get(`${baseUrl}/reporter/news`, { // Ensure correct URL for employee-added news
                headers: {
                    'Authorization': `Bearer ${token}` // Add token to the Authorization header
                }
            });
            setNewsArticles(response.data);
        } catch (error) {
            console.error('Error fetching news articles:', error);
            setMessage('Error fetching news articles: ' + error.message); // Display error message
        }
    };

    const handleEdit = (article) => {
        setEditingArticleId(article._id);
        setEditingArticle({ ...article });
        setNewImage(null);
        setWebsite1(article.website1);
        setWebsite2(article.website2);

        setMostPopular(article.mostPopular);
        setFeatured(article.featured);
        setPopular(article.popular);
        setLatestNews(article.latestNews);
        setMostViewed(article.mostViewed);
        setRecentPosts(article.recentPosts);

        setImagePreview(article.image ? `${baseUrl}/${article.image}` : null);
        setShowModal(true); // Show the modal
    };

    const handleCancelEdit = () => {
        setEditingArticleId(null);
        setEditingArticle(null);
        setNewImage(null);
        setImagePreview(null);
        setShowModal(false); // Hide the modal
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingArticle({ ...editingArticle, [name]: value });
    };

    const handleImageChange = (e) => {
        setNewImage(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
    };

    const handleDelete = async (id) => {
        try {
            const token = getToken(); // Get the token
            await axios.delete(`${baseUrl}/reporter/news/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}` // Add token to the Authorization header
                }
            });
            setMessage('Article deleted successfully');
            fetchNewsArticles();
        } catch (error) {
            setMessage('Error deleting article: ' + error.message);
        }
    };

    const handleUpdate = async () => {
        try {
            const token = getToken(); // Get the token
            const formData = new FormData();
            formData.append('category', editingArticle.category);
            formData.append('title', editingArticle.title);
            formData.append('viewSide', editingArticle.viewSide);
            formData.append('description', editingArticle.description);
            formData.append('date', editingArticle.date);
            formData.append('website1', website1);
            formData.append('website2', website2);
            formData.append('mostPopular', mostPopular);
            formData.append('featured', featured);
            formData.append('popular', popular);
            formData.append('latestNews', latestNews);
            formData.append('mostViewed', mostViewed);
            formData.append('recentPosts', recentPosts);
            formData.append('status', editingArticle.status); // SEND THE STATUS
            if (newImage) {
                formData.append('image', newImage);
            }

            await axios.put(`${baseUrl}/reporter/news/${editingArticleId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}` // Add token to the Authorization header
                }
            });

            setMessage('Article updated successfully');
            setEditingArticleId(null);
            setEditingArticle(null);
            setNewImage(null);
            fetchNewsArticles();
            setImagePreview(null);
            setShowModal(false); // Hide the modal after updating
        } catch (error) {
            setMessage('Error updating article: ' + error.message);
        }
    };

    return (
        <>
            <Dashboard />
            <div className="containerrs list-news-container" style={{ marginTop: "50px" }}>
                <h1>List News Articles</h1>

                {message && <div className="error-message">{message}</div>}

                {loading ? (
                    <p>Loading articles...</p>
                ) : (
                    <>
                        <table className="polls-table news-list-table">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Title</th>
                                    <th>Image</th>
                                    <th>View Side</th>
                                    <th>Description</th>
                                    <th>Date</th>
                                    <th>Website 1</th>
                                    <th>Website 2</th>
                                    <th>Most Popular</th>
                                    <th>Featured</th>
                                    <th>Popular</th>
                                    <th>Latest News</th>
                                    <th>Most Viewed</th>
                                    <th>Recent Posts</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {newsArticles.map((article) => (
                                    <tr key={article._id}>
                                        <td>{article.category}</td>
                                        <td>{article.title}</td>
                                        <td>
                                            {article.image && (
                                                <img
                                                    src={`${baseUrl}/${article.image}`}
                                                    alt={article.title}
                                                    style={{ width: '90px', height: '90px', borderRadius: '10%' }}
                                                />
                                            )}
                                        </td>
                                        <td>{article.viewSide}</td>
                                        <td>{article.description}</td>
                                        <td>{new Date(article.date).toLocaleDateString()}</td>
                                        <td>{article.website1 ? '✅' : '❌'}</td>
                                        <td>{article.website2 ? '✅' : '❌'}</td>
                                        <td>{article.mostPopular ? '✅' : '❌'}</td>
                                        <td>{article.featured ? '✅' : '❌'}</td>
                                        <td>{article.popular ? '✅' : '❌'}</td>
                                        <td>{article.latestNews ? '✅' : '❌'}</td>
                                        <td>{article.mostViewed ? '✅' : '❌'}</td>
                                        <td>{article.recentPosts ? '✅' : '❌'}</td>
                                        <td>
                                            <button onClick={() => handleEdit(article)}>Edit</button>
                                            <button onClick={() => handleDelete(article._id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Modal for Editing */}
                        {showModal && (
                            <div className="modal">
                                <div className="modal-content">
                                    <span className="close" onClick={handleCancelEdit}>×</span>
                                    <h2>Edit News Article</h2>
                                    <div className="form-group">
                                        <label htmlFor="category">Category:</label>
                                        <input
                                            type="text"
                                            name="category"
                                            value={editingArticle.category || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="title">Title:</label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={editingArticle.title || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="image">Image:</label>
                                        {imagePreview ? (
                                            <img
                                                src={imagePreview}
                                                alt={editingArticle.title}
                                                style={{ width: '90px', height: '90px', borderRadius: '10%' }}
                                            />
                                        ) : (
                                            editingArticle.image && (
                                                <img
                                                    src={`${baseUrl}/${editingArticle.image}`}
                                                    alt={editingArticle.title}
                                                    style={{ width: '90px', height: '90px', borderRadius: '10%' }}
                                                />
                                            )
                                        )}
                                        <input type="file" onChange={handleImageChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="viewSide">View Side:</label>
                                        <select
                                            name="viewSide"
                                            value={editingArticle.viewSide || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="left">Left</option>
                                            <option value="right">Right</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="description">Description:</label>
                                        <textarea
                                            name="description"
                                            value={editingArticle.description || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="date">Date:</label>
                                        <input
                                            type="date"
                                            name="date"
                                            value={editingArticle.date ? new Date(editingArticle.date).toISOString().split('T')[0] : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="form_group_new website_container_new">
                                        <label className="label_new">
                                            Website 1:
                                            <input
                                                style={{ marginBottom: "4px" }}
                                                type="checkbox"
                                                name="website1"
                                                checked={website1}
                                                onChange={(e) => setWebsite1(e.target.checked)}
                                            />
                                        </label>

                                        <label className="label_new">
                                            Website 2:
                                            <input
                                                style={{ marginBottom: "4px" }}
                                                type="checkbox"
                                                name="website2"
                                                checked={website2}
                                                onChange={(e) => setWebsite2(e.target.checked)}
                                            />
                                        </label>
                                    </div>


                                    <div className="form_group_new tags_container_new">
                                        <label className="label_new">
                                            <input
                                                style={{ marginBottom: "5px" }}
                                                type="checkbox"
                                                name="mostPopular"
                                                checked={mostPopular}
                                                onChange={(e) => setMostPopular(e.target.checked)}
                                            />
                                            Most Popular
                                        </label>


                                        <label className="label_new">
                                            <input
                                                style={{ marginBottom: "5px" }}
                                                type="checkbox"
                                                name="featured"
                                                checked={featured}
                                                onChange={(e) => setFeatured(e.target.checked)}
                                            />
                                            Featured
                                        </label>


                                        <label className="label_new">
                                            <input
                                                style={{ marginBottom: "5px" }}
                                                type="checkbox"
                                                name="popular"
                                                checked={popular}
                                                onChange={(e) => setPopular(e.target.checked)}
                                            />
                                            Popular
                                        </label>


                                        <label className="label_new">
                                            <input
                                                style={{ marginBottom: "5px" }}
                                                type="checkbox"
                                                name="latestNews"
                                                checked={latestNews}
                                                onChange={(e) => setLatestNews(e.target.checked)}
                                            />
                                            Latest News
                                        </label>


                                        <label className="label_new">
                                            <input
                                                style={{ marginBottom: "5px" }}
                                                type="checkbox"
                                                name="mostViewed"
                                                checked={mostViewed}
                                                onChange={(e) => setMostViewed(e.target.checked)}
                                            />
                                            Most Viewed
                                        </label>


                                        <label className="label_new">
                                            <input
                                                style={{ marginBottom: "5px" }}
                                                type="checkbox"
                                                name="recentPosts"
                                                checked={recentPosts}
                                                onChange={(e) => setRecentPosts(e.target.checked)}
                                            />
                                            Recent Posts
                                        </label>
                                    </div>

                                    <div className="form-actions">
                                        <button onClick={handleUpdate}>Update</button>
                                        <button onClick={handleCancelEdit}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <AdminFooter />
        </>
    );
};

export default ListNewNews;