import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
// Assuming Addnews.css contains relevant styles, or create a new one
import '../Addnews/Addnews.css';
import AdminFooter from '../Footer/Footeradmin';

const ListAdvertisements = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [advertisements, setAdvertisements] = useState([]);
    const [loading, setLoading] = useState(false); // For initial fetch
    const [message, setMessage] = useState('');

    // --- Edit Modal State ---
    const [editingAdId, setEditingAdId] = useState(null);
    const [editingAd, setEditingAd] = useState(null); // Holds the ad data being edited in modal
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    // State for checkboxes within the modal (mirrors editingAd but used for direct binding)
    const [modalWebsite1, setModalWebsite1] = useState(false);
    const [modalWebsite2, setModalWebsite2] = useState(false);
    const [modalRightLeft, setModalRightLeft] = useState(false);
    const [modalBannerTop, setModalBannerTop] = useState(false);
    const [modalBannerBottom, setModalBannerBottom] = useState(false);
    const [modalTopBottom, setModalTopBottom] = useState(false);
    const [modalCategoryPage, setModalCategoryPage] = useState(false);
    const [modalDetailsPage, setModalDetailsPage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isUpdatingModal, setIsUpdatingModal] = useState(false); // Loading state for modal update

    // --- Toggle State ---
    const [togglingAdId, setTogglingAdId] = useState(null); // Tracks which Ad's status is being toggled directly

    useEffect(() => {
        fetchAdvertisements();
    }, []);

    const fetchAdvertisements = async () => {
        setLoading(true); // Use loading state for fetch
        setMessage('');
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${baseUrl}/advertisements`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            // Ensure boolean fields are correctly typed
            const ads = response.data.map(ad => ({
                ...ad,
                website1: ad.website1 === true || ad.website1 === 'true',
                website2: ad.website2 === true || ad.website2 === 'true',
                rightLeft: ad.rightLeft === true || ad.rightLeft === 'true',
                bannerTop: ad.bannerTop === true || ad.bannerTop === 'true',
                bannerBottom: ad.bannerBottom === true || ad.bannerBottom === 'true',
                topBottom: ad.topBottom === true || ad.topBottom === 'true',
                categoryPage: ad.categoryPage === true || ad.categoryPage === 'true',
                detailsPage: ad.detailsPage === true || ad.detailsPage === 'true',
            }));
            setAdvertisements(ads);
        } catch (error) {
            console.error('Error fetching advertisements:', error);
            setMessage('Error fetching advertisements: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    // --- Edit Modal Handlers ---
    const handleEdit = (ad) => {
        if (togglingAdId === ad._id) return; // Prevent opening edit while toggling

        setEditingAdId(ad._id);
        setEditingAd({ ...ad }); // Store full ad data for editing
        setNewImage(null);

        // Set modal checkbox states from the ad data
        setModalWebsite1(ad.website1);
        setModalWebsite2(ad.website2);
        setModalRightLeft(ad.rightLeft);
        setModalBannerTop(ad.bannerTop);
        setModalBannerBottom(ad.bannerBottom);
        setModalTopBottom(ad.topBottom);
        setModalCategoryPage(ad.categoryPage);
        setModalDetailsPage(ad.detailsPage);

        setImagePreview(ad.image ? `${baseUrl}/${ad.image}` : null);
        setShowModal(true);
    };

    const handleCancelEdit = () => {
        setEditingAdId(null);
        setEditingAd(null);
        setNewImage(null);
        setImagePreview(null);
        setShowModal(false);
        setIsUpdatingModal(false); // Reset modal loading state
    };

    const handleInputChange = (e) => { // For text/select inputs in modal
        const { name, value } = e.target;
        setEditingAd(prev => ({ ...prev, [name]: value }));
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setNewImage(e.target.files[0]);
            setImagePreview(URL.createObjectURL(e.target.files[0]));
        }
    };

    const handleUpdate = async () => {
        if (!editingAd || isUpdatingModal) return;

        setIsUpdatingModal(true); // Set modal update loading
        setMessage('');

        try {
            const formData = new FormData();
            // Append fields expected by the backend (match backend requirements)
            formData.append('title', editingAd.title);
            formData.append('url', editingAd.url);
            formData.append('viewSide', editingAd.viewSide);
            // Append boolean values from modal state
            formData.append('website1', modalWebsite1);
            formData.append('website2', modalWebsite2);
            formData.append('rightLeft', modalRightLeft);
            formData.append('bannerTop', modalBannerTop);
            formData.append('bannerBottom', modalBannerBottom);
            formData.append('topBottom', modalTopBottom);
            formData.append('categoryPage', modalCategoryPage);
            formData.append('detailsPage', modalDetailsPage);

            if (newImage) {
                formData.append('image', newImage);
            }

            const token = localStorage.getItem('token');
            await axios.put(`${baseUrl}/advertisements/${editingAdId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            setMessage('Advertisement updated successfully');
            // Optimistically update UI or refetch
            fetchAdvertisements(); // Refetch to get the latest data including potentially new image URL
            handleCancelEdit(); // Close modal and reset state

        } catch (error) {
            console.error('Error updating advertisement:', error);
            setMessage('Error updating advertisement: ' + (error.response?.data?.message || error.message));
            setIsUpdatingModal(false); // Reset loading on error
        }
    };


    // --- Delete Handler ---
    const handleDelete = async (id) => {
         if (editingAdId === id || togglingAdId === id) return; // Prevent delete while editing/toggling

        if (window.confirm('Are you sure you want to delete this advertisement?')) {
            setMessage('');
            try {
                const token = localStorage.getItem('token');
                await axios.delete(`${baseUrl}/advertisements/${id}`, {
                     headers: { 'Authorization': `Bearer ${token}` }
                 });
                setMessage('Advertisement deleted successfully');
                // Update UI immediately
                setAdvertisements(prev => prev.filter(ad => ad._id !== id));
            } catch (error) {
                console.error('Error deleting advertisement:', error);
                setMessage('Error deleting advertisement: ' + (error.response?.data?.message || error.message));
            }
        }
    };


    // --- NEW: Direct Status Toggle Handler ---
    const handleToggleStatus = async (id, fieldKey, currentValue, currentAdData) => {
        // Prevent action if already editing or another toggle is in progress for this ad
        if (editingAdId === id || togglingAdId === id) return;

        setTogglingAdId(id); // Mark this ad as being updated
        setMessage('');

        const newValue = !currentValue;

        try {
            // Prepare payload: Send all relevant fields as expected by the PUT endpoint
            // Assuming a JSON PUT endpoint here (modify if backend needs FormData for toggles too)
            const payload = {
                title: currentAdData.title,
                url: currentAdData.url,
                viewSide: currentAdData.viewSide,
                website1: currentAdData.website1,
                website2: currentAdData.website2,
                rightLeft: currentAdData.rightLeft,
                bannerTop: currentAdData.bannerTop,
                bannerBottom: currentAdData.bannerBottom,
                topBottom: currentAdData.topBottom,
                categoryPage: currentAdData.categoryPage,
                detailsPage: currentAdData.detailsPage,
                 // Overwrite the toggled field with the new value
                [fieldKey]: newValue
            };

            // Remove fields the backend might not expect or handle in PUT
            // delete payload._id; // Example if needed
            // delete payload.image; // Don't send image path for simple status toggle

            const token = localStorage.getItem('token');
            await axios.put(
                `${baseUrl}/advertisements/${id}`,
                payload, // Send JSON payload
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json' // Specify JSON content type
                     }
                }
            );

            // Optimistic UI Update
            setAdvertisements(prevAds =>
                prevAds.map(ad =>
                    ad._id === id
                        ? { ...ad, [fieldKey]: newValue } // Update the specific field locally
                        : ad
                )
            );
            // Optional: Short success message
            // setMessage(`${fieldKey} status updated.`);

        } catch (error) {
            console.error(`Error toggling ${fieldKey}:`, error);
            setMessage(`Error updating ${fieldKey}: ` + (error.response?.data?.message || error.message));
            // Optional: Revert UI change on error if needed
        } finally {
            setTogglingAdId(null); // Unmark item - update finished (success or fail)
        }
    };


    // --- Render Logic ---
    return (
        <>
            <Dashboard />
            <div className="containerrs list-news-container list-advertisements-container" style={{ marginTop: "50px" }}>
                <h1>List Advertisements</h1>

                {/* Display Message */}
                {message && (
                     <p className={`message ${message.includes('Error') ? 'error' : 'success'}`}>
                         {message}
                     </p>
                 )}

                {loading ? (
                    <p>Loading advertisements...</p>
                ) : (
                    <>
                        <div className="table-responsive"> {/* Added for better mobile view */}
                            <table className="polls-table news-list-table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Image</th>
                                        <th>URL</th>
                                        <th>Type</th>
                                        {/* Add text-align center for status columns */}
                                        <th style={{ textAlign: 'center' }}>Web 1</th>
                                        <th style={{ textAlign: 'center' }}>Web 2</th>
                                        <th style={{ textAlign: 'center' }}>R/L</th>
                                        <th style={{ textAlign: 'center' }}>B.Top</th>
                                        <th style={{ textAlign: 'center' }}>B.Bot</th>
                                        <th style={{ textAlign: 'center' }}>T/B</th>
                                        <th style={{ textAlign: 'center' }}>Cat.</th>
                                        <th style={{ textAlign: 'center' }}>Detail</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {advertisements.length > 0 ? advertisements.map((ad) => (
                                        <tr key={ad._id}>
                                            <td>{ad.title}</td>
                                            <td>
                                                {ad.image && (
                                                    <img
                                                        src={`${baseUrl}/${ad.image}`}
                                                        alt={ad.title}
                                                        style={{ width: '90px', height: '90px', borderRadius: '10%' }}
                                                    />
                                                )}
                                            </td>
                                            <td><a href={ad.url} target="_blank" rel="noopener noreferrer">{ad.url ? ad.url.substring(0, 20) + '...' : 'N/A'}</a></td>
                                            <td>{ad.viewSide}</td>

                                            {/* --- Status Toggles --- */}
                                            <td style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingAdId === ad._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(ad._id, 'website1', ad.website1, ad)}
                                                    title={`Click to toggle Website 1 (${ad.website1 ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingAdId === ad._id ? '...' : (ad.website1 ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingAdId === ad._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(ad._id, 'website2', ad.website2, ad)}
                                                    title={`Click to toggle Website 2 (${ad.website2 ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingAdId === ad._id ? '...' : (ad.website2 ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingAdId === ad._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(ad._id, 'rightLeft', ad.rightLeft, ad)}
                                                    title={`Click to toggle Right/Left (${ad.rightLeft ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingAdId === ad._id ? '...' : (ad.rightLeft ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingAdId === ad._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(ad._id, 'bannerTop', ad.bannerTop, ad)}
                                                    title={`Click to toggle Banner Top (${ad.bannerTop ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingAdId === ad._id ? '...' : (ad.bannerTop ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingAdId === ad._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(ad._id, 'bannerBottom', ad.bannerBottom, ad)}
                                                    title={`Click to toggle Banner Bottom (${ad.bannerBottom ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingAdId === ad._id ? '...' : (ad.bannerBottom ? '✅' : '❌')}
                                                </span>
                                            </td>
                                              <td style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingAdId === ad._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(ad._id, 'topBottom', ad.topBottom, ad)}
                                                    title={`Click to toggle Top/Bottom (${ad.topBottom ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingAdId === ad._id ? '...' : (ad.topBottom ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingAdId === ad._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(ad._id, 'categoryPage', ad.categoryPage, ad)}
                                                    title={`Click to toggle Category Page (${ad.categoryPage ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingAdId === ad._id ? '...' : (ad.categoryPage ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingAdId === ad._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(ad._id, 'detailsPage', ad.detailsPage, ad)}
                                                    title={`Click to toggle Details Page (${ad.detailsPage ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingAdId === ad._id ? '...' : (ad.detailsPage ? '✅' : '❌')}
                                                </span>
                                            </td>
                                            {/* --- End Status Toggles --- */}

                                            <td>
                                                <button className="action-button edit" onClick={() => handleEdit(ad)} disabled={togglingAdId === ad._id}>Edit</button>
                                                <button className="action-button delete" onClick={() => handleDelete(ad._id)} disabled={togglingAdId === ad._id || editingAdId === ad._id}>Delete</button>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan="13" style={{ textAlign: 'center' }}>No advertisements found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* Modal for Editing */}
                        {showModal && editingAd && ( // Ensure editingAd is not null
                            <div className="modal ad-edit-modal"> {/* Added specific class */}
                                <div className="modal-content">
                                    <span className="close" onClick={handleCancelEdit}>×</span>
                                    <h2>Edit Advertisement</h2>

                                    {/* Form fields inside the modal */}
                                    <div className="form-group">
                                        <label htmlFor="title">Title:</label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={editingAd.title || ''}
                                            onChange={handleInputChange}
                                            disabled={isUpdatingModal}
                                        />
                                    </div>

                                     <div className="form-group">
                                        <label htmlFor="url">URL:</label>
                                        <input
                                            type="url" // Use type="url" for better validation
                                            name="url"
                                            value={editingAd.url || ''}
                                            onChange={handleInputChange}
                                             disabled={isUpdatingModal}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="viewSide">Type Advertise:</label>
                                        <select
                                            name="viewSide"
                                            value={editingAd.viewSide || ''}
                                            onChange={handleInputChange}
                                             disabled={isUpdatingModal}
                                        >
                                            <option value="">Select Type</option> {/* Add default option */}
                                            <option value="Single">Single</option>
                                            <option value="Double">Double</option>
                                        </select>
                                    </div>

                                    {/* Image Upload */}
                                    <div className="form-group">
                                        <label htmlFor="image">Image:</label>
                                        {imagePreview && (
                                            <img
                                                src={imagePreview}
                                                alt="Preview"
                                                className="ad-modal-preview" // Use class
                                            />
                                        )}
                                        <input type="file" accept="image/*" onChange={handleImageChange}  disabled={isUpdatingModal}/>
                                    </div>


                                    {/* Checkboxes - bind to modal state variables */}
                                     <div className="form_group_new website_container_new">
                                        <label className="label_new">
                                            Website 1:
                                            <input type="checkbox" checked={modalWebsite1} onChange={(e) => setModalWebsite1(e.target.checked)} disabled={isUpdatingModal} />
                                        </label>
                                         <label className="label_new">
                                            Website 2:
                                            <input type="checkbox" checked={modalWebsite2} onChange={(e) => setModalWebsite2(e.target.checked)} disabled={isUpdatingModal} />
                                        </label>
                                    </div>

                                    <div className="form_group_new tags_container_new">
                                        <label className="label_new">
                                            <input type="checkbox" checked={modalRightLeft} onChange={(e) => setModalRightLeft(e.target.checked)} disabled={isUpdatingModal} /> Right Left
                                        </label>
                                         <label className="label_new">
                                            <input type="checkbox" checked={modalBannerTop} onChange={(e) => setModalBannerTop(e.target.checked)} disabled={isUpdatingModal} /> Banner Top
                                        </label>
                                         <label className="label_new">
                                            <input type="checkbox" checked={modalBannerBottom} onChange={(e) => setModalBannerBottom(e.target.checked)} disabled={isUpdatingModal}/> Banner Bottom
                                        </label>
                                         <label className="label_new">
                                            <input type="checkbox" checked={modalTopBottom} onChange={(e) => setModalTopBottom(e.target.checked)} disabled={isUpdatingModal}/> Top Bottom
                                        </label>
                                         <label className="label_new">
                                            <input type="checkbox" checked={modalCategoryPage} onChange={(e) => setModalCategoryPage(e.target.checked)} disabled={isUpdatingModal}/> Category Page
                                        </label>
                                         <label className="label_new">
                                            <input type="checkbox" checked={modalDetailsPage} onChange={(e) => setModalDetailsPage(e.target.checked)} disabled={isUpdatingModal}/> Details Page
                                        </label>
                                    </div>

                                    <div className="form-actions">
                                        <button onClick={handleUpdate} disabled={isUpdatingModal}>
                                            {isUpdatingModal ? 'Updating...' : 'Update'}
                                        </button>
                                        <button onClick={handleCancelEdit} disabled={isUpdatingModal}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <AdminFooter />
        </>
    );
};

export default ListAdvertisements;