import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import './Addnews.css'; // Import the CSS file
import AdminFooter from '../Footer/Footeradmin';

const AddNewsArticle = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [viewSide, setViewSide] = useState('left');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [message, setMessage] = useState('');
    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);

    const [mostPopular, setMostPopular] = useState(false);
    const [featured, setFeatured] = useState(false);
    const [popular, setPopular] = useState(false);
    const [latestNews, setLatestNews] = useState(false);
    const [mostViewed, setMostViewed] = useState(false);
    const [recentPosts, setRecentPosts] = useState(false);

    // NEW: State and Options for the Category Dropdown
    const [categoryValue, setCategoryValue] = useState('');
    const categoryOptions = [
        { value: 'देश-विदेश', label: 'देश-विदेश' },
        { value: 'खास मुलाकात', label: 'खास मुलाकात' },
        { value: 'राजनीति', label: 'राजनीति' },
        { value: 'व्यापार जगत', label: 'व्यापार जगत' },
        { value: 'ज्योतिष', label: 'ज्योतिष' },
        { value: 'जोधपुर विशेष', label: 'जोधपुर विशेष' },
        { value: 'RTI (सुचना का अधिकार)', label: 'RTI (सुचना का अधिकार)' },
        { value: 'पर्यावरण संबंधित', label: 'पर्यावरण संबंधित' },
        { value: 'धार्मिक - समाचार', label: 'धार्मिक - समाचार' },
        { value: 'अपराध', label: 'अपराध' },
        { value: 'कोरोना अपडेट', label: 'कोरोना अपडेट' },
        { value: 'अजब - गजब', label: 'अजब - गजब' },
        { value: 'जॉब एंड कैरियर', label: 'जॉब एंड कैरियर' },
        { value: 'तीज - त्यौहार', label: 'तीज - त्यौहार' },
        { value: 'इतिहास व सत्यकथा', label: 'इतिहास व सत्यकथा' },
        { value: 'आयुर्वेदा', label: 'आयुर्वेदा' },
        { value: 'चालीसा व आरती संग्रह', label: 'चालीसा व आरती संग्रह' },
        { value: 'अंतरराष्ट्रीय', label: 'अंतरराष्ट्रीय' },
        { value: 'राष्ट्रीय', label: 'राष्ट्रीय' },
        { value: 'भ्रष्टाचार के खिलाफ मुहिम', label: 'भ्रष्टाचार के खिलाफ मुहिम' },
        { value: 'शाही रसोई', label: 'शाही रसोई' },
        { value: 'लेख/ Article', label: 'लेख/ Article' }
    ];

 
    const [textBoxDescription, setTextBoxDescription] = useState('');

    useEffect(() => {
        // No need to fetch categories from API anymore, using dummy data
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // *** FRONT-END VALIDATION: Check for Empty Description ***
        if (!description || description.trim() === '') {
            setMessage('Description is required.'); // Show message to the user
            return; // Stop submission
        }

        const formData = new FormData();
        formData.append('category', categoryValue);
        formData.append('title', title);
        if (image) { // Only append if an image is selected
            formData.append('image', image);
        }
        formData.append('viewSide', viewSide);
        formData.append('description', description);
        formData.append('date', date);
        formData.append('website1', website1);
        formData.append('website2', website2);

        formData.append('mostPopular', mostPopular);
        formData.append('featured', featured);
        formData.append('popular', popular);
        formData.append('latestNews', latestNews);
        formData.append('mostViewed', mostViewed);
        formData.append('recentPosts', recentPosts);
        formData.append('textBoxDescription', textBoxDescription)

        try {
            const response = await axios.post(`${baseUrl}/news`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage(response.data.message);

            setCategoryValue('');
            setTitle('');
            setImage(null);
            setViewSide('left');
            setDescription('');
            setDate('');
            setWebsite1(false);
            setWebsite2(false);

            setMostPopular(false);
            setFeatured(false);
            setPopular(false);
            setLatestNews(false);
            setMostViewed(false);
            setRecentPosts(false);
            setTextBoxDescription('')

        } catch (error) {
            console.error("Error adding news article:", error);
            setMessage('Error adding news article: ' + error.message);
        }
    };

    return (
        <>
            <Dashboard />
            <div className="containerrs" style={{ marginTop: "50px" }}>
                <h1>Add New News</h1>

                {message && <div className="message_new">{message}</div>}

                <form onSubmit={handleSubmit}  encType="multipart/form-data" className="news_form_new">
                     {/* NEW: Category Dropdown */}
                     <div className="form_group_new category_title_image_container_new">
                        <label htmlFor="category" className="label_new">Category:</label>
                        <select
                            id="category"
                            value={categoryValue}
                            onChange={(e) => setCategoryValue(e.target.value)}
                            required
                        >
                            <option value="">Select Category</option>
                            {categoryOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    

                   
                        <label htmlFor="title" className="label_new">Title:</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                   

                   
                        <label htmlFor="image" className="label_new">Image:</label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            onChange={(e) => setImage(e.target.files[0])}
                            accept="image/*"
                            required
                        />
                    </div>

                    <div className="form_group_new view_side_container_new">
                        <label className="label_new">View Side:</label>
                        <label className="label_new">
                            <input
                            style={{marginBottom:"10px"}}
                                type="radio"
                                name="viewSide"
                                value="left"
                                checked={viewSide === 'left'}
                                onChange={() => setViewSide('left')}
                            />
                            Left
                        </label>
                        <label className="label_new">
                            <input
                             style={{marginBottom:"10px"}}
                                type="radio"
                                name="viewSide"
                                value="right"
                                checked={viewSide === 'right'}
                                onChange={() => setViewSide('right')}
                            />
                            Right
                        </label>
                        <div className="form_group_new date_time_container_new">
                         <label htmlFor="date" className="label_new">Date:</label>
                        <input
                            type="date"
                            id="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </div>
                    </div>
                   

                    <div className="form_group_new">
                        <label htmlFor="description" className="label_new">Description:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows="4"  // Adjust as needed
                            cols="50" // Adjust as needed
                            required
                        />
                    </div>

                  

                  

                 


                    <div className="form_group_new website_container_new">
                         <label className="label_new">
                            Website 1:
                               <input
                                  style={{marginBottom:"4px"}}
                                     type="checkbox"
                                    name="website1"
                                     checked={website1}
                                      onChange={(e) => setWebsite1(e.target.checked)}
                                       />
                         </label>
                   
                         <label className="label_new">
                            Website 2:
                             <input
                               style={{marginBottom:"4px"}}
                                 type="checkbox"
                                  name="website2"
                                      checked={website2}
                                       onChange={(e) => setWebsite2(e.target.checked)}
                                   />
                          </label>
                    </div>


                          <div className="form_group_new tags_container_new">
                              <label className="label_new">
                                  <input
                                  style={{marginBottom:"5px"}}
                                      type="checkbox"
                                      name="mostPopular"
                                      checked={mostPopular}
                                      onChange={(e) => setMostPopular(e.target.checked)}
                                  />
                                  Most Popular
                              </label>

                            
                              <label className="label_new">
                                  <input
                                   style={{marginBottom:"5px"}}
                                      type="checkbox"
                                      name="featured"
                                      checked={featured}
                                      onChange={(e) => setFeatured(e.target.checked)}
                                  />
                                  Featured
                              </label>

                             
                              <label className="label_new">
                                  <input
                                   style={{marginBottom:"5px"}}
                                      type="checkbox"
                                      name="popular"
                                      checked={popular}
                                      onChange={(e) => setPopular(e.target.checked)}
                                  />
                                  Popular
                              </label>

                             
                              <label className="label_new">
                                  <input
                                   style={{marginBottom:"5px"}}
                                      type="checkbox"
                                      name="latestNews"
                                      checked={latestNews}
                                      onChange={(e) => setLatestNews(e.target.checked)}
                                  />
                                  Latest News
                              </label>

                              
                              <label className="label_new">
                                  <input
                                   style={{marginBottom:"5px"}}
                                      type="checkbox"
                                      name="mostViewed"
                                      checked={mostViewed}
                                      onChange={(e) => setMostViewed(e.target.checked)}
                                  />
                                  Most Viewed
                              </label>

                            
                              <label className="label_new">
                                  <input
                                   style={{marginBottom:"5px"}}
                                      type="checkbox"
                                      name="recentPosts"
                                      checked={recentPosts}
                                      onChange={(e) => setRecentPosts(e.target.checked)}
                                  />
                                  Recent Posts
                              </label>
                          </div>

                    <button type="submit" className="submit_button_new">Submit</button>
                </form>
            </div>
            <AdminFooter/>
        </>
    );
};

export default AddNewsArticle;