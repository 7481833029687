import React, { useState } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import "./Addnewspaper.css"
import AdminFooter from '../Footer/Footeradmin';

const AddNewspaper = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [heading, setHeading] = useState('');
    const [pdfFile, setPdfFile] = useState(null);
    const [date, setDate] = useState('');
    const [message, setMessage] = useState('');
  const [website1, setWebsite1] = useState(false);
  const [website2, setWebsite2] = useState(false);

    const handleHeadingChange = (e) => {
        setHeading(e.target.value);
    };

    const handlePdfFileChange = (e) => {
        // Check if the selected file is a PDF
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            setPdfFile(file);
        } else {
            setPdfFile(null);
            setMessage('Please select a PDF file.');
        }
    };

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const handleAddNewspaper = async () => {
        if (!heading || !pdfFile || !date) {
            setMessage('Please fill in all fields.');
            return;
        }

        const formData = new FormData();
        formData.append('heading', heading);
        formData.append('pdf', pdfFile);
        formData.append('date', date);
        formData.append('website1', website1.toString());
        formData.append('website2', website2.toString());


        try {
            const response = await axios.post(`${baseUrl}/newspapers`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage(response.data.message);
            setHeading('');
            setPdfFile(null);
            setDate('');
              setWebsite1(false);
              setWebsite2(false);
        } catch (error) {
            setMessage('Error adding newspaper: ' + error.message);
        }
    };

    return (
        <>
            <Dashboard />
           
            <div className="containerrs" style={{marginTop:"50px"}}>
                <h2>Add Newspaper</h2>
                <div className="newspaper-form">
                    <label htmlFor="heading">Heading:</label>
                    <input
                        type="text"
                        id="heading"
                        value={heading}
                        onChange={handleHeadingChange}
                    />

                    <label htmlFor="pdfFile">PDF File:</label>
                    <input
                        type="file"
                        id="pdfFile"
                        accept="application/pdf"
                        onChange={handlePdfFileChange}
                    />
                              

<h4 style={{color:"red"}}>* Add pdf File only</h4>
<br/><label htmlFor="date">Date:</label>
                    <input
                        type="date"
                        id="date"
                        value={date}
                        onChange={handleDateChange}
                    />
  <label>
                                        Website 1:
                                            <input
                                                type="checkbox"
                                                name="website1"
                                                 checked={website1}
                                                  onChange={(e) => setWebsite1(e.target.checked)}
                                        />
                                   </label>
                                  <label>
                                        Website 2:
                                         <input
                                              type="checkbox"
                                               name="website2"
                                              checked={website2}
                                            onChange={(e) => setWebsite2(e.target.checked)}
                                         />
                                   </label>
                                   <br/>
                    <button onClick={handleAddNewspaper}>Add Newspaper</button>
                </div>

                <p className={message.includes('Error') ? 'error' : ''}>{message}</p>
            </div>
            <AdminFooter/>
        </>
    );
};

export default AddNewspaper;