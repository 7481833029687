// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './Login.css';
// import { setToken, getToken, clearToken } from './Authutils';
// import AdminFooter from '../Footer/Footeradmin';

// const Login = () => {
//     const baseUrl = process.env.REACT_APP_API_URL;
//     const [userId, setUserId] = useState(''); // For Reporter ID
//     const [email, setEmail] = useState('');   // For Admin Email
//     const [password, setPassword] = useState('');
//     const [loginType, setLoginType] = useState('admin'); // Default to Admin
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [error, setError] = useState('');

//     useEffect(() => {
//         // Check for an existing token on component mount and redirect accordingly
//         const token = getToken();
//         if (token) {
//             axios.post(`${baseUrl}/validate-token`, {}, {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             })
//             .then(response => {
//                 const { role } = response.data;
//                 if (role === 'admin') {
//                     navigate('/addgallery');
//                 } else if (role === 'reporter') {
//                     navigate('/employee-dashboard');
//                 } else {
//                     clearToken();
//                     navigate('/login');
//                 }
//             })
//             .catch(error => {
//                 clearToken();
//                 navigate('/login');
//             });
//         }
//     }, [baseUrl, navigate]);

//     const handleLogin = async (e) => {
//         e.preventDefault();
//         try {
//             let loginEndpoint = '/'; //Base Endpoint

//             if (loginType === 'admin') {
//                 loginEndpoint += 'admin/login';
//             } else if (loginType === 'reporter') {
//                 loginEndpoint += 'reporter/login';
//             }

//             const payload = loginType === 'reporter' ? { userId, password } : { email, password };

//             const res = await axios.post(`${baseUrl}${loginEndpoint}`, payload, { withCredentials: true });
//             setToken(res.data.token);

//             toast.success('Login successful!', {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//             });

//             const token = res.data.token;
//             try {
//                 const decodedToken = JSON.parse(atob(token.split('.')[1]));

//                 if (decodedToken.role === 'admin') {
//                     navigate('/addgallery');
//                 } else if (decodedToken.role === 'reporter') {
//                     navigate('/employee-dashboard');
//                 } else {
//                     console.warn("User role not defined in token.");
//                     clearToken();
//                     navigate('/login');
//                 }
//             } catch (decodeError) {
//                 console.error("Error decoding token:", decodeError);
//                 clearToken();
//                 navigate('/login');
//             }
//         } catch (err) {
//             toast.error('Invalid credentials. Please try again.', {
//                 position: "top-right",
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//             });
//             setError('Invalid credentials');
//             console.error('Login error:', err);
//         }
//     };

//     return (
//         <>
//             <div className="login-container">
//                 <ToastContainer />
//                 <div className="login-card">
//                     <h2 className="login-title">{loginType === 'admin' ? 'Admin Login' : 'Reporter Login'}</h2>

//                     <div className="login-type-buttons">
//                         <button
//                             type="button"
//                             className={`login-type-button ${loginType === 'admin' ? 'active' : ''}`}
//                             onClick={() => setLoginType('admin')}
//                         >
//                             Admin Login
//                         </button>
//                         <button
//                             type="button"
//                             className={`login-type-button ${loginType === 'reporter' ? 'active' : ''}`}
//                             onClick={() => setLoginType('reporter')}
//                         >
//                             Reporter Login
//                         </button>
//                     </div>

//                     {error && <div className="error-message">{error}</div>}
//                     <form onSubmit={handleLogin} className="login-form">

//                         {loginType === 'admin' && (
//                             <div className="form-groups">
//                                 <label htmlFor="email">Email:</label>
//                                 <input
//                                     type="email"
//                                     id="email"
//                                     placeholder="Enter your email"
//                                     value={email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     required
//                                 />
//                             </div>
//                         )}

//                         {loginType === 'reporter' && (
//                             <div className="form-groups">
//                                 <label htmlFor="userId">Reporter ID:</label>
//                                 <input
//                                     type="text"
//                                     id="userId"
//                                     placeholder="Enter your Reporter ID"
//                                     value={userId}
//                                     onChange={(e) => setUserId(e.target.value)}
//                                     required
//                                 />
//                             </div>
//                         )}

//                         <div className="form-groups">
//                             <label htmlFor="password">Password:</label>
//                             <input
//                                 type="password"
//                                 id="password"
//                                 placeholder="Enter your password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 required
//                             />
//                         </div>

//                         <button type="submit" className="login-button">Login</button>
//                     </form>
//                 </div>
//             </div>
//             <AdminFooter />
//         </>
//     );
// };

// export default Login;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css'; // Assuming you keep the same CSS file or create AdminLogin.css
import { setToken, getToken, clearToken } from './Authutils';
import AdminFooter from '../Footer/Footeradmin'; // Assuming this footer is appropriate

const AdminLogin = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState('');   // Only Admin Email needed
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    useEffect(() => {
        // Check for an existing token on component mount
        const token = getToken();
        if (token) {
            axios.post(`${baseUrl}/validate-token`, {}, { // Still validate token
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                const { role } = response.data;
                if (role === 'admin') {
                    navigate('/addgallery'); // Redirect admin if already logged in
                } else {
                    // If a non-admin token exists on admin login page, clear it
                    console.warn("Non-admin token found on admin login page. Clearing token.");
                    clearToken();
                    // Stay on the admin login page or navigate('/admin-login') if routes are set up
                }
            })
            .catch(error => {
                console.error("Token validation failed:", error);
                clearToken();
                // Stay on the admin login page
            });
        }
        // No need to navigate to /login as we are already on a specific login page
    }, [baseUrl, navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors
        try {
            const loginEndpoint = '/admin/login'; // Specific endpoint for admin
            const payload = { email, password }; // Specific payload for admin

            const res = await axios.post(`${baseUrl}${loginEndpoint}`, payload, { withCredentials: true });
            setToken(res.data.token);

            toast.success('Admin login successful!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            // Optional: Decode token client-side for immediate role check (backend validation is primary)
            const token = res.data.token;
            try {
                const decodedToken = JSON.parse(atob(token.split('.')[1]));
                if (decodedToken.role === 'admin') {
                    navigate('/addgallery'); // Navigate to admin dashboard
                } else {
                    // This case should ideally not happen if backend is correct
                    console.error("Login successful but received non-admin token.");
                    toast.error('Login error: Role mismatch.');
                    clearToken();
                    setError('Login error: Role mismatch.');
                }
            } catch (decodeError) {
                console.error("Error decoding token:", decodeError);
                toast.error('Login successful, but could not verify role.');
                 // Proceed with caution or clear token depending on security policy
                navigate('/addgallery'); // Or potentially clear token and show error
            }

        } catch (err) {
            toast.error('Invalid credentials. Please try again.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError('Invalid credentials');
            console.error('Admin Login error:', err.response?.data || err.message);
        }
    };

    return (
        <>
            <div className="login-container">
                <ToastContainer />
                <div className="login-card">
                    <h2 className="login-title">Admin Login</h2>

                    {/* Removed login type buttons */}

                    {error && <div className="error-message">{error}</div>}
                    <form onSubmit={handleLogin} className="login-form">

                        {/* Only Admin Email Input */}
                        <div className="form-groups">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        {/* Password Input */}
                        <div className="form-groups">
                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>

                        <button type="submit" className="login-button">Login</button>
                    </form>
                </div>
            </div>
            <AdminFooter />
        </>
    );
};

export default AdminLogin;