// src/components/EPaperViewer.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import 'pdfjs-dist/web/pdf_viewer.css';
import './EPaperViewer.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

const EPaperViewer = () => {
    const { newspaperId } = useParams();
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    
    const [newspaper, setNewspaper] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    
    useEffect(() => {
        const fetchNewspaperDetails = async () => {
            if (!newspaperId) return;
            setLoading(true);
            setError('');
            setNewspaper(null);

            try {
                const response = await axios.get(`${baseUrl}/newspapers/${newspaperId}`);
                setNewspaper(response.data);
            } catch (err) {
                console.error("Error fetching newspaper details:", err);
                setError(`Failed to load newspaper details. ${err.response?.data?.message || err.message}`);
            } finally {
                setLoading(false);
            }
        };
        fetchNewspaperDetails();
    }, [newspaperId, baseUrl]);
    
    return (
        <>
        <Navbar/>
        <div className="epaper-viewer-container">
            <div className="viewer-header">
                <Link to="/epaper" className="back-link">← Back to Archive</Link>
                <h1>{newspaper?.heading || 'E-Paper Viewer'}</h1>
                {newspaper?.date && <p>Date: {new Date(newspaper.date).toLocaleDateString()}</p>}
            </div>

            {loading && <p className="loading-indicator">Loading E-Paper...</p>}
            {error && <p className="error-message">{error}</p>}

            {newspaper?.pdfPath && (
                <div className="pdf-viewer">
                    <iframe 
                        src={`${baseUrl}${newspaper.pdfPath}`} 
                        title="E-Paper Viewer" 
                        width="100%" 
                        height="1200px" 
                    ></iframe>
                </div>
            )}
        </div>
        <Footer/>
        </>
    );
};

export default EPaperViewer;


// // src/components/EPaperViewer.jsx
// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import axios from 'axios';

// import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
// import * as pdfjsLib from 'pdfjs-dist/build/pdf'; // Import core library
// import 'pdfjs-dist/web/pdf_viewer.css'; // Recommended for styling (optional)
// import './EPaperViewer.css'; // Create this CSS file

// // IMPORTANT: Set up the worker source for pdf.js
// // Adjust the path based on where the worker file is located after build or installation
// // Option 1: If using Create React App or similar (copy worker file to public folder)
// pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;


// // Option 2: Direct path if using CDN or custom setup (replace with your path)
// // pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
// // Option 3: If installed via npm (might require additional build steps/config)
// // import WorkerSrc from 'pdfjs-dist/build/pdf.worker.entry';
// // pdfjsLib.GlobalWorkerOptions.workerSrc = WorkerSrc;


// const EPaperViewer = () => {
//     const { newspaperId } = useParams();
//     const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
//     const canvasRef = useRef(null); // Ref to the canvas element for rendering

//     const [newspaper, setNewspaper] = useState(null);
//     const [pdfDoc, setPdfDoc] = useState(null); // Stores the loaded PDF document object
//     const [currentPage, setCurrentPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(0);
//     const [scale, setScale] = useState(1.5); // Initial rendering scale
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState('');
//     const [isRendering, setIsRendering] = useState(false); // Track rendering state

//     // Fetch Newspaper Details (including pdfPath)
//     useEffect(() => {
//         const fetchNewspaperDetails = async () => {
//             if (!newspaperId) return;
//             setLoading(true);
//             setError('');
//             setNewspaper(null);
//             setPdfDoc(null);
//             setCurrentPage(1);
//             setTotalPages(0);

//             try {
//                 const response = await axios.get(`${baseUrl}/newspapers/${newspaperId}`);
//                 setNewspaper(response.data);
//                 // Load PDF immediately after getting details
//                 if (response.data?.pdfPath) {
//                     loadPdf(`${baseUrl}${response.data.pdfPath}`); // Prepend baseUrl
//                 } else {
//                      throw new Error("PDF path not found for this newspaper.");
//                 }
//             } catch (err) {
//                 console.error("Error fetching newspaper details:", err);
//                 setError(`Failed to load newspaper details. ${err.response?.data?.message || err.message}`);
//                 setLoading(false); // Stop loading on error here
//             }
//         };
//         fetchNewspaperDetails();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [newspaperId, baseUrl]); // Depend on newspaperId and baseUrl


//     // Load PDF Function
//     const loadPdf = useCallback(async (pdfUrl) => {
//         try {
//             const loadingTask = pdfjsLib.getDocument(pdfUrl);
//             const loadedPdfDoc = await loadingTask.promise;
//             setPdfDoc(loadedPdfDoc);
//             setTotalPages(loadedPdfDoc.numPages);
//             setError(''); // Clear previous PDF loading errors
//         } catch (err) {
//             console.error("Error loading PDF:", err);
//             setError(`Failed to load PDF: ${err.message}`);
//             setPdfDoc(null); // Reset pdfDoc on error
//             setTotalPages(0);
//         } finally {
//             // Loading might finish here, or after the first page render
//             // setLoading(false); // Moved this to after first render attempt
//         }
//     }, []);

//     // Render Page Function (using useCallback for stability)
//     const renderPage = useCallback(async (pageNum) => {
//         if (!pdfDoc || isRendering) return; // Don't render if no doc or already rendering

//         setIsRendering(true);
//         setError(''); // Clear rendering errors
//         const canvas = canvasRef.current;
//         const ctx = canvas?.getContext('2d');

//         if (!ctx) {
//             setError("Could not get canvas context.");
//             setIsRendering(false);
//             setLoading(false); // Ensure loading stops
//             return;
//         }

//         try {
//             const page = await pdfDoc.getPage(pageNum);
//             const viewport = page.getViewport({ scale: scale });

//             canvas.height = viewport.height;
//             canvas.width = viewport.width;

//             const renderContext = {
//                 canvasContext: ctx,
//                 viewport: viewport,
//             };

//             await page.render(renderContext).promise;
//              setCurrentPage(pageNum); // Update current page state *after* successful render

//         } catch (err) {
//             console.error(`Error rendering page ${pageNum}:`, err);
//             setError(`Error rendering page ${pageNum}: ${err.message}`);
//             // Optionally clear canvas on error: ctx.clearRect(0, 0, canvas.width, canvas.height);
//         } finally {
//             setIsRendering(false);
//             setLoading(false); // Stop main loading indicator after first render attempt
//         }
//     }, [pdfDoc, scale, isRendering]); // Dependencies for renderPage


//     // Effect to render the current page when pdfDoc or currentPage changes
//     useEffect(() => {
//         if (pdfDoc) {
//             renderPage(currentPage);
//         }
//     }, [pdfDoc, currentPage, renderPage]); // renderPage is now stable due to useCallback

//     // --- Navigation Handlers ---
//     const goToPreviousPage = () => {
//         if (currentPage > 1) {
//              renderPage(currentPage - 1); // Render previous page
//         }
//     };

//     const goToNextPage = () => {
//         if (currentPage < totalPages) {
//             renderPage(currentPage + 1); // Render next page
//         }
//     };

//      // Zoom Handlers (Optional)
//     const zoomIn = () => setScale(prevScale => prevScale + 0.2);
//     const zoomOut = () => setScale(prevScale => Math.max(0.5, prevScale - 0.2)); // Prevent zooming too small

//     return (
//         <div className="epaper-viewer-container">
//             <div className="viewer-header">
//                  <Link to="/epaper" className="back-link">← Back to Archive</Link>
//                  <h1>{newspaper?.heading || 'E-Paper Viewer'}</h1>
//                 {newspaper?.date && <p>Date: {new Date(newspaper.date).toLocaleDateString()}</p>}
//             </div>

//             {loading && <p className="loading-indicator">Loading E-Paper...</p>}
//             {error && <p className="error-message">{error}</p>}

//             {!loading && pdfDoc && ( // Only show controls and canvas if PDF is loaded
//                 <>
//                     <div className="viewer-controls">
//                         <button onClick={goToPreviousPage} disabled={currentPage <= 1 || isRendering}>
//                             Previous Page
//                         </button>
//                         <span> Page {currentPage} of {totalPages} </span>
//                         <button onClick={goToNextPage} disabled={currentPage >= totalPages || isRendering}>
//                             Next Page
//                         </button>
//                          {/* Optional Zoom */}
//                          <button onClick={zoomOut} disabled={isRendering}>Zoom Out</button>
//                          <button onClick={zoomIn} disabled={isRendering}>Zoom In</button>
//                          <span>Scale: {scale.toFixed(1)}x</span>
//                     </div>

//                     <div className="canvas-container">
//                         {isRendering && <div className="rendering-overlay">Rendering Page...</div>}
//                         <canvas ref={canvasRef}></canvas>
//                     </div>

//                     {/* Repeat controls at bottom if desired */}
//                      <div className="viewer-controls bottom-controls">
//                         <button onClick={goToPreviousPage} disabled={currentPage <= 1 || isRendering}>
//                             Previous Page
//                         </button>
//                         <span> Page {currentPage} of {totalPages} </span>
//                         <button onClick={goToNextPage} disabled={currentPage >= totalPages || isRendering}>
//                             Next Page
//                         </button>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// };

// export default EPaperViewer;