import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link for navigation

// Static Image Imports (Ensure paths are correct or remove if unused)
import placeholderImg from "../Navbar/body_card1.png"; // Generic placeholder
// Social icons imports are removed as they are replaced by the poll widget

import "./Home.css"; // Import the CSS
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

// --- Configuration ---
const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.29news.live:5004'; // Default if not set
const WEATHER_NEWS_API_URL = 'https://newsdata.io/api/1/news?apikey=';
const WEATHER_NEWS_API_KEY = 'pub_74929d382acc6388fea8c3d955692fdf259ab'; // Replace with your actual API key

// --- Helper Functions ---
const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    try {
        return new Date(dateString).toLocaleDateString(undefined, options);
    } catch (e) {
        console.error("Error formatting date:", e);
        return dateString; // Return original if formatting fails
    }
};

const getYouTubeVideoId = (url) => {
    if (!url) return null;
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
};

// Helper specifically for poll vote calculation
const getTotalVotes = (poll) => {
    // Check if poll and options array exist and is an array
    if (!poll || !Array.isArray(poll.options)) {
        console.warn("Invalid poll structure for getTotalVotes:", poll);
        return 0;
    }
    return poll.options.reduce((sum, option) => sum + (option.votes || 0), 0);
};


// --- Child Components (Modified to accept props) ---

function Marquee({ headings = [] }) {
    if (!headings.length) return null;
    return (
        <div className="marquee-container">
            <marquee behavior="scroll" direction="left" scrollamount="8">
                {headings.map((heading) => (
                    <span key={heading._id} className="marquee-item">
                        {heading.heading}
                    </span>
                ))}
            </marquee>
        </div>
    );
}

function TrendingArea({ featuredNews = [] }) {
    const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

    useEffect(() => {
        if (featuredNews.length > 1) {
            const interval = setInterval(() => {
                setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % featuredNews.length);
            }, 5000); // Auto slide news every 5 seconds
            return () => clearInterval(interval);
        }
    }, [featuredNews.length]);

    const mainNews = featuredNews[currentNewsIndex];
    const sideNews = featuredNews.slice(0, 2);

    return (
        <div className="trending-area fix pt-25 gray-bg">
            <div className="container">
                <div className="trending-main">
                    <div className="row">
                        <div className="col-lg-8">
                            {/* Trending Top */}
                            {mainNews ? (
                <div className="trending-top mb-30">
                    <div className="trend-top-img">
                        <img src={`${API_BASE_URL}/${mainNews.image}`} alt={mainNews.title} />
                        <div className="trend-top-cap">
                            <span className="bgr">{mainNews.category}</span>
                            <h2><Link to={`/news/${mainNews._id}`}>{mainNews.title}</Link></h2>
                            <p>{mainNews.date ? formatDate(mainNews.date) : ''}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="trending-top mb-30 placeholder">Welcome to News 29</div>
            )}
                        </div>
                        {/* Right content */}
                        <div className="col-lg-4">
                            <div className="row">
                                {sideNews.length > 0 ? sideNews.map((news) => (
                                    <div className="col-lg-12 col-md-6 col-sm-6" key={news._id}>
                                        <div className="trending-top mb-30">
                                            <div className="trend-top-img">
                                                <img src={`${API_BASE_URL}/${news.image}`} alt={news.title}/>
                                                <div className="trend-top-cap trend-top-cap2">
                                                    <span className="bgb">{news.category}</span>
                                                    <h2><Link to={`/news/${news._id}`}>{news.title}</Link></h2>
                                                    <p>{formatDate(news.date)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : (
                                     <div className="col-lg-12 placeholder">No featured news.</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// --- Modified WhatsNew Component with Poll Integration ---
function WhatsNew({ categories = [], allNews = [], bannerAd }) {
    const API_BASE_URL = "http://localhost:5000";
    const [activeTab, setActiveTab] = useState("");
    const [polls, setPolls] = useState([]);
    const [loadingPolls, setLoadingPolls] = useState(true);
    const [errorPolls, setErrorPolls] = useState(null);
    const [votedPolls, setVotedPolls] = useState({});
     const [poll, setPoll] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [voted, setVoted] = useState(false);

    useEffect(() => {
        if (!activeTab && categories.length > 0) {
            setActiveTab(categories[0].heading);
        }
        fetchPolls();
    }, [categories]);

    const fetchPolls = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/polls`);
            if (response.data.length > 0) {
                // Sort polls by date (assuming 'date' is a valid field in your API response)
                const sortedPolls = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setPolls([sortedPolls[0]]); // Keep only the latest poll
            } else {
                setPolls([]);
            }
        } catch (error) {
            setErrorPolls("Failed to load polls");
        } finally {
            setLoadingPolls(false);
        }
    };
    
    const handleVote = async (pollId, optionIndex) => {
        if (votedPolls[pollId]) return;

        try {
            await axios.post(`${API_BASE_URL}/polls/vote/${pollId}`, { optionIndex });
            setPolls(polls.map((poll) => {
                if (poll._id === pollId) {
                    poll.options[optionIndex].votes += 1;
                }
                return poll;
            }));
            setVotedPolls({ ...votedPolls, [pollId]: true });
        } catch (error) {
            console.error("Error submitting vote:", error);
        }
    };

    // 🛠️ Fix: Define `newsForTab` first
    const newsForTab = activeTab ? allNews.filter(news => news.category === activeTab) : [];

    const mainNewsItem = newsForTab.find(news => news.featured) || newsForTab[0];
    const sideNewsItems = newsForTab.filter(news => news._id !== mainNewsItem?._id).slice(0, 4);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    // Get category names for tabs
    const tabNames = categories.map(cat => cat.heading);

    return (
        <section className="whats-news-area pt-50 pb-20 gray-bg">
            <div className="container">
                <div className="row">
                    {/* Left Column (Main Content: News Tabs) */}
                    <div className="col-lg-8">
                        <div className="whats-news-wrapper">
                            {/* Heading & Nav Button */}
                            <div className="row justify-content-between align-items-end mb-15">
                                <div className="col-xl-4">
                                    <div className="section-tittle mb-30">
                                        <h3>What's New</h3>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-md-9">
                                    <div className="properties__button">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                {tabNames.map((tabName) => (
                                                    <a
                                                        key={tabName}
                                                        className={`nav-item nav-link ${activeTab === tabName ? 'active' : ''}`}
                                                        id={`nav-${tabName}-tab`}
                                                        data-toggle="tab" // Bootstrap attributes (might need JS)
                                                        href={`#nav-${tabName}`} // Target for tab content
                                                        role="tab"
                                                        aria-controls={`nav-${tabName}`}
                                                        aria-selected={activeTab === tabName}
                                                        onClick={(e) => { e.preventDefault(); handleTabClick(tabName); }}
                                                    >
                                                        {tabName}
                                                    </a>
                                                ))}
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            {/* Tab content */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="tab-content" id="nav-tabContent">
                                        {/* Render content ONLY for the active tab */}
                                        {/* Using key={activeTab} on the outer div helps React re-render efficiently */}
                                        <div key={activeTab} className={`tab-pane fade show active`} id={`nav-${activeTab}`} role="tabpanel" aria-labelledby={`nav-${activeTab}-tab`}>
                                            <div className="row">
                                                {/* Left Details Caption */}
                                                <div className="col-xl-6 col-lg-12">
                                                    {mainNewsItem ? (
                                                        <div className="whats-news-single mb-40">
                                                            <div className="whates-img">
                                                                <img src={`${API_BASE_URL}/${mainNewsItem.image}`} alt={mainNewsItem.title} />
                                                            </div>
                                                            <div className="whates-caption">
                                                                <h4><Link to={`/news/${mainNewsItem._id}`}>{mainNewsItem.title}</Link></h4>
                                                                <span>{formatDate(mainNewsItem.date)}</span>
                                                                {/* Truncate description */}
                                                                <p>{mainNewsItem.description?.substring(0, 150)}{mainNewsItem.description?.length > 150 ? '...' : ''}</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        // Show placeholder if no news for the active tab
                                                        <div className="placeholder mb-40">No news available for {activeTab || 'this category'}.</div>
                                                    )}
                                                </div>
                                                {/* Right single caption */}
                                                <div className="col-xl-6 col-lg-12">
                                                    <div className="row">
                                                        {sideNewsItems.map((item) => (
                                                            <div className="col-xl-12 col-lg-6 col-md-6 col-sm-10" key={item._id}>
                                                                <div className="whats-right-single mb-20">
                                                                    <div className="whats-right-img">
                                                                         <img src={`${API_BASE_URL}/${item.image}`} alt={item.title}/>
                                                                    </div>
                                                                    <div className="whats-right-cap">
                                                                        <span className="colorb">{item.category}</span>
                                                                        <h4><Link to={`/news/${item._id}`}>{item.title}</Link></h4>
                                                                        <p>{formatDate(item.date)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         {/* Banner */}
                         {bannerAd && (
                            <div className="banner-one mt-20 mb-30">
                                <a href={bannerAd.url} target="_blank" rel="noopener noreferrer">
                                    <img src={`${API_BASE_URL}/${bannerAd.image}`} alt={bannerAd.title} style={{height:"541px"}} />
                                </a>
                            </div>
                        )}
                    </div>

                    {/* === SIDEBAR === */}
                    <div className="col-lg-4">

                         {/* --- LATEST POLL WIDGET --- */}
                         <div className="widget-content">
    {loadingPolls ? (
        <p>Loading polls...</p>
    ) : errorPolls ? (
        <p>{errorPolls}</p>
    ) : (
        polls.map((poll) => {
            const totalVotes = poll.options.reduce((sum, opt) => sum + (opt.votes || 0), 0);

            return (
                <div key={poll._id} className="poll-container">
                    <h3 className="poll-title">Polls</h3>
                    <p className="poll-question">{poll.question}</p>
                    
                    <ul className="poll-options-list">
                        {poll.options.map((option, index) => {
                            const percentage = totalVotes > 0 ? ((option.votes || 0) / totalVotes * 100).toFixed(2) : 0;

                            return (
                                <li key={index} className="poll-option">
                                    <div className="option-header">
                                        <span className="option-text">{option.text}</span>
                                        <span className="option-percentage">{percentage}% ({option.votes} votes)</span>
                                    </div>
                                    
                                    <div className="progress-bar">
                                        <div className="progress-fill" style={{ width: `${percentage}%` }}></div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    
                    <p className="total-votes">Total Votes: {totalVotes}</p>
                </div>
            );
        })
    )}
</div>

                        {/* --- END POLL WIDGET --- */}

                        {/* Most Recent Area */}
                        <div className="most-recent-area">
                            <div className="small-tittle mb-20">
                                <h4>Most Recent</h4>
                            </div>
                            {/* Ensure allNews is available and has items */}
                            {allNews && allNews.length > 0 ? allNews.slice(0, 3).map((item, index) => (
                                index === 0 ? (
                                     <div className="most-recent mb-40" key={`recent-${item._id}`}>
                                        <div className="most-recent-img">
                                            <img src={`${API_BASE_URL}/${item.image}`} alt={item.title}/>
                                            <div className="most-recent-cap">
                                                <span className="bgbeg">{item.category}</span>
                                                <h4><Link to={`/news/${item._id}`}>{item.title}</Link></h4>
                                                <p>{formatDate(item.date)}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="most-recent-single" key={`recent-${item._id}`}>
                                        <div className="most-recent-images">
                                            <img src={`${API_BASE_URL}/${item.image}`} alt={item.title}/>
                                        </div>
                                        <div className="most-recent-capt">
                                            <h4><Link to={`/news/${item._id}`}>{item.title}</Link></h4>
                                            <p>{formatDate(item.date)}</p>
                                        </div>
                                    </div>
                                )
                            )) : (
                                <div className="placeholder">No recent news.</div>
                            )}
                        </div>
                    </div>
                     {/* === END SIDEBAR === */}
                </div>
            </div>
        </section>
    );
}


function WeeklyNews({ popularNews = [], bannerAd }) {
    const newsToShow = popularNews.slice(0, 4);

    return (
        <div className="weekly2-news-area pt-50 pb-30 gray-bg">
            <div className="container">
                <div className="weekly2-wrapper">
                    <div className="row">
                        <div className="col-lg-3">
                             {bannerAd && (
                                <div className="home-banner2 d-none d-lg-block">
                                    <a href={bannerAd.url} target="_blank" rel="noopener noreferrer">
                                        <img src={`${API_BASE_URL}/${bannerAd.image}`} alt={bannerAd.title} style={{height:"495px"}} />
                                    </a>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-9">
                            <div className="slider-wrapper">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="small-tittle mb-30">
                                            <h4>Most Popular</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {newsToShow.length > 0 ? (
                                            <div className="weekly2-news-active d-flex">
                                                {newsToShow.map((news) => (
                                                    <div className="weekly2-single" key={`weekly2-${news._id}`}>
                                                        <div className="weekly2-img">
                                                             <img src={`${API_BASE_URL}/${news.image}`} alt={news.title}/>
                                                        </div>
                                                        <div className="weekly2-caption">
                                                            <h4><Link to={`/news/${news._id}`}>{news.title}</Link></h4>
                                                            <p>{formatDate(news.date)}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                             <div className="placeholder">No popular news available.</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function RecentArticles({ trendingNews = [] }) {
    const newsToShow = trendingNews.slice(0, 4);

    return (
        <div className="recent-articles pt-80 pb-80">
            <div className="container">
                <div className="recent-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-tittle mb-30">
                                <h3>Latest News</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                           {newsToShow.length > 0 ? (
                                <div className="recent-active dot-style d-flex dot-style">
                                    {newsToShow.map(news => (
                                        <div className="single-recent" key={`recent-art-${news._id}`}>
                                            <div className="what-img">
                                                 <img src={`${API_BASE_URL}/${news.image}`} alt={news.title}/>
                                            </div>
                                            <div className="what-cap">
                                                <h4><Link to={`/news/${news._id}`} style={{color:"black"}}>{news.title}</Link></h4>
                                                <p>{formatDate(news.date)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                           ) : (
                               <div className="placeholder">No trending news available.</div>
                           )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function VideoArea() {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
    const baseUrl = process.env.REACT_APP_API_URL;

    const fetchVideos = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/videos`);
            setVideos(response.data);
            setMessage('');
        } catch (error) {
            setMessage('Error fetching videos: ' + error.message);
            console.error("Error fetching videos:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVideos();
    }, []);

    const handleThumbnailClick = (index) => {
        setSelectedVideoIndex(index);
    };

    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const renderVideo = (videoUrl) => {
        const youtubeId = getYouTubeVideoId(videoUrl);

        if (youtubeId) {
            return (
                <iframe
                    width="100%" // Adjusted for responsive design
                    height="400" // Adjusted height, you can modify as needed
                    src={`https://www.youtube.com/embed/${youtubeId}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            );
        } else {
            return (
                <video controls style={{ width: '100%' }}>
                    <source src={videoUrl} type="video/mp4" />
                    <source src={videoUrl} type="video/webm" />
                    <source src={videoUrl} type="video/ogg" />
                    Your browser does not support the video tag.
                </video>
            );
        }
    };

    const renderThumbnail = (videoUrl) => {
        const youtubeId = getYouTubeVideoId(videoUrl);

        if (youtubeId) {
            return (
                <img
                    src={`https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`} // Use medium quality thumbnail
                    alt={`YouTube Video Thumbnail`}
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                />
            );
        } else {
            return (
                <video
                    src={videoUrl}
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    muted // Add muted attribute to prevent autoplay issues
                />
            );
        }
    };

    if (loading) {
        return <div>Loading videos...</div>;
    }

    if (message) {
        return <div>{message}</div>;
    }

    if (!videos || videos.length === 0) {
        return <div>No videos found.</div>;
    }

    const mainVideo = videos[selectedVideoIndex];

    return (
        <div className="youtube-area video-padding d-none d-sm-block">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {mainVideo && (
                            <div className="main-video" style={{ marginBottom: '20px' }}>
                                {renderVideo(mainVideo.url)}
                                <h3>{mainVideo.title || `Video ${selectedVideoIndex + 1}`}</h3>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row">
                    {videos.map((video, index) => (
                        <div
                            className="col-md-4 col-sm-6 col-12"
                            key={video._id} // Corrected key to video._id
                            onClick={() => handleThumbnailClick(index)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="thumbnail-video">
                                {renderThumbnail(video.url)}
                                <h4>{video.title || `Video ${index + 1}`}</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}


function WeeklyNews3({ newsData = [] }) {
    const newsToShow = newsData.slice(0, 5);

    return (
        <div className="weekly3-news-area pt-80 pb-130">
            <div className="container">
                <div className="weekly3-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="slider-wrapper">
                                <div className="row">
                                    <div className="col-lg-12">
                                    <div>
                            <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">Featured News</div>
                        </div>
                                        {newsToShow.length > 0 ? (
                                            <div className="weekly3-news-active dot-style d-flex">
                                                {newsToShow.map(news => (
                                                    <div className="weekly3-single" key={`weekly3-${news._id}`}>
                                                        <div className="weekly3-img">
                                                             <img src={`${API_BASE_URL}/${news.image}`} alt={news.title}/>
                                                        </div>
                                                        <div className="weekly3-caption">
                                                            <h4><Link to={`/news/${news._id}`}>{news.title}</Link></h4>
                                                            <p>{formatDate(news.date)}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="placeholder">No news to display in this section.</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BannerLast({ bannerAd }) {
    return (
        <div className="banner-area gray-bg pt-90 pb-90">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10">
                        {bannerAd ? (
                            <div className="banner-one">
                                 <a href={bannerAd.url} target="_blank" rel="noopener noreferrer">
                                     <img src={`${API_BASE_URL}/${bannerAd.image}`} alt={bannerAd.title}/>
                                 </a>
                            </div>
                        ) : (
                            <div className="banner-one placeholder">Advertisement Area</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}


function News({ latestNews = [], popularNews = [], categories = [] }) {
    const latestNewss = latestNews.slice(0, 4);
    const popularSidebar = popularNews.slice(0, 4);
    const tags = categories.slice(0, 12);

    return (
        <div className="container-fluid pb-4 pt-4 paddding">
            <div className="container paddding">
                <div className="row mx-0">
                    <div className="col-md-8 animate-box" data-animate-effect="fadeInLeft">
                        <div>
                            <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">Latest News</div>
                        </div>
                        {latestNewss.length > 0 ? latestNewss.map(news => (
                            <div className="row pb-4" key={`news-list-${news._id}`}>
                                <div className="col-md-5">
                                    <div className="fh5co_hover_news_img">
                                        <div className="fh5co_news_img">
                                            <img src={`${API_BASE_URL}/${news.image}`} alt={news.title}/>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="col-md-7 animate-box">
                                    <Link to={`/news/${news._id}`} className="fh5co_magna py-2">{news.title}</Link>
                                    <div className="fh5co_mini_time py-3">{news.category} - {formatDate(news.date)}</div>
                                    <div className="fh5co_consectetur">{news.description?.substring(0, 100)}{news.description?.length > 100 ? '...' : ''}</div>
                                </div>
                            </div>
                        )) : <div className="placeholder">No latest news found.</div>}
                    </div>
                    <div className="col-md-3 animate-box" data-animate-effect="fadeInRight">
                        {/* Tags */}
                        <div>
                            <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">Tags</div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="fh5co_tags_all">
                            {tags.length > 0 ? tags.map(cat => (
                                <Link key={cat._id} to={`/category/${cat.heading}`} className="fh5co_tagg">{cat.heading}</Link>
                            )) : <div className="placeholder">No tags found.</div>}
                        </div>

                        {/* Most Popular Sidebar */}
                        <div>
                            <div className="fh5co_heading fh5co_heading_border_bottom pt-3 py-2 mb-4">Most Popular</div>
                        </div>
                         {popularSidebar.length > 0 ? popularSidebar.map(news => (
                            <div className="row pb-3" key={`pop-sidebar-${news._id}`}>
                                <div className="col-5 align-self-center">
                                    <img src={`${API_BASE_URL}/${news.image}`} alt={news.title} className="fh5co_most_trading"/>
                                </div>
                                <div className="col-7 paddding">
                                    <div className="most_fh5co_treding_font"><Link to={`/news/${news._id}`}>{news.title}</Link></div>
                                    <div className="most_fh5co_treding_font_123">{formatDate(news.date)}</div>
                                </div>
                            </div>
                        )) : <div className="placeholder">No popular news found.</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

// function WeatherNews({ news = [], loading, error }) {
//     if (loading) return <div className="loading container paddding">Loading weather news...</div>;
//     if (error) return <div className="error container paddding">Error fetching weather news: {error}</div>;
//     if (!news.length) return <div className="container paddding">No weather news found.</div>;

//     const newsToShow = news.slice(0, 3); // Show first 3 weather articles

//     return (
//         <div className="container-fluid pb-4 pt-4 paddding gray-bg">
//             <div className="container paddding">
//                 <div className="row mx-0">
//                     <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
//                         <div>
//                             <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">Weather News</div>
//                         </div>
//                         {newsToShow.map((article, index) => (
//                             <div className="row pb-4" key={article.article_id || `weather-${index}`}> {/* Use unique key */}
//                                 <div className="col-md-5">
//                                     <div className="fh5co_hover_news_img">
//                                         <div className="fh5co_news_img">
//                                             <img src={article.image_url || placeholderImg} alt={article.title} />
//                                         </div>
//                                         <div></div>
//                                     </div>
//                                 </div>
//                                 <div className="col-md-7 animate-box">
//                                     <a href={article.link} target="_blank" rel="noopener noreferrer" className="fh5co_magna py-2">{article.title}</a>
//                                     <div className="fh5co_mini_time py-3">{article.source_id} - {formatDate(article.pubDate)}</div>
//                                     <div className="fh5co_consectetur">{article.description?.substring(0, 150)}{article.description?.length > 150 ? '...' : ''}</div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// --- Main Home Component ---

function Home() {
    // Data States
    const [marqueeHeadings, setMarqueeHeadings] = useState([]);
    const [carouselImages, setCarouselImages] = useState([]);
    const [allNews, setAllNews] = useState([]);
    const [videos, setVideos] = useState([]);
    const [categories, setCategories] = useState([]);
    const [advertisements, setAdvertisements] = useState([]);
    const [weatherNews, setWeatherNews] = useState([]);
    // --- Poll State ---
    const [latestPoll, setLatestPoll] = useState(null);
    const [loadingPoll, setLoadingPoll] = useState(true);
    const [errorPoll, setErrorPoll] = useState(null);

    // Loading/Error States
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [weatherNewsLoading, setWeatherNewsLoading] = useState(true);
    const [weatherNewsError, setWeatherNewsError] = useState(null);

    // Fetch Main Data
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const [
                    marqueeRes, carouselRes, newsRes, videoRes, categoryRes, adsRes
                ] = await Promise.all([
                    axios.get(`${API_BASE_URL}/marquee-headings`),
                    axios.get(`${API_BASE_URL}/carousel-images`),
                    axios.get(`${API_BASE_URL}/news`),
                    axios.get(`${API_BASE_URL}/videos`),
                    axios.get(`${API_BASE_URL}/newscategories`),
                    axios.get(`${API_BASE_URL}/advertisements`)
                ]);

                // Ensure responses are arrays before setting state
                setMarqueeHeadings(Array.isArray(marqueeRes.data) ? marqueeRes.data : []);
                setCarouselImages(Array.isArray(carouselRes.data) ? carouselRes.data : []);
                setAllNews(Array.isArray(newsRes.data) ? newsRes.data : []);
                setVideos(Array.isArray(videoRes.data) ? videoRes.data : []);
                setCategories(Array.isArray(categoryRes.data) ? categoryRes.data.filter(cat => cat.heading) : []);
                setAdvertisements(Array.isArray(adsRes.data) ? adsRes.data : []);

            } catch (err) {
                console.error("Error fetching main data:", err);
                setError(err.message || 'Failed to fetch data');
                // Set states to empty arrays on error to prevent filtering errors
                setMarqueeHeadings([]); setCarouselImages([]); setAllNews([]);
                setVideos([]); setCategories([]); setAdvertisements([]);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // Fetch Weather News Separately
    useEffect(() => {
        const fetchWeatherNewsData = async () => {
            setWeatherNewsLoading(true);
            setWeatherNewsError(null);
            try {
                const response = await axios.get(`${WEATHER_NEWS_API_URL}${WEATHER_NEWS_API_KEY}&q=weather&country=in&language=en`);
                setWeatherNews(Array.isArray(response.data?.results) ? response.data.results : []);
            } catch (err) {
                 console.error("Error fetching weather news:", err);
                 if (err.response?.data?.results?.message) {
                     setWeatherNewsError(`API Error: ${err.response.data.results.message}`);
                 } else {
                     setWeatherNewsError(err.message || 'Failed to fetch weather news');
                 }
                 setWeatherNews([]);
            } finally {
                setWeatherNewsLoading(false);
            }
        };
        fetchWeatherNewsData();
    }, []);

    // --- Fetch Latest Poll ---
      // --- Fetch Latest Poll ---
      useEffect(() => {
        const fetchLatestPoll = async () => {
            setLoadingPoll(true);
            setErrorPoll(null);
            try {
                // ***** CORRECTED ENDPOINT HERE *****
                const response = await axios.get(`${API_BASE_URL}/polls`);
                // ***********************************

                // Check if response.data is truthy (it could be null if no polls exist)
                // No need to check for object type, null is valid.
                if (response.data) {
                     setLatestPoll(response.data); // Set the single poll object or null
                } else {
                    setLatestPoll(null); // Explicitly set to null if backend returns null/empty
                    // console.log("No latest poll found from API."); // Optional log
                }

            } catch (err) {
                console.error("Error fetching latest poll:", err);
                // Check if the error is specifically a 404 Not Found
                if (err.response && err.response.status === 404) {
                    setErrorPoll("No polls found."); // More specific message for 404
                } else {
                    setErrorPoll("Could not load poll."); // Generic error
                }
                setLatestPoll(null); // Ensure poll is null on error
            } finally {
                setLoadingPoll(false);
            }
        };
        fetchLatestPoll();
    }, []); // Empty dependency array runs once on mount


    // Filter Data for Components - Moved inside render or useMemo if complex
    // Simple filtering can stay here, ensures it runs after state updates
    const featuredNews = loading ? [] : allNews.filter(news => news.featured).sort((a, b) => new Date(b.date) - new Date(a.date));
    const popularNews = loading ? [] : allNews.filter(news => news.popular || news.mostPopular).sort((a, b) => new Date(b.date) - new Date(a.date));
    const trendingNews = loading ? [] : allNews.filter(news => news.latestNews).sort((a, b) => new Date(b.date) - new Date(a.date));
    const recentNews = loading ? [] : allNews.filter(news => news.recentPosts).sort((a, b) => new Date(b.date) - new Date(a.date));

    // Ad filtering
    const bannerAd1 = loading ? null : advertisements.find(ad => ad.bannerTop);
    const bannerAd2 = loading ? null : advertisements.find(ad => ad.rightLeft);
    const bannerAd3 = loading ? null : advertisements.find(ad => ad.bannerBottom);

    // Initial Loading State
    if (loading) {
        // You can return a more sophisticated loading skeleton here
        return (
            <div className="loading-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <h2>Loading News...</h2>
            </div>
        );
    }

    // Error State
    if (error) {
        return (
             <div className="error-container" style={{ padding: '20px', color: 'red', textAlign: 'center' }}>
                <h2>Error Loading Data</h2>
                <p>{error}</p>
                <p>Please try refreshing the page.</p>
            </div>
        );
    }

    // Render Main Content
    return (
        <>
            <Navbar/>
            <main>
                <Marquee headings={marqueeHeadings} />
                <TrendingArea carouselImages={carouselImages} featuredNews={featuredNews} />
                 {/* Pass poll props to WhatsNew */}
                 <WhatsNew
                    categories={categories}
                    allNews={allNews}
                    bannerAd={bannerAd1}
                    latestPoll={latestPoll} // <-- Pass Poll Data
                    loadingPoll={loadingPoll} // <-- Pass Loading State
                    errorPoll={errorPoll}     // <-- Pass Error State
                />
                <WeeklyNews popularNews={popularNews} bannerAd={bannerAd2} />
                <RecentArticles trendingNews={trendingNews} />
                <WeeklyNews3 newsData={featuredNews} /> {/* Reusing featured news here */}
                <BannerLast bannerAd={bannerAd3} />
                {/* Pass correct data to News component */}
                <News latestNews={trendingNews} popularNews={popularNews} categories={categories} />
                <VideoArea videos={videos} />
            </main>
            <Footer/>
        </>
    );
}

export default Home;