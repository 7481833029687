import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // If you need links later
import Dashboard from "../Dashboard/Dashoboard";
import { FaComment, FaTrashAlt } from 'react-icons/fa'; // Import icons
import AdminFooter from '../Footer/Footeradmin';
// Assuming common styles are in Addnews.css or create ListPolls.css
import '../Addnews/Addnews.css'; // Keep if it has relevant styles

const ListPolls = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

    // State for listing
    const [polls, setPolls] = useState([]);
    const [loading, setLoading] = useState(false); // For initial fetch
    const [error, setError] = useState(''); // Use specific error state
    const [message, setMessage] = useState(''); // For success/info messages

    // --- NEW: State for Toggling ---
    const [togglingPollId, setTogglingPollId] = useState(null); // ID of poll being toggled

    // Fetch polls on component mount
    useEffect(() => {
        fetchPolls();
    }, []);

    const fetchPolls = async () => {
        setLoading(true);
        setError('');
        setMessage('');
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError("Authentication token not found. Please log in.");
                setLoading(false);
                return;
            }

            const response = await axios.get(`${baseUrl}/polls`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            let pollsData = [];
            if (response.data && Array.isArray(response.data)) {
                 pollsData = response.data;
           } else if (response.data?.data && Array.isArray(response.data.data)){
                 pollsData = response.data.data;
           } else {
                console.warn("Received non-array data for polls:", response.data);
                setError("Received unexpected data format for polls.");
           }

           // Normalize boolean fields
           const normalizedPolls = pollsData.map(poll => ({
               ...poll,
               website1: poll.website1 === true || poll.website1 === 'true' || poll.website1 === 1,
               website2: poll.website2 === true || poll.website2 === 'true' || poll.website2 === 1,
           }));
           setPolls(normalizedPolls);

        } catch (err) {
            console.error('Error fetching polls:', err);
            const errorMsg = err.response?.data?.message || err.message;
            const status = err.response?.status;
             if (status === 401 || status === 403) {
                setError("Unauthorized to fetch polls. Please log in again.");
            } else {
                setError(`Error fetching polls: ${errorMsg}`);
            }
        } finally {
            setLoading(false);
        }
    };

    // --- Deletion ---
    const handleDeletePoll = async (id) => {
        // Prevent delete while toggling status
        if (togglingPollId === id) return;

        if (window.confirm('Are you sure you want to delete this poll?')) {
            setMessage('');
            setError('');
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setError("Authentication token not found. Cannot delete poll.");
                    return;
                }

                const response = await axios.delete(`${baseUrl}/polls/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setMessage(response.data.message || 'Poll deleted successfully!');
                setPolls(prevPolls => prevPolls.filter(poll => poll._id !== id));

            } catch (err) {
                 console.error('Error deleting poll:', err);
                 const errorMsg = err.response?.data?.message || err.message;
                 const status = err.response?.status;
                 if (status === 401 || status === 403) {
                    setError("Unauthorized to delete poll. Please log in again.");
                } else {
                    setError(`Error deleting poll: ${errorMsg}`);
                }
            }
        }
    };

    // --- NEW: Handle Status Toggle ---
    const handleToggleStatus = async (id, fieldKey, currentValue, currentPollData) => {
        if (togglingPollId === id) return; // Prevent rapid clicks

        setTogglingPollId(id);
        setMessage(''); // Clear previous messages
        setError('');

        const newValue = !currentValue;

        try {
            // Prepare payload - Assuming JSON PUT/PATCH
            // Send fields required by the backend for an update
            const payload = {
                question: currentPollData.question, // Include question if needed
                options: currentPollData.options,   // Include options if needed
                // Update the specific field, keep others as they are
                website1: fieldKey === 'website1' ? newValue : currentPollData.website1,
                website2: fieldKey === 'website2' ? newValue : currentPollData.website2,
                // If backend handles partial updates (PATCH), payload could be just: { [fieldKey]: newValue }
            };

            const token = localStorage.getItem('token');
            if (!token) {
                setError("Authentication token not found. Cannot update poll status.");
                 setTogglingPollId(null); // Reset toggle state
                return;
            }

            // *** VERIFY the UPDATE endpoint and METHOD (PUT/PATCH) for polls ***
            await axios.put(`${baseUrl}/polls/${id}`, payload, { // OR axios.patch
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json' // Sending JSON
                }
            });

            // Optimistic UI Update
            setPolls(prevPolls =>
                prevPolls.map(poll =>
                    poll._id === id
                        ? { ...poll, [fieldKey]: newValue } // Update the specific field
                        : poll
                )
            );
             // Optional: setMessage(`${fieldKey} status updated.`);

        } catch (err) {
            console.error(`Error toggling ${fieldKey}:`, err);
            const errorMsg = err.response?.data?.message || err.message;
            const status = err.response?.status;
            if (status === 401 || status === 403) {
                setError(`Unauthorized to update poll status.`);
            } else {
                 setError(`Error updating ${fieldKey}: ${errorMsg}`);
            }
            // Don't revert UI on error, just show the message
        } finally {
            setTogglingPollId(null); // Reset loading state for this item
        }
    };


    // --- Comment Handling (Placeholder) ---
    const handleViewComments = (pollId) => {
        alert(`Implement logic to view comments for poll ID: ${pollId}`);
        console.log(`View comments for poll ID: ${pollId}`);
    };

    return (
        <>
            <Dashboard />
            <div className="containerrs list-polls-container list-news-container" style={{ marginTop: "50px" }}>
                <h1>Manage Polls</h1>

                {message && <div className="message success-message">{message}</div>}
                {error && <div className="message error-message">{error}</div>}

                <div className="poll-list-section">
                    {loading ? (
                        <div className="loading-indicator">Loading polls...</div>
                    ) : polls.length === 0 && !error ? (
                        <p>No polls found.</p>
                    ) : !error ? (
                        <div className="table-responsive">
                            <table className="polls-table list-polls-table news-list-table">
                                <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th>Options (Votes)</th>
                                        <th>Comments</th>
                                        {/* Centered Status Headers */}
                                        <th style={{ textAlign: 'center' }}>Web 1</th>
                                        <th style={{ textAlign: 'center' }}>Web 2</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {polls.map((poll) => (
                                        <tr key={poll._id}>
                                            <td className="question-cell" title={poll.question}>
                                                 {poll.question ? poll.question.substring(0, 50) + (poll.question.length > 50 ? '...' : '') : 'N/A'}
                                            </td>
                                            <td className="options-cell">
                                                {poll.options && Array.isArray(poll.options) ? (
                                                    <ul className="options-list-display">
                                                        {poll.options.map((opt, idx) => (
                                                            <li key={opt._id || `opt-${idx}`}>
                                                                {opt.text ? opt.text.substring(0, 25) + (opt.text.length > 25 ? '...' : '') : ''} ({opt.votes !== undefined ? opt.votes : 'N/A'})
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <span>(Invalid Options)</span>
                                                )}
                                            </td>
                                            <td className="comment-cell">
                                                <button
                                                    onClick={() => handleViewComments(poll._id)}
                                                    className="comment-button icon-button"
                                                    title={`View Comments (${poll.comments?.length || 0})`}
                                                    disabled={togglingPollId === poll._id} // Disable while toggling status
                                                >
                                                    <FaComment />
                                                    <span className="comment-count">({poll.comments?.length || 0})</span>
                                                </button>
                                            </td>

                                            {/* Website Status Toggles */}
                                            <td className="status-cell" style={{ textAlign: 'center' }}>
                                                <span
                                                    className={`status-toggle ${togglingPollId === poll._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(poll._id, 'website1', poll.website1, poll)}
                                                    title={`Toggle Website 1 (${poll.website1 ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingPollId === poll._id ? '...' : (poll.website1 ? '✅' : '❌')}
                                                </span>
                                            </td>
                                            <td className="status-cell" style={{ textAlign: 'center' }}>
                                                 <span
                                                    className={`status-toggle ${togglingPollId === poll._id ? 'loading' : ''}`}
                                                    onClick={() => handleToggleStatus(poll._id, 'website2', poll.website2, poll)}
                                                    title={`Toggle Website 2 (${poll.website2 ? 'Active' : 'Inactive'})`}
                                                >
                                                    {togglingPollId === poll._id ? '...' : (poll.website2 ? '✅' : '❌')}
                                                </span>
                                            </td>

                                            <td className="action-cell">
                                                <button
                                                    className="delete-button icon-button"
                                                    onClick={() => handleDeletePoll(poll._id)}
                                                    title="Delete Poll"
                                                    disabled={togglingPollId === poll._id} // Disable delete while toggling status
                                                >
                                                   <FaTrashAlt />
                                                </button>
                                                {/* Add Edit button later if needed */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : null}
                </div>
            </div>
            <AdminFooter />
        </>
    );
};

export default ListPolls;