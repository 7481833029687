import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';
// import "../Addnewspaper/Addnewspaper.css"; // Your original CSS import

const ManageYouTubeVideos = () => {
    // --- Configuration ---
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    // Token key is still needed for DELETE operation
    const tokenKey = 'adminToken';

    // --- State for Adding Videos ---
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);
    const [addMessage, setAddMessage] = useState('');
    const [addError, setAddError] = useState('');
    const [isAdding, setIsAdding] = useState(false);

    // --- State for Listing/Deleting Videos ---
    const [videos, setVideos] = useState([]);
    const [listMessage, setListMessage] = useState('');
    const [listError, setListError] = useState('');
    const [isLoadingList, setIsLoadingList] = useState(false);

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        setIsLoadingList(true);
        setListMessage('');
        setListError('');
        try {
            const response = await axios.get(`${baseUrl}/videos`);
            setVideos(response.data || []);
        } catch (error) {
            console.error("Error fetching videos:", error);
            setListError(`Error fetching videos: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsLoadingList(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        switch (name) {
            case 'title': setTitle(value); break;
            case 'url': setUrl(value); break;
            case 'website1': setWebsite1(checked); break;
            case 'website2': setWebsite2(checked); break;
            default: break;
        }
    };

    // --- Handle Add Video Submission (Authentication Removed) ---
    const handleAddVideo = async () => {
        setAddMessage('');
        setAddError('');

        if (!title.trim()) { setAddError('Video Title is required.'); return; }
        if (!url.trim()) { setAddError('Video URL is required.'); return; }
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/;
        if (!youtubeRegex.test(url)) { setAddError('Please enter a valid YouTube video URL.'); return; }

        setIsAdding(true);

        const newVideoData = {
            title: title.trim(),
            url: url.trim(),
            website1: String(website1),
            website2: String(website2),
        };

        try {
            // --- Authentication Removed ---
            // const token = localStorage.getItem(tokenKey); // No longer getting token
            // if (!token) throw new Error("Admin token not found."); // No longer checking for token

            // --- Make the POST request without the Authorization header ---
            const response = await axios.post(`${baseUrl}/upload-video`, newVideoData, {
                headers: {
                    // 'Authorization': `Bearer ${token}`, // REMOVED Authorization header
                    'Content-Type': 'application/json',
                }
            });
            // --- End Authentication Removal ---

            setAddMessage(response.data.message || 'Video added successfully!');
            setTitle('');
            setUrl('');
            setWebsite1(false);
            setWebsite2(false);
            fetchVideos(); // Refresh list

        } catch (error) {
            console.error("Error adding video:", error);
            // Check if the error is specifically an auth error from backend (if backend still requires it)
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                 setAddError(`Error adding video: Authentication failed. Please ensure the backend route is public or you are logged in.`);
            } else {
                 setAddError(`Error adding video: ${error.response?.data?.message || error.message}`);
            }
        } finally {
            setIsAdding(false);
        }
    };

     // --- Handle Delete Video (Keeps Authentication) ---
     const handleDeleteVideo = async (id) => {
         if (!window.confirm('Are you sure you want to delete this video link?')) return;

         setListMessage('');
         setListError('');
         setIsLoadingList(true);

         try {
             // --- Authentication is STILL USED for Delete ---
             const token = localStorage.getItem(tokenKey);
             if (!token) {
                 // Provide specific feedback if token is missing for delete
                 setListError("Cannot delete: Admin token not found. Please log in.");
                 setIsLoadingList(false);
                 return;
             };
             // --- End Authentication Check for Delete ---

             const response = await axios.delete(`${baseUrl}/videos/${id}`, {
                 headers: { 'Authorization': `Bearer ${token}` } // Send token for delete
             });

             setListMessage(response.data.message || 'Video deleted successfully!');
             fetchVideos(); // Refresh list

         } catch (error) {
             console.error("Error deleting video:", error);
             setListError(`Error deleting video: ${error.response?.data?.message || error.message}`);
             setIsLoadingList(false);
         }
     };

    const getYouTubeID = (youtubeUrl) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = youtubeUrl.match(regExp);
        return (match && match[7].length === 11) ? match[7] : null;
    };

    const displayMessage = addMessage || listMessage;
    const displayError = addError || listError;

    return (
        <>
            <Dashboard />
            <div className="containerrs" style={{ marginTop: "50px" }}>
                <h2>Manage YouTube Video Links</h2>

                {/* Add Video Form */}
                <div className="poll-form">
                    {/* Inputs for title, url, website1, website2 */}
                    <label htmlFor="title">Video Title: *</label>
                    <input type="text" id="title" name="title" value={title} onChange={handleInputChange} placeholder="Enter video title" required disabled={isAdding}/>
                    <label htmlFor="url" style={{ marginTop: '10px' }}>YouTube Video URL: *</label>
                    <input type="url" id="url" name="url" value={url} onChange={handleInputChange} placeholder="e.g., https://www.youtube.com/watch?v=..." required disabled={isAdding} />
                    <div style={{ margin: '15px 0', display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <span>Assign to Websites:</span>
                        <label htmlFor="website1" style={{ marginRight: '10px', cursor: 'pointer' }}>
                            <input type="checkbox" id="website1" name="website1" checked={website1} onChange={handleInputChange} disabled={isAdding} style={{ marginRight: '5px' }} /> Website 1
                        </label>
                        <label htmlFor="website2" style={{ cursor: 'pointer' }}>
                            <input type="checkbox" id="website2" name="website2" checked={website2} onChange={handleInputChange} disabled={isAdding} style={{ marginRight: '5px' }} /> Website 2
                        </label>
                    </div>
                    <button onClick={handleAddVideo} disabled={isAdding}>
                        {isAdding ? 'Adding Video...' : 'Add YouTube Video'}
                    </button>
                </div>

                {/* Display Messages/Errors */}
                {displayMessage && <p className="success-message" style={{color: 'green'}}>{displayMessage}</p>}
                {displayError && <p className="error-message" style={{color: 'red'}}>{displayError}</p>}

                

            </div> {/* End containerrs */}
            <AdminFooter />
        </>
    );
};

export default ManageYouTubeVideos;