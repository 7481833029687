// Example: src/api/fetchWeather.js

import axios from 'axios';

// Define constants usable by both functions
const BASE_URL = 'https://api.openweathermap.org/data/2.5';
const API_KEY = 'f33a484cf794d08d0148764789aaba32'; // Your API Key

// Your existing function (can optionally add improved error handling)
export const fetchWeather = async (query) => {
    try {
        const { data } = await axios.get(`${BASE_URL}/weather`, { // Use BASE_URL
            params: {
                q: query,
                units: 'metric', // Ensure units are consistent
                APPID: API_KEY,
            }
        });
        return data;
    } catch (error) {
        // Optional: Add specific error handling/logging
        console.error("Error fetching current weather:", error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 404) {
             throw new Error(`City not found: ${query}`); // Throw specific error for 404
        }
        // Re-throw a generic error or the original error for the component to catch
        throw new Error('Failed to fetch current weather data.');
    }
};

// --- NEW Function to fetch 5-day forecast ---
export const fetchForecast = async (query) => {
    try {
        // Use the /forecast endpoint
        const { data } = await axios.get(`${BASE_URL}/forecast`, {
            params: {
                q: query,           // City name
                units: 'metric',    // Keep units consistent (metric or imperial)
                APPID: API_KEY,     // Your API key
                // Optional: 'cnt' parameter can limit results, but default gives 5 days / 3 hours (40 results)
            }
        });
        // Return the raw forecast data (list of 3-hour intervals)
        // The component will process this into daily summaries
        return data;
    } catch (error) {
        // Optional: Add specific error handling/logging
        console.error("Error fetching forecast data:", error.response ? error.response.data : error.message);
         if (error.response && error.response.status === 404) {
             // Usually fetchWeather fails first for 404, but good to handle just in case
             throw new Error(`Forecast data not found for: ${query}`);
        }
        // Re-throw a generic error or the original error for the component to catch
        throw new Error('Failed to fetch forecast data.');
    }
};