import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios'; // Keep for reporter lookup
import logo from './logo.png'; // Adjust path if needed

import './Navbar.css'; // Ensure CSS is linked

// --- Configuration ---
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Keep for reporter lookup

// --- NEW Static Category Data ---
// Define the categories directly in the component
const staticCategories = [
    { name: "देश-विदेश", path: `/category/${encodeURIComponent("देश-विदेश")}` },
    { name: "खास मुलाकात", path: `/category/${encodeURIComponent("खास मुलाकात")}` },
    { name: "राजनीति", path: `/category/${encodeURIComponent("राजनीति")}` },
    { name: "व्यापार जगत", path: `/category/${encodeURIComponent("व्यापार जगत")}` },
    { name: "ज्योतिष", path: `/category/${encodeURIComponent("ज्योतिष")}` },
    { name: "जोधपुर विशेष", path: `/category/${encodeURIComponent("जोधपुर विशेष")}` },
    { name: "RTI (सुचना का अधिकार)", path: `/category/${encodeURIComponent("RTI (सुचना का अधिकार)")}` },
    { name: "पर्यावरण संबंधित", path: `/category/${encodeURIComponent("पर्यावरण संबंधित")}` },
    { name: "धार्मिक - समाचार", path: `/category/${encodeURIComponent("धार्मिक - समाचार")}` }, // Corrected duplicate name
    { name: "अपराध", path: `/category/${encodeURIComponent("अपराध")}` },
    { name: "कोरोना अपडेट", path: `/category/${encodeURIComponent("कोरोना अपडेट")}` },
    { name: "अजब - गजब", path: `/category/${encodeURIComponent("अजब - गजब")}` },
    { name: "जॉब एंड कैरियर", path: `/category/${encodeURIComponent("जॉब एंड कैरियर")}` },
    { name: "तीज - त्यौहार", path: `/category/${encodeURIComponent("तीज - त्यौहार")}` },
    { name: "इतिहास व सत्यकथा", path: `/category/${encodeURIComponent("इतिहास व सत्यकथा")}` },
    { name: "आयुर्वेदा", path: `/category/${encodeURIComponent("आयुर्वेदा")}` },
    { name: "चालीसा व आरती संग्रह", path: `/category/${encodeURIComponent("चालीसा व आरती संग्रह")}` },
    { name: "अंतरराष्ट्रीय", path: `/category/${encodeURIComponent("अंतरराष्ट्रीय")}` }, // Trimmed space
    { name: "राष्ट्रीय", path: `/category/${encodeURIComponent("राष्ट्रीय")}` },     // Trimmed space
    { name: "भ्रष्टाचार के खिलाफ मुहिम", path: `/category/${encodeURIComponent("भ्रष्टाचार के खिलाफ मुहिम")}` },
    { name: "शाही रसोई", path: `/category/${encodeURIComponent("शाही रसोई")}` },      // Trimmed space
    { name: "लेख/ Article", path: `/category/${encodeURIComponent("लेख/ Article")}` }
];
// --- END Static Category Data ---


export default function Navbar() {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    // --- Existing States (Keep as is) ---
    const [showUserPopup, setShowUserPopup] = useState(false);
    const [userId, setUserId] = useState('');
    const [reporterData, setReporterData] = useState(null);
    const [loadingReporter, setLoadingReporter] = useState(false);
    const [reporterMessage, setReporterMessage] = useState('');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const headerRef = useRef(null);
    const popupRef = useRef(null);

    // --- REMOVED States for Dynamic Categories ---
    // const [navCategories, setNavCategories] = useState([]);
    // const [isLoadingCategories, setIsLoadingCategories] = useState(true);
    // const [categoryError, setCategoryError] = useState(null);

    // --- REMOVED Fetch and Structure Categories Effect ---
    // useEffect(() => { ... fetchAndStructureCategories ... }, []);

    // --- Sticky Header Logic --- (Keep as is)
    useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current) {
                setIsSticky(window.scrollY > headerRef.current.offsetTop);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // --- Mobile Menu Toggle & Body Class --- (Keep as is)
    const toggleMobileMenu = () => setIsMobileMenuOpen(prev => !prev);
    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.classList.add('mobile-menu-active');
        } else {
            document.body.classList.remove('mobile-menu-active');
        }
        // Cleanup function to remove the class if the component unmounts while menu is open
        return () => document.body.classList.remove('mobile-menu-active');
    }, [isMobileMenuOpen]);

    // --- Reporter Popup Handlers & Click Outside --- (Keep as is)
    const handleUserIconClick = (event) => {
        event.stopPropagation(); // Prevent click from immediately closing the popup
        setShowUserPopup(true);
        // Clear previous search when opening
        setUserId('');
        setReporterData(null);
        setReporterMessage('');
    };
    const handleClosePopup = () => setShowUserPopup(false);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                // Check if the click was on the user icon itself to prevent immediate closing
                const userIcon = document.querySelector('.newsnav-user-icon'); // Adjust selector if needed
                if (!userIcon || !userIcon.contains(event.target)) {
                   handleClosePopup();
                }
            }
        };
        if (showUserPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showUserPopup]);

    // --- Reporter Search --- (Keep as is)
    const handleSearch = async () => {
        if (!userId.trim()) {
            setReporterMessage('Please enter a Reporter ID.');
            setReporterData(null); return;
        }
        setLoadingReporter(true);
        setReporterData(null); setReporterMessage('');
        try {
             const response = await axios.get(`${API_BASE_URL}/reporters/lookup/${userId.trim()}`);
             if (response.data) { setReporterData(response.data); }
        } catch (error) {
            setReporterData(null);
            if (error.response && error.response.status === 404) {
                setReporterMessage(`Reporter ID "${userId}" not found.`);
            } else {
                setReporterMessage(`Error fetching reporter. Please try again.`);
                console.error('Error fetching reporter:', error);
            }
        } finally { setLoadingReporter(false); }
    };

    const handleMobileLinkClick = () => setIsMobileMenuOpen(false);

    // --- Image URL Helper --- (Keep as is)
    const getImageUrl = (imagePath) => {
        if (!imagePath) return null; // Handle cases where imagePath might be missing
        // Check if it's an absolute URL (starts with http or https)
        if (/^https?:\/\//.test(imagePath)) {
            return imagePath;
        }
        // Otherwise, assume it's a relative path and prepend the base URL
        // Ensure no double slashes if imagePath starts with /
        const path = imagePath.startsWith('/') ? imagePath.substring(1) : imagePath;
        return `${API_BASE_URL}/${path}`;
    }

    return (
        <header id="newsnav-header" className={`newsnav-header ${isSticky ? 'newsnav-sticky' : ''}`} ref={headerRef}>
            {/* ----- Top Bar ----- (Keep as is) */}
            <div className="newsnav-top-bar">
                <div className="container newsnav-top-content">
                    <div className="newsnav-date-time">
                        {/* Placeholder for date/time */}
                        <span>{new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
                    </div>
                    <div className="newsnav-social-user">
                        <div className="newsnav-social-icons">
                            <a href="https://www.facebook.com/29news.live" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://twitter.com/29NEWS2?s=08" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                            <a href="https://www.instagram.com/29newslive/" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                            <a href="https://www.youtube.com/c/29NEWS29news" target='_blank' rel="noopener noreferrer" aria-label="YouTube" title="YouTube"><i className="fab fa-youtube"></i></a>
                        </div>
                         {/* User Icon Trigger */}
                         <div className="newsnav-user-icon" onClick={handleUserIconClick} role="button" tabIndex="0" aria-label="Reporter Lookup">
                            <i className="fas fa-user"></i>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----- Main Navigation ----- */}
            <nav className="newsnav-main">
                <div className="container newsnav-main-content">
                    <div className="newsnav-logo">
                        <Link to="/"><img src={logo} alt="24Live News Logo" /></Link>
                    </div>

                    <div className={`newsnav-menu-desktop ${isMobileMenuOpen ? 'mobile-fade-out' : ''}`}>
                        <ul className="newsnav-navigation">
                            <li><NavLink exact="true" to="/" className={({isActive}) => isActive ? "active" : ""}>Home</NavLink></li>
                            {/* <li><NavLink to="/gallery" className={({isActive}) => isActive ? "active" : ""}>Gallery</NavLink></li> */}

                            {/* --- STATIC CATEGORY DROPDOWN --- */}
                            <li className="newsnav-dropdown">
                                {/* Main Category Link - doesn't navigate, just toggles dropdown via CSS :hover */}
                                <a href="#category-menu" onClick={(e) => e.preventDefault()}>
                                    Category <i className="fas fa-chevron-down newsnav-dropdown-icon"></i>
                                </a>

                                <ul className="newsnav-submenu newsnav-submenu-grid">
                                    {/* Map directly over the staticCategories array */}
                                    {staticCategories.map((category) => (
                                        <li key={category.path}> {/* Using path as key */}
                                            <NavLink
                                                to={category.path}
                                                className={({ isActive }) => `category-link ${isActive ? "active" : ""}`}
                                            >
                                                {category.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {/* --- END STATIC CATEGORY DROPDOWN --- */}

                            <li><NavLink to="/weather" className={({isActive}) => isActive ? "active" : ""}>Weather</NavLink></li>
                            <li><NavLink to="/epaper" className={({isActive}) => isActive ? "active" : ""}>Latest Newspaper</NavLink></li>
                            <li><NavLink to="/contact" className={({isActive}) => isActive ? "active" : ""}>Contact</NavLink></li>
                        </ul>
                    </div>

                    {/* Mobile Toggle Button (Keep as is) */}
                    <button className={`newsnav-mobile-toggle ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu} aria-label="Toggle Menu" aria-expanded={isMobileMenuOpen} aria-controls="newsnav-mobile-menu-list">
                        <span></span><span></span><span></span>
                    </button>
                </div>
            </nav>

            {/* ----- Mobile Menu ----- */}
            <div id="newsnav-mobile-menu-list" className={`newsnav-mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li><NavLink exact="true" to="/" className={({isActive}) => isActive ? "active" : ""} onClick={handleMobileLinkClick}>Home</NavLink></li>
                    {/* <li><NavLink to="/gallery" className={({isActive}) => isActive ? "active" : ""} onClick={handleMobileLinkClick}>Gallery</NavLink></li> */}

                    {/* --- STATIC MOBILE CATEGORIES --- */}
                    <li className="mobile-category-header">Categories</li>
                    {/* Map directly over the staticCategories array */}
                    {staticCategories.map((category) => (
                         <li key={category.path}> {/* Using path as key */}
                             <NavLink
                                 to={category.path}
                                 className={({ isActive }) => `mobile-category-link ${isActive ? "active" : ""}`} // Add specific mobile class if needed
                                 onClick={handleMobileLinkClick}
                             >
                                 {category.name}
                             </NavLink>
                         </li>
                     ))}
                     {/* --- END STATIC MOBILE CATEGORIES --- */}

             
                    <li><NavLink to="/contact" className={({isActive}) => isActive ? "active" : ""} onClick={handleMobileLinkClick}>Contact</NavLink></li>
                </ul>
                {/* Mobile Extras (Keep as is) */}
                <div className="newsnav-mobile-extras">
                    {/* <div className="newsnav-mobile-social">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                    </div> */}
                     {/* User Icon Trigger in Mobile */}
                     {/* <div className="newsnav-mobile-user-icon" onClick={(e) => { handleMobileLinkClick(); handleUserIconClick(e); }} role="button" tabIndex="0" aria-label="Reporter Lookup">
                        <i className="fas fa-user"></i> <span style={{marginLeft: '5px'}}>Reporter Lookup</span>
                    </div> */}
                </div>
            </div>
            {isMobileMenuOpen && <div className="newsnav-mobile-overlay" onClick={toggleMobileMenu}></div>}

            {/* ----- User ID Lookup Popup ----- (Keep as is) */}
            {showUserPopup && (
                <div className="newsnav-user-popup-overlay">
                     <div className="newsnav-user-popup user-popup" ref={popupRef}>
                         <div className="newsnav-user-popup-content user-popup-content">
                             <button className="newsnav-close-button close-button" onClick={handleClosePopup} aria-label="Close">×</button>
                             <h3>Reporter Lookup</h3>
                             <p className="newsnav-popup-instructions">Enter the Reporter's unique ID</p>
                             <div className="newsnav-input-group input-group">
                                 <input
                                     type="text"
                                     placeholder="Enter Reporter ID (e.g., REP001)"
                                     value={userId}
                                     onChange={(e) => setUserId(e.target.value)}
                                     aria-label="Reporter ID Input"
                                     onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                 />
                                 <button onClick={handleSearch} disabled={loadingReporter || !userId.trim()}>
                                     {loadingReporter ? <span className="newsnav-spinner"></span> : <i className="fa fa-search"></i>}
                                 </button>
                             </div>

                             <div className="newsnav-results-area">
                                 {!loadingReporter && reporterMessage && !reporterData && (
                                     <div className="newsnav-popup-message error">
                                         {reporterMessage}
                                     </div>
                                 )}
                                 {loadingReporter && <div className="newsnav-popup-loading">Searching... <span className="newsnav-spinner-small"></span></div>}

                                 {!loadingReporter && reporterData && (
                                     <div className="newsnav-user-data user-data animate-fade-in">
                                         <h4>Reporter Details</h4>
                                        {reporterData.photo && (
                                            <img
                                            src={`${baseUrl}/${reporterData.photo}`} 
                                                alt={`${reporterData.name}`}
                                                className="newsnav-reporter-image"
                                            />
                                        )}
                                        <p><strong>ID:</strong> {reporterData.userId}</p>
                                        <p><strong>Name:</strong> {reporterData.name}</p>
                                        {/* Add other fields as needed */}
                                        {/* Example: <p><strong>Email:</strong> {reporterData.email}</p> */}
                                        <p><strong>Role:</strong> <span className={`newsnav-status ${reporterData.status === 'active' ? 'active' : 'inactive'}`}>{reporterData.post}</span></p>
                                        {/* You might want to add a link to their profile page if available */}
                                        {/* <Link to={`/reporter/${reporterData.reporterId}`}>View Profile</Link> */}
                                     </div>
                                 )}
                             </div>
                         </div>
                     </div>
                 </div>
            )}
        </header>
    );
}