import React, { useState } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import '../Addnews/Addnews.css'; // Reuse the CSS or create a new one
import AdminFooter from '../Footer/Footeradmin';

const AddAdvertise = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [url, setUrl] = useState('');
    const [viewSide, setViewSide] = useState('Single'); // Set default value to 'Single'
    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);
    const [message, setMessage] = useState('');

    const [rightLeft, setRightLeft] = useState(false);
    const [bannerTop, setBannerTop] = useState(false);
    const [bannerBottom, setBannerBottom] = useState(false);
    const [topBottom, setTopBottom] = useState(false);
    const [categoryPage, setCategoryPage] = useState(false);
    const [detailsPage, setDetailsPage] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        if (image) {
            formData.append('image', image);
        }
        formData.append('url', url);
        formData.append('viewSide', viewSide);
        formData.append('website1', website1);
        formData.append('website2', website2);

        formData.append('rightLeft', rightLeft); //Corrected variable names
        formData.append('bannerTop', bannerTop);
        formData.append('bannerBottom', bannerBottom);
        formData.append('topBottom', topBottom);
        formData.append('categoryPage', categoryPage);
        formData.append('detailsPage', detailsPage);

        try {
            const response = await axios.post(`${baseUrl}/advertisements`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage(response.data.message);

            setTitle('');
            setImage(null);
            setUrl('');
            setViewSide('Single');
            setWebsite1(false);
            setWebsite2(false);

            setRightLeft(false); //Corrected variable names
            setBannerTop(false);
            setBannerBottom(false);
            setTopBottom(false);
            setCategoryPage(false);
            setDetailsPage(false);


        } catch (error) {
            console.error("Error adding advertisement:", error);
            setMessage('Error adding advertisement: ' + error.message);
        }
    };

    return (
        <>
            <Dashboard />
            <div className="containerrs" style={{ marginTop: "50px" }}>
                <h1>Add New Advertisement</h1>
                {message && <div className="message_new">{message}</div>}

                <form onSubmit={handleSubmit} encType="multipart/form-data" className="news_form_new">
                    <div className="form_group_new category_title_image_container_new">
                        <label htmlFor="title" className="label_new">Title:</label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />

                        <label htmlFor="image" className="label_new">Image:</label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            onChange={(e) => setImage(e.target.files[0])}
                            accept="image/*"
                            required
                        />
                         <label htmlFor="url" className="label_new">URL:</label>
                        <input
                            type="text"
                            id="url"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form_group_new view_side_container_new">
                        <label className="label_new">Type Advertise:</label>
                        <label className="label_new">
                            <input
                                style={{ marginBottom: "10px" }}
                                type="radio"
                                name="viewSide"
                                value="Single" // Corrected values
                                checked={viewSide === 'Single'}
                                onChange={(e) => setViewSide(e.target.value)} // Use e.target.value
                            />
                           Single
                        </label>
                        <label className="label_new">
                            <input
                                style={{ marginBottom: "10px" }}
                                type="radio"
                                name="viewSide"
                                value="Double" //Corrected values
                                checked={viewSide === 'Double'}
                                onChange={(e) => setViewSide(e.target.value)} // Use e.target.value
                            />
                          Double
                        </label>
                    </div>


                    <div className="form_group_new website_container_new">
                        <label className="label_new">
                            Website 1:
                            <input
                                style={{ marginBottom: "4px" }}
                                type="checkbox"
                                name="website1"
                                checked={website1}
                                onChange={(e) => setWebsite1(e.target.checked)}
                            />
                        </label>

                        <label className="label_new">
                            Website 2:
                            <input
                                style={{ marginBottom: "4px" }}
                                type="checkbox"
                                name="website2"
                                checked={website2}
                                onChange={(e) => setWebsite2(e.target.checked)}
                            />
                        </label>
                    </div>

                    <div className="form_group_new tags_container_new">
                        <label className="label_new">
                            <input
                                style={{ marginBottom: "5px" }}
                                type="checkbox"
                                name="rightLeft"  // Corrected names
                                checked={rightLeft}
                                onChange={(e) => setRightLeft(e.target.checked)}
                            />
                            Right Left
                        </label>


                        <label className="label_new">
                            <input
                                style={{ marginBottom: "5px" }}
                                type="checkbox"
                                name="bannerTop" // Corrected names
                                checked={bannerTop}
                                onChange={(e) => setBannerTop(e.target.checked)}
                            />
                           Banner Top
                        </label>


                        <label className="label_new">
                            <input
                                style={{ marginBottom: "5px" }}
                                type="checkbox"
                                name="bannerBottom" // Corrected names
                                checked={bannerBottom}
                                onChange={(e) => setBannerBottom(e.target.checked)}
                            />
                          Banner Bottom
                        </label>


                        <label className="label_new">
                            <input
                                style={{ marginBottom: "5px" }}
                                type="checkbox"
                                name="topBottom" // Corrected names
                                checked={topBottom}
                                onChange={(e) => setTopBottom(e.target.checked)}
                            />
                            Top Bottom
                        </label>


                        <label className="label_new">
                            <input
                                style={{ marginBottom: "5px" }}
                                type="checkbox"
                                name="categoryPage"  // Corrected names
                                checked={categoryPage}
                                onChange={(e) => setCategoryPage(e.target.checked)}
                            />
                            Category Page
                        </label>


                        <label className="label_new">
                            <input
                                style={{ marginBottom: "5px" }}
                                type="checkbox"
                                name="detailsPage" // Corrected names
                                checked={detailsPage}
                                onChange={(e) => setDetailsPage(e.target.checked)}
                            />
                         Details Page
                        </label>
                    </div>

                    <button type="submit" className="submit_button_new">Submit</button>
                </form>
            </div>
            <AdminFooter />
        </>
    );
};

export default AddAdvertise;