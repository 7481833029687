import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaComments } from 'react-icons/fa'; // Import the comments icon
import Dashboard from "../Dashboard/Dashoboard";
import './Addnews.css'; // Your existing CSS file
import AdminFooter from '../Footer/Footeradmin';

const ListNewNews = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Ensure this is configured
    const [newsArticles, setNewsArticles] = useState([]);
    const [loading, setLoading] = useState(false); // For list fetching
    const [message, setMessage] = useState(''); // For list actions/feedback

    // --- Edit Modal State ---
    const [editingArticleId, setEditingArticleId] = useState(null);
    const [editingArticle, setEditingArticle] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isUpdatingModal, setIsUpdatingModal] = useState(false); // Loading state for modal update

    // State for checkboxes within the edit modal
    const [modalWebsite1, setModalWebsite1] = useState(false);
    const [modalWebsite2, setModalWebsite2] = useState(false);
    const [modalMostPopular, setModalMostPopular] = useState(false);
    const [modalFeatured, setModalFeatured] = useState(false);
    const [modalPopular, setModalPopular] = useState(false);
    const [modalLatestNews, setModalLatestNews] = useState(false);
    const [modalMostViewed, setModalMostViewed] = useState(false);
    const [modalRecentPosts, setModalRecentPosts] = useState(false);

    // --- Toggle State ---
    const [togglingArticleId, setTogglingArticleId] = useState(null); // ID of article being toggled

    // --- State for Comment Modal ---
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [currentComments, setCurrentComments] = useState([]);
    const [viewingArticleId, setViewingArticleId] = useState(null); // Track which article's comments are shown
    const [viewingArticleTitle, setViewingArticleTitle] = useState(''); // For modal header
    const [loadingComments, setLoadingComments] = useState(false);
    const [commentError, setCommentError] = useState('');

    // --- Fetch Initial Data ---
    useEffect(() => {
        fetchNewsArticles();
    }, []);

    // --- Fetch News Articles ---
    const fetchNewsArticles = async () => {
        setLoading(true);
        setMessage('');
        try {
            const token = localStorage.getItem('token'); // Assuming token is needed
            const response = await axios.get(`${baseUrl}/news`, {
                headers: { 'Authorization': `Bearer ${token}` } // Adjust if auth is not needed for GET /news
            });

            let articles = [];
            if (response.data && Array.isArray(response.data)) {
                articles = response.data;
            } else {
                 console.warn("Unexpected news data format received:", response.data);
            }

            // Normalize boolean fields consistently
            const normalizedArticles = articles.map(article => ({
                ...article,
                website1: !!article.website1,
                website2: !!article.website2,
                mostPopular: !!article.mostPopular,
                featured: !!article.featured,
                popular: !!article.popular,
                latestNews: !!article.latestNews,
                mostViewed: !!article.mostViewed,
                recentPosts: !!article.recentPosts,
            }));

            setNewsArticles(normalizedArticles);

        } catch (error) {
            console.error('Error fetching news articles:', error);
            setMessage('Error fetching news: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    // --- Edit Modal Handlers ---
    const handleEdit = (article) => {
        if (togglingArticleId === article._id) return; // Prevent edit while toggling

        setEditingArticleId(article._id);
        setEditingArticle({ ...article }); // Store full article data
        setNewImage(null);

        // Set modal checkbox states directly from article data
        setModalWebsite1(article.website1);
        setModalWebsite2(article.website2);
        setModalMostPopular(article.mostPopular);
        setModalFeatured(article.featured);
        setModalPopular(article.popular);
        setModalLatestNews(article.latestNews);
        setModalMostViewed(article.mostViewed);
        setModalRecentPosts(article.recentPosts);

        setImagePreview(article.image ? `${baseUrl}/${article.image.startsWith('/') ? article.image.substring(1) : article.image}` : null);
        setShowModal(true);
    };

    const handleCancelEdit = () => {
        setEditingArticleId(null);
        setEditingArticle(null);
        setNewImage(null);
        setImagePreview(null);
        setShowModal(false);
        setIsUpdatingModal(false); // Reset modal loading state
    };

    // Handle text/select changes in edit modal
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingArticle(prev => ({ ...prev, [name]: value }));
    };

    // Generic checkbox handler for edit modal state variables
    const handleModalCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    };

    // Handle image file change in edit modal
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewImage(file);
            // Create a preview URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // --- Delete Handler ---
    const handleDelete = async (id) => {
        if (editingArticleId === id || togglingArticleId === id) return; // Prevent delete while editing/toggling

        if (window.confirm('Are you sure you want to delete this article and its comments?')) {
            setMessage('');
            try {
                const token = localStorage.getItem('token');
                await axios.delete(`${baseUrl}/news/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setMessage('Article deleted successfully');
                // Refresh list after delete
                fetchNewsArticles();
                 // Or Optimistic UI update:
                 // setNewsArticles(prev => prev.filter(article => article._id !== id));
            } catch (error) {
                console.error('Error deleting article:', error);
                setMessage('Error deleting article: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    // --- Update Handler (Edit Modal Save) ---
    const handleUpdate = async () => {
        if (!editingArticleId || !editingArticle || isUpdatingModal) return;

        setIsUpdatingModal(true);
        setMessage('');
        try {
            const formData = new FormData();
            // Append non-boolean fields
            formData.append('category', editingArticle.category || '');
            formData.append('title', editingArticle.title || '');
            formData.append('viewSide', editingArticle.viewSide || 'left');
            formData.append('description', editingArticle.description || '');
            formData.append('date', editingArticle.date ? new Date(editingArticle.date).toISOString() : new Date().toISOString());

            // Append boolean fields from modal state variables
            formData.append('website1', modalWebsite1);
            formData.append('website2', modalWebsite2);
            formData.append('mostPopular', modalMostPopular);
            formData.append('featured', modalFeatured);
            formData.append('popular', modalPopular);
            formData.append('latestNews', modalLatestNews);
            formData.append('mostViewed', modalMostViewed);
            formData.append('recentPosts', modalRecentPosts);

            if (newImage) {
                formData.append('image', newImage); // Key must match backend multer field name
            }

            const token = localStorage.getItem('token');
            await axios.put(`${baseUrl}/news/${editingArticleId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            setMessage('Article updated successfully');
            handleCancelEdit(); // Close modal and reset state
            fetchNewsArticles(); // Refresh list to get updated data

        } catch (error) {
            console.error('Error updating article:', error);
            setMessage('Error updating article: ' + (error.response?.data?.message || error.message));
            // Keep modal open on error? Optional.
            // setIsUpdatingModal(false); // Reset loading state on error
        } finally {
             setIsUpdatingModal(false); // Ensure loading state is reset
        }
    };

    // --- Direct Status Toggle Handler ---
    const handleToggleStatus = async (id, fieldKey, currentValue, currentArticleData) => {
        if (editingArticleId === id || togglingArticleId === id) return;

        setTogglingArticleId(id);
        setMessage('');
        const newValue = !currentValue;

        try {
            // Send the update for the specific field
            const payload = {
                 // Spread existing data (optional, but safer if backend expects full obj)
                 // ...currentArticleData,
                // Only send the field being changed
                [fieldKey]: newValue
            };

            // Assuming backend PUT for /news/:id can handle partial JSON updates
             const token = localStorage.getItem('token');
             await axios.put(`${baseUrl}/news/${id}`, payload, {
                 headers: {
                     'Authorization': `Bearer ${token}`,
                     'Content-Type': 'application/json' // Important: Send as JSON for partial update
                 }
             });

            // Optimistic UI Update
            setNewsArticles(prevArticles =>
                prevArticles.map(article =>
                    article._id === id
                        ? { ...article, [fieldKey]: newValue }
                        : article
                )
            );
            // setMessage(`${fieldKey} updated.`); // Optional feedback

        } catch (error) {
            console.error(`Error toggling ${fieldKey}:`, error);
            setMessage(`Error updating ${fieldKey}: ` + (error.response?.data?.message || error.message));
            // Revert UI on error? Optional.
        } finally {
            setTogglingArticleId(null);
        }
    };

    // --- Comment Modal Handlers ---
    const fetchComments = async (articleId) => {
        setLoadingComments(true);
        setCommentError('');
        setCurrentComments([]);
        try {
            // Assuming public endpoint for comments
            const response = await axios.get(`${baseUrl}/news/${articleId}/comments`);
            setCurrentComments(response.data || []); // Ensure array
        } catch (error) {
            console.error('Error fetching comments:', error);
            setCommentError('Failed to load comments: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoadingComments(false);
        }
    };

    const handleViewComments = (articleId, articleTitle) => {
        if (editingArticleId === articleId || togglingArticleId === articleId) return;

        setViewingArticleId(articleId);
        setViewingArticleTitle(articleTitle);
        setShowCommentModal(true);
        fetchComments(articleId);
    };

    const handleCloseCommentModal = () => {
        setShowCommentModal(false);
        setViewingArticleId(null);
        setViewingArticleTitle('');
        setCurrentComments([]);
        setLoadingComments(false);
        setCommentError('');
    };


    // --- Render Logic ---
    return (
        <>
            <Dashboard />
            <div className="containerrs list-news-container"> {/* Ensure correct container class */}
                <h1>List News Articles</h1>

                {message && (
                    <div className={`message ${message.includes('Error') ? 'error' : 'success'}`}>
                        {message}
                    </div>
                )}

                {loading ? (
                     <div className="loading-indicator">Loading articles...</div>
                ) : newsArticles.length === 0 ? (
                    <p>No news articles found.</p>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="polls-table news-list-table">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Title</th>
                                        <th>Image</th>
                                        <th>Side</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                        {/* Status Headers - Centered */}
                                        <th style={{ textAlign: 'center' }} title="Website 1">Web1</th>
                                        <th style={{ textAlign: 'center' }} title="Website 2">Web2</th>
                                        <th style={{ textAlign: 'center' }} title="Most Popular">MPop</th>
                                        <th style={{ textAlign: 'center' }} title="Featured">Feat</th>
                                        <th style={{ textAlign: 'center' }} title="Popular">Pop</th>
                                        <th style={{ textAlign: 'center' }} title="Latest News">Latest</th>
                                        <th style={{ textAlign: 'center' }} title="Most Viewed">Viewed</th>
                                        <th style={{ textAlign: 'center' }} title="Recent Posts">Recent</th>
                                        {/* Comments Header */}
                                        <th style={{ textAlign: 'center' }}>Comments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newsArticles.map((article) => (
                                        <tr key={article._id}>
                                            <td>{article.category}</td>
                                            <td title={article.title}>{article.title ? article.title.substring(0, 30) + (article.title.length > 30 ? '...' : '') : ''}</td>
                                            <td className="image-cell">
                                                {article.image && (
                                                    <img
                                                        src={`${baseUrl}/${article.image.startsWith('/') ? article.image.substring(1) : article.image}`}
                                                        alt=""
                                                        onError={(e) => { e.target.style.display = 'none'; }} // Hide if image fails to load
                                                    />
                                                )}
                                            </td>
                                            <td>{article.viewSide}</td>
                                            <td className="description-cell" title={article.description}>
                                                {article.description ? article.description.substring(0, 40) + (article.description.length > 40 ? '...' : '') : ''}
                                            </td>
                                            <td>{article.date ? new Date(article.date).toLocaleDateString() : 'N/A'}</td>

                                            {/* Status Toggles */}
                                            <td style={{ textAlign: 'center' }}>
                                                <span className={`status-toggle ${togglingArticleId === article._id ? 'loading' : ''}`} onClick={() => handleToggleStatus(article._id, 'website1', article.website1, article)} title={`Toggle Website 1 (${article.website1 ? 'On' : 'Off'})`}>
                                                    {togglingArticleId === article._id ? '...' : (article.website1 ? '✅' : '❌')}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <span className={`status-toggle ${togglingArticleId === article._id ? 'loading' : ''}`} onClick={() => handleToggleStatus(article._id, 'website2', article.website2, article)} title={`Toggle Website 2 (${article.website2 ? 'On' : 'Off'})`}>
                                                    {togglingArticleId === article._id ? '...' : (article.website2 ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span className={`status-toggle ${togglingArticleId === article._id ? 'loading' : ''}`} onClick={() => handleToggleStatus(article._id, 'mostPopular', article.mostPopular, article)} title={`Toggle Most Popular (${article.mostPopular ? 'On' : 'Off'})`}>
                                                    {togglingArticleId === article._id ? '...' : (article.mostPopular ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span className={`status-toggle ${togglingArticleId === article._id ? 'loading' : ''}`} onClick={() => handleToggleStatus(article._id, 'featured', article.featured, article)} title={`Toggle Featured (${article.featured ? 'On' : 'Off'})`}>
                                                    {togglingArticleId === article._id ? '...' : (article.featured ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span className={`status-toggle ${togglingArticleId === article._id ? 'loading' : ''}`} onClick={() => handleToggleStatus(article._id, 'popular', article.popular, article)} title={`Toggle Popular (${article.popular ? 'On' : 'Off'})`}>
                                                    {togglingArticleId === article._id ? '...' : (article.popular ? '✅' : '❌')}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <span className={`status-toggle ${togglingArticleId === article._id ? 'loading' : ''}`} onClick={() => handleToggleStatus(article._id, 'latestNews', article.latestNews, article)} title={`Toggle Latest News (${article.latestNews ? 'On' : 'Off'})`}>
                                                    {togglingArticleId === article._id ? '...' : (article.latestNews ? '✅' : '❌')}
                                                </span>
                                            </td>
                                             <td style={{ textAlign: 'center' }}>
                                                <span className={`status-toggle ${togglingArticleId === article._id ? 'loading' : ''}`} onClick={() => handleToggleStatus(article._id, 'mostViewed', article.mostViewed, article)} title={`Toggle Most Viewed (${article.mostViewed ? 'On' : 'Off'})`}>
                                                    {togglingArticleId === article._id ? '...' : (article.mostViewed ? '✅' : '❌')}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <span className={`status-toggle ${togglingArticleId === article._id ? 'loading' : ''}`} onClick={() => handleToggleStatus(article._id, 'recentPosts', article.recentPosts, article)} title={`Toggle Recent Posts (${article.recentPosts ? 'On' : 'Off'})`}>
                                                    {togglingArticleId === article._id ? '...' : (article.recentPosts ? '✅' : '❌')}
                                                </span>
                                            </td>
                                            {/* End Status Toggles */}

                                            {/* Comments Cell */}
                                            <td style={{ textAlign: 'center' }} className="action-cell comment-action-cell">
                                                <button
                                                    className="action-button comments"
                                                    onClick={() => handleViewComments(article._id, article.title)}
                                                    title="View Comments"
                                                    disabled={togglingArticleId === article._id || editingArticleId === article._id}
                                                >
                                                    <FaComments />
                                                </button>
                                            </td>

                                            {/* Actions Cell */}
                                            <td className="action-cell">
                                                <button className="action-button edit" onClick={() => handleEdit(article)} disabled={togglingArticleId === article._id}>Edit</button>
                                                <button className="action-button delete" onClick={() => handleDelete(article._id)} disabled={togglingArticleId === article._id || editingArticleId === article._id}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> {/* End table-responsive */}

                        {/* --- Modal for Editing --- */}
                        {showModal && editingArticle && (
                            <div className="modal news-edit-modal">
                                <div className="modal-content">
                                    <span className="close" onClick={handleCancelEdit}>×</span>
                                    <h2>Edit News Article</h2>
                                    <form onSubmit={(e) => { e.preventDefault(); handleUpdate(); }}>
                                         {/* Category & Title */}
                                         <div className="modal-form-row">
                                            <div className="form-group">
                                                <label htmlFor="edit-category">Category:</label>
                                                <input id="edit-category" type="text" name="category" value={editingArticle.category || ''} onChange={handleInputChange} disabled={isUpdatingModal}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="edit-title">Title:</label>
                                                <input id="edit-title" type="text" name="title" value={editingArticle.title || ''} onChange={handleInputChange} disabled={isUpdatingModal}/>
                                            </div>
                                        </div>
                                        {/* Description */}
                                        <div className="form-group">
                                            <label htmlFor="edit-description">Description:</label>
                                            <textarea id="edit-description" name="description" value={editingArticle.description || ''} onChange={handleInputChange} disabled={isUpdatingModal} rows={4}/>
                                        </div>
                                        {/* View Side & Date */}
                                        <div className="modal-form-row">
                                            <div className="form-group">
                                                <label htmlFor="edit-viewSide">View Side:</label>
                                                <select id="edit-viewSide" name="viewSide" value={editingArticle.viewSide || 'left'} onChange={handleInputChange} disabled={isUpdatingModal}>
                                                    <option value="left">Left</option>
                                                    <option value="right">Right</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="edit-date">Date:</label>
                                                <input id="edit-date" type="date" name="date" value={editingArticle.date ? new Date(editingArticle.date).toISOString().split('T')[0] : ''} onChange={handleInputChange} disabled={isUpdatingModal}/>
                                            </div>
                                        </div>
                                         {/* Image Upload */}
                                         <div className="form-group">
                                                <label htmlFor="edit-image">Image:</label>
                                                <div className="image-preview-container">
                                                    {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview-modal"/>}
                                                </div>
                                                <input id="edit-image" type="file" onChange={handleImageChange} accept="image/*" disabled={isUpdatingModal}/>
                                         </div>
                                        {/* Website Display Checkboxes */}
                                        <fieldset className="form-group checkbox-group">
                                            <legend>Website Display</legend>
                                            <label><input type="checkbox" checked={modalWebsite1} onChange={handleModalCheckboxChange(setModalWebsite1)} disabled={isUpdatingModal} /> Website 1</label>
                                            <label><input type="checkbox" checked={modalWebsite2} onChange={handleModalCheckboxChange(setModalWebsite2)} disabled={isUpdatingModal} /> Website 2</label>
                                        </fieldset>
                                        {/* Tags Checkboxes */}
                                        <fieldset className="form-group checkbox-group tags_container_new">
                                            <legend>Tags</legend>
                                            <label><input type="checkbox" checked={modalMostPopular} onChange={handleModalCheckboxChange(setModalMostPopular)} disabled={isUpdatingModal} /> Most Popular</label>
                                            <label><input type="checkbox" checked={modalFeatured} onChange={handleModalCheckboxChange(setModalFeatured)} disabled={isUpdatingModal} /> Featured</label>
                                            <label><input type="checkbox" checked={modalPopular} onChange={handleModalCheckboxChange(setModalPopular)} disabled={isUpdatingModal} /> Popular</label>
                                            <label><input type="checkbox" checked={modalLatestNews} onChange={handleModalCheckboxChange(setModalLatestNews)} disabled={isUpdatingModal} /> Latest News</label>
                                            <label><input type="checkbox" checked={modalMostViewed} onChange={handleModalCheckboxChange(setModalMostViewed)} disabled={isUpdatingModal} /> Most Viewed</label>
                                            <label><input type="checkbox" checked={modalRecentPosts} onChange={handleModalCheckboxChange(setModalRecentPosts)} disabled={isUpdatingModal} /> Recent Posts</label>
                                        </fieldset>
                                        {/* Form Actions */}
                                        <div className="form-actions modal-actions">
                                            <button type="submit" className="action-button update" disabled={isUpdatingModal}>
                                                 {isUpdatingModal ? 'Updating...' : 'Update'}
                                             </button>
                                            <button type="button" className="action-button cancel" onClick={handleCancelEdit} disabled={isUpdatingModal}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )} {/* End Edit Modal */}

                        {/* --- Modal for Viewing Comments --- */}
                        {showCommentModal && viewingArticleId && (
                            <div className="modal comments-view-modal">
                                <div className="modal-content comments-modal-content">
                                    <span className="close" onClick={handleCloseCommentModal}>×</span>
                                    <h2 title={viewingArticleTitle}>
                                        Comments for: "{viewingArticleTitle ? viewingArticleTitle.substring(0, 50) + (viewingArticleTitle.length > 50 ? '...' : '') : 'Article'}"
                                    </h2>

                                    {loadingComments ? (
                                        <div className="loading-indicator">Loading comments...</div>
                                    ) : commentError ? (
                                        <div className="message error">{commentError}</div>
                                    ) : currentComments.length === 0 ? (
                                        <p>No comments found for this article.</p>
                                    ) : (
                                        <ul className="comments-list">
                                            {currentComments.map(comment => (
                                                <li key={comment._id} className="comment-item">
                                                    <div className="comment-header">
                                                        <strong className="comment-author" style={{color:"red"}}>{comment.name || 'Anonymous'}</strong>
                                                        <br/>
                                                        <span className="comment-date">
                                                            {comment.createdAt ? new Date(comment.createdAt).toLocaleString() : ''}
                                                        </span>
                                                    </div>
                                                    <p className="comment-text">{comment.comment}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                     <div className="form-actions modal-actions">
                                        <button type="button" className="action-button cancel" onClick={handleCloseCommentModal}>Close</button>
                                    </div>
                                </div>
                            </div>
                        )} {/* End Comment Modal */}
                    </>
                )}
            </div> {/* End containerrs */}
            <AdminFooter/>
        </>
    );
};

export default ListNewNews;