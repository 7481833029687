// src/components/EPaperPage.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './EPaperViewer.css'; // Create this CSS file
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const EPaperPage = () => {
    const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Default to today's date YYYY-MM-DD
    const [newspapers, setNewspapers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchNewspapersByDate(selectedDate);
    }, [selectedDate]); // Refetch when selectedDate changes

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const fetchNewspapersByDate = async (date) => {
        if (!date) return;
        setLoading(true);
        setError('');
        setNewspapers([]); // Clear previous results
        try {
            const response = await axios.get(`${baseUrl}/newspapers/by-date`, {
                params: { date: date } // Send date as query parameter
            });
            setNewspapers(response.data);
            if (response.data.length === 0) {
                 setError(`No newspapers found for ${date}.`);
            }
        } catch (err) {
            console.error("Error fetching newspapers by date:", err);
            setError(`Failed to load newspapers for ${date}. ${err.response?.data?.message || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        <Navbar/>
        <div className="epaper-page-container container"> {/* Added container class */}
            <h1>E-Paper Archive</h1>

            <div className="date-selector">
                <label htmlFor="epaper-date">Select Date: </label>
                <input
                    type="date"
                    id="epaper-date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    style={{width:"12%"}}
                />
            </div>

            {loading && <p className="loading-indicator">Loading newspapers...</p>}
            {error && <p className="error-message">{error}</p>}

            {!loading && newspapers.length > 0 && (
                <div className="newspaper-list">
                    <h2>Available Newspapers for {selectedDate}</h2>
                    <ul>
                        {newspapers.map(paper => (
                            <li key={paper._id}>
                                <Link to={`/epaper/${paper._id}`} className="newspaper-link">
                                    {paper.heading || 'Untitled Newspaper'}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
        <Footer/></>
    );
};

export default EPaperPage;