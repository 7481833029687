import React from 'react'; // Removed useState as newsletter state is gone
import { Link } from 'react-router-dom';
import logoWhite from './logo.png'; // Ensure path is correct
import './Footer.css';

function Footer() {
    // Removed newsletter state and handler

    const currentYear = new Date().getFullYear();

    return (
        <footer className="bestnews-footer">
            <div className="container">
                {/* ----- Main Footer Section ----- */}
                {/* Updated class to reflect 3 columns */}
                <div className="bestnews-footer-main bestnews-footer-3col">
                    {/* Column 1: About & Logo */}
                    <div className="bestnews-footer-col bestnews-footer-about">
                        <Link to="/" className="bestnews-footer-logo">
                            <img src={logoWhite} alt="24Live News White Logo" />
                        </Link>
                        <p className="bestnews-footer-description">
                            Your trusted source for breaking news, in-depth analysis, and engaging content across the globe. Stay informed, stay connected.
                        </p>
                      
                    </div>

                    {/* Column 2: Quick Links & Categories Combined (or keep separate) */}
                    {/* Option 1: Combined Links */}
                     {/* <div className="bestnews-footer-col bestnews-footer-links">
                        <h4 className="bestnews-footer-heading"><i className="fas fa-link"></i> Quick Links</h4>
                        <ul className="bestnews-footer-nav">
                           <li><Link to="/about">About Us</Link></li>
                           <li><Link to="/category">Categories</Link></li>
                           <li><Link to="/latest-news">Latest News</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                           <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/terms-of-service">Terms of Service</Link></li>
                        </ul>
                         <h4 className="bestnews-footer-heading" style={{marginTop: '30px'}}><i className="fas fa-folder-open"></i> Categories</h4>
                         <ul className="bestnews-footer-nav">
                           <li><Link to="/category/politics">Politics</Link></li>
                           <li><Link to="/category/business">Business</Link></li>
                           <li><Link to="/category/technology">Technology</Link></li>
                           <li><Link to="/category/sports">Sports</Link></li>
                        </ul>
                     </div> */}

                    {/* Option 2: Keep Separate (Requires adjusting grid columns in CSS if needed) */}
                     <div className="bestnews-footer-col bestnews-footer-links">
                        <h4 className="bestnews-footer-heading"><i className="fas fa-link"></i> Quick Links</h4>
                        <ul className="bestnews-footer-nav">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/category">Categories</Link></li>
                            <li><Link to="/weather">Weather</Link></li>
                            <li><Link to="/epaper">newspaper</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="bestnews-footer-col bestnews-footer-links">
                         <h4 className="bestnews-footer-heading"><i className="fas fa-folder-open"></i> Popular Categories</h4>
                         <ul className="bestnews-footer-nav">
                             <li><Link to="/category/देश-विदेश">देश-विदेश</Link></li>
                             <li><Link to="/category/राजनीति">राजनीति</Link></li>
                             <li><Link to="/category/जोधपुर विशेष">जोधपुर विशेष</Link></li>
                             <li><Link to="/category/धार्मिक - समाचार">धार्मिक - समाचार</Link></li>
                             <li><Link to="/category/अपराध">अपराध</Link></li>
                             <li><Link to="/category/राजस्थान विशेष">राजस्थान विशेष</Link></li>
                         </ul>
                     </div>


                    {/* Column 3: Follow Us */}
                    <div className="bestnews-footer-col bestnews-footer-connect">
                        {/* Removed Newsletter Form */}
                        <h4 className="bestnews-footer-heading bestnews-follow-heading"><i className="fas fa-share-alt"></i> Follow Us</h4>
                        <p>Connect with us on social media for the latest updates and discussions.</p>
                        <ul className="bestnews-social-links">
                             {/* Added title attribute for tooltips */}
                            <li><a href="https://www.facebook.com/29news.live" target='_blank' rel="noopener noreferrer" aria-label="Facebook" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/29NEWS2?s=08" target='_blank' rel="noopener noreferrer" aria-label="Twitter" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.instagram.com/29newslive/" target='_blank' rel="noopener noreferrer" aria-label="Instagram" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                            <li><a href="https://www.youtube.com/c/29NEWS29news" target='_blank' rel="noopener noreferrer" aria-label="YouTube" title="YouTube"><i className="fab fa-youtube"></i></a></li>
                            <li><a href="#" target='_blank' rel="noopener noreferrer" aria-label="LinkedIn" title="LinkedIn"><i className="fab fa-linkedin-in"></i></a></li>
                            
                        </ul>
                        <br/>
                        <div className="bestnews-contact-info">
                             <p><i className="fas fa-map-marker-alt"></i>Satyanarayan Joshi Krantikari <br/> Jodhpur(Raj.)-302001</p>
                             <p><i className="fas fa-phone"></i> {/* Changed icon */} <a href="tel:+1234567890">+91 9982888857</a></p>
                             <p><i className="fas fa-envelope-open"></i>  <a href="mailto:info@24livenews.com">29newslive@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----- Bottom Bar ----- */}
            <div className="bestnews-footer-bottom">
                <div className="container bestnews-footer-bottom-content">
                    <p className="bestnews-copyright">
                        ©  29News Live . All Rights Reserved  {currentYear} 
                    </p>
                    <ul className="bestnews-footer-bottom-links">
                       <p id='dheerdesignfooter'>Designed and Developed By <a href='https://dheersoftwaresolutions.com/' target='_blank'> Dheer Software Solutions</a></p>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;