// // src/authUtils.js

// export const getToken = () => {
//     return localStorage.getItem('token'); // Or read from cookie
//   };
  
//   export const setToken = (token) => {
//     localStorage.setItem('token', token); // Or store in cookie
//   };
  
  // export const removeToken = () => {
  //   localStorage.removeItem('token');  // Or clear cookie
  // };

  // Authutils.js
// export const setToken = (token) => {
//   localStorage.setItem('token', token); // Or use cookies
// };

// export const getToken = () => {
//   return localStorage.getItem('token'); // Or retrieve from cookies
// };

// export const clearToken = () => {
//   localStorage.removeItem('token'); // Or clear from cookies
// };

// Authutils.js

export const setToken = (token) => {
  localStorage.setItem('token', token);
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const clearToken = () => {
  localStorage.clear();
};

export const getUserRole = () => {
  //  This is just an example.  Adapt to *your* storage mechanism
  const role = localStorage.getItem('userRole');
  return role;
};