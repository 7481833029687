import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';
import '../AddNewspaper/Addnewspaper.css'; // Keep your existing CSS path
// import './ListMarqueeHeading.css'; // Add a new CSS file for specific styles (optional)

const ListMarqueeHeading = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [marqueeHeadings, setMarqueeHeadings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [editingId, setEditingId] = useState(null);
    const [editedHeading, setEditedHeading] = useState('');
    const [editedWebsite1, setEditedWebsite1] = useState(false);
    const [editedWebsite2, setEditedWebsite2] = useState(false);

    // State to track which item is currently being updated via toggle
    const [togglingStatusId, setTogglingStatusId] = useState(null);

    useEffect(() => {
        fetchMarqueeHeadings();
    }, []);

    const fetchMarqueeHeadings = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/marquee-headings`);
            // Ensure website1 and website2 are treated as booleans on fetch
            const headings = response.data.map(h => ({
                ...h,
                website1: h.website1 === true || h.website1 === 'true',
                website2: h.website2 === true || h.website2 === 'true',
            }));
            setMarqueeHeadings(headings);
            setMessage('');
        } catch (error) {
            console.error("Error fetching marquee headings:", error);
            setMessage('Error fetching marquee headings: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteMarqueeHeading = async (id) => {
        // Prevent action if already toggling/editing this item
        if (togglingStatusId === id || editingId === id) return;

        if (window.confirm('Are you sure you want to delete this heading?')) {
             try {
                await axios.delete(`${baseUrl}/marquee-headings/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setMessage('Marquee heading deleted successfully');
                // Remove from local state immediately for better UX
                setMarqueeHeadings(prev => prev.filter(h => h._id !== id));
                // Adjust current page if the last item on a page was deleted
                const newTotalItems = marqueeHeadings.length - 1;
                const newTotalPages = Math.ceil(newTotalItems / itemsPerPage);
                if (currentPage > newTotalPages && newTotalPages > 0) {
                    setCurrentPage(newTotalPages);
                } else if (newTotalItems === 0) {
                    setCurrentPage(1); // Go back to page 1 if no items left
                }

            } catch (error) {
                console.error("Error deleting marquee heading:", error);
                setMessage('Error deleting marquee heading: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    const handleEditClick = (heading) => {
         // Prevent action if already toggling this item
         if (togglingStatusId === heading._id) return;
        setEditingId(heading._id);
        setEditedHeading(heading.heading);
        setEditedWebsite1(heading.website1);
        setEditedWebsite2(heading.website2);
    };

    // Handles saving changes from the Edit mode
    const handleSaveClick = async (id) => {
        try {
            const payload = {
                heading: editedHeading,
                // Send as boolean (assuming backend handles booleans)
                // If backend strictly needs 'true'/'false' strings, uncomment the .toString() lines
                website1: editedWebsite1,
                website2: editedWebsite2,
                // website1: editedWebsite1.toString(),
                // website2: editedWebsite2.toString(),
            };
            await axios.put(
                `${baseUrl}/marquee-headings/${id}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                }
            );

            setMessage('Marquee heading updated successfully');
            setEditingId(null);
            // Update local state directly instead of full refetch
            setMarqueeHeadings(prev => prev.map(h =>
                h._id === id ? { ...h, ...payload } : h
            ));
        } catch (error) {
             console.error("Error updating marquee heading:", error);
             setMessage('Error updating marquee heading: ' + (error.response?.data?.message || error.message));
        }
    };

    const handleCancelClick = () => {
        setEditingId(null);
    };

    // --- NEW FUNCTION ---
    // Handles toggling website1/website2 status directly from the icon click
    const handleToggleWebsiteStatus = async (id, websiteKey, currentValue, currentHeading) => {
        // Prevent action if already editing or toggling this item
        if (editingId === id || togglingStatusId === id) return;

        setTogglingStatusId(id); // Mark this item as being updated
        setMessage(''); // Clear previous messages

        const newValue = !currentValue;

        try {
            // Prepare payload - Send the field being toggled AND the current heading
            // (Assuming PUT might overwrite if heading isn't sent, based on handleSaveClick)
            const payload = {
                heading: currentHeading, // Include current heading
                [websiteKey]: newValue,   // The toggled value (computed property name)
            };
            // Ensure the other website status is included if needed by backend PUT
            // If backend handles partial updates (PATCH or smart PUT), this isn't needed
            // Let's find the current heading data to include the other status
            const currentItem = marqueeHeadings.find(h => h._id === id);
            if (currentItem) {
                 if (websiteKey === 'website1') {
                    payload.website2 = currentItem.website2;
                 } else {
                    payload.website1 = currentItem.website1;
                 }
            } else {
                 // Should not happen, but handle defensively
                 throw new Error("Could not find heading data locally.");
            }


            // Decide whether to send boolean or string based on handleSaveClick pattern
            // Assuming boolean is preferred unless backend strictly needs strings
            // If strings needed:
            // payload[websiteKey] = newValue.toString();
            // payload.website1 = payload.website1.toString();
            // payload.website2 = payload.website2.toString();


            await axios.put(
                `${baseUrl}/marquee-headings/${id}`,
                payload, // Send the updated data
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            // Update local state immediately
            setMarqueeHeadings(prevHeadings =>
                prevHeadings.map(heading =>
                    heading._id === id
                        ? { ...heading, [websiteKey]: newValue } // Update only the toggled key
                        : heading
                )
            );
            

        } catch (error) {
            console.error(`Error toggling ${websiteKey} status:`, error);
            setMessage(`Error updating ${websiteKey}: ` + (error.response?.data?.message || error.message));
            // Optional: You could revert the optimistic UI update here if needed, but usually showing error is enough
        } finally {
            setTogglingStatusId(null); // Unmark item - update finished (success or fail)
        }
    };

    // --- Pagination Logic (remains the same) ---
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = marqueeHeadings.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(marqueeHeadings.length / itemsPerPage);

    return (
        <>
            <Dashboard />
            <div className="containerrs list-news-container" style={{ marginTop: "50px" }}>
                <h2>List Marquee Headings</h2>
                 {/* Display Message */}
                 {message && (
                     <p className={`message ${message.includes('Error') ? 'error' : 'success'}`}>
                         {message}
                     </p>
                 )}

                {loading ? (
                    <p>Loading marquee headings...</p>
                ) : (
                    <>
                        <table className="image-table news-list-table list-marquee-table"> {/* Added class */}
                            <thead>
                                <tr>
                                    <th>Heading</th>
                                    <th style={{ textAlign: 'center' }}>Website 1</th>
                                    <th style={{ textAlign: 'center' }}>Website 2</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((heading) => (
                                        <tr key={heading._id} className={editingId === heading._id ? 'editing-row' : ''}>
                                            {editingId === heading._id ? (
                                                // --- Edit Mode Row ---
                                                <>
                                                    <td>
                                                        <input
                                                            className="edit-input"
                                                            type="text"
                                                            value={editedHeading}
                                                            onChange={(e) => setEditedHeading(e.target.value)}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={editedWebsite1}
                                                            onChange={(e) => setEditedWebsite1(e.target.checked)}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={editedWebsite2}
                                                            onChange={(e) => setEditedWebsite2(e.target.checked)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button className="action-button save" onClick={() => handleSaveClick(heading._id)}>Save</button>
                                                        <button className="action-button cancel" onClick={handleCancelClick}>Cancel</button>
                                                    </td>
                                                </>
                                            ) : (
                                                // --- View Mode Row ---
                                                <>
                                                    <td>{heading.heading}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <span
                                                            className={`status-toggle ${togglingStatusId === heading._id ? 'loading' : ''}`}
                                                            onClick={() => handleToggleWebsiteStatus(heading._id, 'website1', heading.website1, heading.heading)}
                                                            title={`Click to toggle Website 1 status (${heading.website1 ? 'Active' : 'Inactive'})`}
                                                        >
                                                            {/* Show spinner if toggling */}
                                                            {togglingStatusId === heading._id ? '...' : (heading.website1 ? '✅' : '❌')}
                                                        </span>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                         <span
                                                            className={`status-toggle ${togglingStatusId === heading._id ? 'loading' : ''}`}
                                                            onClick={() => handleToggleWebsiteStatus(heading._id, 'website2', heading.website2, heading.heading)}
                                                            title={`Click to toggle Website 2 status (${heading.website2 ? 'Active' : 'Inactive'})`}
                                                        >
                                                            {togglingStatusId === heading._id ? '...' : (heading.website2 ? '✅' : '❌')}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button className="action-button edit" onClick={() => handleEditClick(heading)} disabled={togglingStatusId === heading._id}>Edit</button>
                                                        <button className="action-button delete" onClick={() => handleDeleteMarqueeHeading(heading._id)} disabled={togglingStatusId === heading._id}>Delete</button>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: 'center' }}>No marquee headings found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                         {/* Pagination */}
                         {totalPages > 1 && (
                            <div className="pagination">
                                <button
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1 || loading}
                                >
                                    Previous
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages || loading}
                                >
                                    Next
                                </button>
                            </div>
                         )}
                    </>
                )}
                 {/* Removed redundant message display here, handled above */}
            </div>
            <AdminFooter />
        </>
    );
};

export default ListMarqueeHeading;