import React from 'react';
import './Footeradmin.css'; // Create a CSS file with this name


function AdminFooter() {
  return (
    <footer className="admin-footer">
      <div className="admin-copyright">
        Copyright © 29 News. All rights reserved.
      </div>
      <div className="admin-design-credit">
        Designed & Developed By:
        <a
          href="https://dheersoftwaresolutions.com/"
          className="admin-dheer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dheer Software Solutions
        </a>
      </div>
    </footer>
  );
}

export default AdminFooter;