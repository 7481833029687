import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Addgallery.css';
import Dashboard from "../Dashboard/Dashoboard";
import AdminFooter from '../Footer/Footeradmin';

const AddGalleryImages = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const [galleryFile, setGalleryFile] = useState(null);
    const [heading, setHeading] = useState('');
    const [paragraph, setParagraph] = useState('');
    const [folder, setFolder] = useState('default'); // 🆕 Folder state
    const [message, setMessage] = useState('');
    const [images, setImages] = useState({});
    const [loading, setLoading] = useState(false);
    const [website1, setWebsite1] = useState(false);
    const [website2, setWebsite2] = useState(false);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/gallery-images`);
            setImages(response.data); // Grouped images
            setMessage('');
        } catch (error) {
            setMessage('Error fetching gallery images: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGalleryFileChange = (e) => {
        setGalleryFile(e.target.files[0]);
    };

    const handleUploadGallery = async () => {
        if (!galleryFile) {
            setMessage('Please select an image to upload.');
            return;
        }

        if (!heading) {
            setMessage('Heading is required.');
            return;
        }

        const formData = new FormData();
        formData.append('image', galleryFile);
        formData.append('heading', heading);
        formData.append('paragraph', paragraph);
        formData.append('website1', website1.toString());
        formData.append('website2', website2.toString());
        formData.append('folder', folder); // 🆕 Send folder name

        try {
            const response = await axios.post(`${baseUrl}/upload-gallery`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setMessage(response.data.message);
            setGalleryFile(null);
            setHeading('');
            setParagraph('');
            fetchImages();
        } catch (error) {
            setMessage('Error uploading gallery image: ' + error.message);
        }
    };

    const handleDeleteImage = async (id) => {
        try {
            const response = await axios.delete(`${baseUrl}/images/${id}`);
            setMessage(response.data.message);
            fetchImages();
        } catch (error) {
            setMessage('Error deleting image: ' + error.message);
        }
    };

    return (
        <>
            <Dashboard />
            <div className="containerrs" style={{ marginTop: "50px" }}>
                <h2>Upload Gallery Image</h2>
                <div className="poll-form">
                    <label htmlFor="heading">Heading: </label>
                    <input
                        type="text"
                        id="heading"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                    />
                    <label htmlFor="paragraph">Paragraph:</label>
                    <textarea
                        id="paragraph"
                        value={paragraph}
                        onChange={(e) => setParagraph(e.target.value)}
                    />

                    <label>
                        Website 1:
                        <input type="checkbox" name="website1" checked={website1} onChange={(e) => setWebsite1(e.target.checked)} />
                    </label>
                    <label>
                        Website 2:
                        <input type="checkbox" name="website2" checked={website2} onChange={(e) => setWebsite2(e.target.checked)} />
                    </label>

                    {/* 🆕 Folder Name Input */}
                    <label htmlFor="folder">Folder Name:</label>
                    <input
                        type="text"
                        id="folder"
                        value={folder}
                        onChange={(e) => setFolder(e.target.value)}
                        placeholder="Enter folder name"
                    />

                    <input type="file" onChange={handleGalleryFileChange} />
                    <button onClick={handleUploadGallery}>Upload Gallery</button>
                </div>

                <p className={message.includes('Error') ? 'error' : ''}>{message}</p>

              
                        
            </div>
            <AdminFooter />
        </>
    );
};

export default AddGalleryImages;
