import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './VideoGallery.css'; // Import CSS
import FooterAdvanced from '../Footer/Footer';
import NavbarAdvanced from '../Navbar/Navbar';

const VideoGallery = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const baseUrl = process.env.REACT_APP_API_URL ; // Fallback URL

    useEffect(() => {
        const fetchVideos = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/videos`);
                // Modified to handle URLs directly and check for external sources
                const transformedVideos = response.data.map(video => {
                    const isExternal = video.url.includes('youtube.com') || video.url.includes('vimeo.com');
                    return {
                        ...video,
                        src: video.url, // Use URL directly from the database
                        title: video.title || 'Untitled Video',
                        isExternal: isExternal,
                    };
                });
                setVideos(transformedVideos);
            } catch (err) {
                setError(err.response?.data?.message || "Failed to fetch videos.");
                console.error("Error fetching videos:", err);  // Log the error
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, []);

    const openVideo = (index) => {
        setSelectedVideo(index);
    };

    const closeVideo = (e) => {
        if (e.target.classList.contains("video-gallery-drugs-modal")) {
            setSelectedVideo(null);
        }
    };

    if (loading) return <div>Loading videos...</div>;
    if (error) return <div>Error: {error}</div>;


    return (
        <>
            <NavbarAdvanced/>
            <div className="video-gallery-drugs-video-gallery-container">
                <h2 className="video-gallery-drugs-video-gallery-title">Video Gallery</h2>
                <div className="video-gallery-drugs-grid-container">
                    {videos.map((video, index) => (
                        <div key={index} className="video-gallery-drugs-grid-item" onClick={() => openVideo(index)}>
                            {/* If it's an external link (YouTube/Vimeo), use iframe, otherwise, use video */}
                            {video.isExternal ? (
                                <iframe
                                    src={video.src}
                                    className="video-gallery-drugs-grid-thumbnail"
                                    width="200"
                                    height="150"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={video.title} // Added title attribute
                                ></iframe>
                            ) : (
                                <video
                                    src={video.src}
                                    className="video-gallery-drugs-grid-thumbnail"
                                    width="200"
                                    height="150"
                                    muted
                                    loop
                                    autoPlay
                                >
                                    <source src={video.src} type="video/mp4"/> {/* Added source tag */}
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </div>
                    ))}
                </div>

                {selectedVideo !== null && (
                    <div className="video-gallery-drugs-modal" onClick={closeVideo}>
                        <div className="video-gallery-drugs-modal-content">
                            {videos[selectedVideo].isExternal ? (
                                <iframe
                                    width="560"
                                    height="315"
                                    src={videos[selectedVideo].src}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={videos[selectedVideo].title} // Added title attribute
                                ></iframe>
                            ) : (
                                <video width="560" height="315" controls>
                                    <source src={videos[selectedVideo].src} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            )}
                            <span className="video-gallery-drugs-close-button" onClick={() => setSelectedVideo(null)}>
                                ×
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <FooterAdvanced/>
        </>
    );
};

export default VideoGallery;