import React, { useState, useEffect } from "react";
import {
    FaBars,
    FaInfoCircle, // Keep for List News or generic icon
    FaNewspaper, // Use for Add News
    FaSignOutAlt, // Logout Icon
    FaKey, // Change Password Icon
    FaList // Optional: Better icon for List News
} from "react-icons/fa"; // Removed unused icons
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "../Dashboard/Dashboard.css"; // Assuming you still need base styles
// import Logo from "./logo.png"; // Assuming logo is not used
import axios from "axios";
import { removeToken, getToken } from '../Login/Authutils'; // Ensure path is correct

const Sidebar = ({ children }) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    if (!baseUrl) {
        console.error("REACT_APP_API_URL is not defined. Please check your .env file.");
    }

    const [isOpen, setIsOpen] = useState(false);
    // Removed unused 'click' state
    const navigate = useNavigate();
    const location = useLocation();

    // Only need state to track if reporter links should be shown
    const [showReporterLinks, setShowReporterLinks] = useState(false);

    // --- Role validation Effect ---
    useEffect(() => {
        const token = getToken();
        if (token) {
            axios.post(`${baseUrl}/validate-token`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                // Correctly parse role from nested user object
                if (response.data && response.data.user && response.data.user.role) {
                    const userRole = response.data.user.role;
                    console.log("Validated Role:", userRole); // Debugging

                    // Only set state for reporter role
                    setShowReporterLinks(userRole === 'reporter');

                    // If role is NOT reporter (e.g., admin or other), redirect or handle appropriately
                    // This component is now designed *only* for reporters
                    if (userRole !== 'reporter') {
                         console.warn(`User role "${userRole}" is not 'reporter'. Redirecting.`);
                         // Maybe redirect admin to admin dashboard if you have one?
                         // removeToken(); // Optional: force logout if wrong role lands here
                         // navigate('/login'); // Or navigate to an appropriate dashboard/page
                         setShowReporterLinks(false); // Ensure links are hidden
                    }

                } else {
                    console.error("Token validation response structure unexpected:", response.data);
                    removeToken();
                    navigate('/login');
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    console.warn("Token validation failed (Unauthorized/Expired). Redirecting to login.");
                } else {
                    console.error("Error validating token:", error.response ? error.response.data : error.message);
                }
                removeToken();
                navigate('/login');
            });
        } else {
            // No token found
            setShowReporterLinks(false);
             if (location.pathname !== '/login' && location.pathname !== '/') {
                 // navigate('/login'); // Optional redirection
             }
        }
    }, [baseUrl, navigate, location]); // Dependencies


    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        try {
            const token = getToken();
            const headers = token ? { Authorization: `Bearer ${token}` } : {};
            await axios.post(`${baseUrl}/logout`, {}, { headers: headers, withCredentials: true });
        } catch (error) {
            console.error("Logout backend call failed:", error.response ? error.response.data : error.message);
        } finally {
            // Always cleanup client-side
            removeToken();
            setShowReporterLinks(false); // Hide links
            setIsOpen(false); // Close sidebar
            navigate('/login');
        }
    };

    // --- No dropdown toggle functions needed ---

    // --- Reporter Links Data ---
    const reporterLinks = [
        { to: "/empaddnews", text: "Add News", icon: FaNewspaper },
        { to: "/emplistaddnews", text: "List News", icon: FaList }, // Changed icon
        { to: "/status", text: "Status", icon: FaList },
    ];

    // --- Component Return ---
    return (
        <>
            {/* Navbar */}
            <nav className="navbar">
                <div className="nav-container">
                    <NavLink exact="true" to="/" className="nav-logo">
                        <span><h1 style={{color:"white"}}>New29 Reporter</h1></span> {/* Updated Title */}
                    </NavLink>
                    <button onClick={handleLogout} className="logout-btn">
                        <FaSignOutAlt />
                        <span className="logout-text">Logout</span>
                    </button>
                </div>
            </nav>

            {/* Layout */}
            <div className="layout">
                {/* Sidebar */}
                <div className={`sidebar ${isOpen ? "open" : ""}`}>
                    <div className="toggle-btn" onClick={toggleSidebar}>
                        <FaBars />
                    </div>
                    <nav className="menu">

                       {/* Reporter Links - Render only if showReporterLinks is true */}
                       {showReporterLinks && (
                            <>
                                {reporterLinks.map(link => (
                                     <NavLink key={link.to} to={link.to} className={({ isActive }) => (`menu-item single ${isActive ? 'active' : ''}`)}> {/* Use single class, add active */}
                                         <link.icon className="icon" />
                                         {/* Show text only when sidebar is open */}
                                         {isOpen && <span className="text">{link.text}</span>}
                                     </NavLink>
                                 ))}
                            </>
                        )}
                       {/* No Admin links needed */}
                    </nav>
                </div>
                {/* Main Content */}
                <main className="content">{children}</main>
            </div>
        </>
    );
};

Sidebar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Sidebar;